// LoadingScreen.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const LoadingScreen = () => {
  return (
    <div className="absolute inset-0 flex items-center justify-center bg-white opacity-75 z-50">
      <div className="flex flex-col items-center">
        <FontAwesomeIcon icon={faCircleNotch} spin className="text-blue-600 text-5xl" />
        <span className="mt-2 text-blue-600">Cargando...</span>
      </div>
    </div>
  );
};

export default LoadingScreen;