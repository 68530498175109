import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useClasses } from "../services/ClassesContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookReader, faChevronDown, faChevronUp, faCheckCircle, faTimesCircle, faTasks, faSpellCheck, faTimeline } from '@fortawesome/free-solid-svg-icons';

const LevelDetail = () => {
  const { levels } = useClasses();
  const { unidadId } = useParams();
  const [activeDropdown, setActiveDropdown] = useState(null);

  const level = levels.find(l => l.id === unidadId);

  if (!level) {
    return <div>Level not found</div>;
  }

  // Desestructuración de las variables
  const { nombre, texto, palabras_nuevas, preguntas } = level;

  // Dummy questions
  const dummyQuestions = {
    explicitas: [
      {
        pregunta: '¿Dónde vive la merluza?',
        opciones: [
          { respuesta: 'En las costas de Coquimbo', esCorrecta: false },
          { respuesta: 'En las costas de Atacama', esCorrecta: false },
          { respuesta: 'En las costas de Arica.', esCorrecta: true },
          { respuesta: 'En las costas de Valparaíso.', esCorrecta: false }
        ]
      },
      {
        pregunta: '¿En qué tiempo migra la merluza?',
        opciones: [
          { respuesta: 'En otoño', esCorrecta: true },
          { respuesta: 'En primavera', esCorrecta: false },
          { respuesta: 'En verano', esCorrecta: false },
          { respuesta: 'En invierno', esCorrecta: false }
        ]
      }
    ],
    implicitas: [
      {
        pregunta: '¿Qué hace la merluza en invierno?',
        opciones: [
          { respuesta: 'Migra hacia el norte', esCorrecta: true },
          { respuesta: 'Se queda en la costa', esCorrecta: false },
          { respuesta: 'Desova', esCorrecta: false },
          { respuesta: 'Nada en aguas profundas', esCorrecta: false }
        ]
      }
    ],
    inferencias: [
      {
        pregunta: '¿Por qué la merluza es importante para el ecosistema?',
        opciones: [
          { respuesta: 'Porque es un depredador', esCorrecta: true },
          { respuesta: 'Porque es una especie en peligro', esCorrecta: false },
          { respuesta: 'Porque se encuentra en aguas frías', esCorrecta: false },
          { respuesta: 'Porque tiene un alto valor comercial', esCorrecta: false }
        ]
      }
    ]
  };

  // Asignar preguntas dummy si no existen
  const preguntasConDummy = preguntas || {
    explicitas: dummyQuestions.explicitas,
    implicitas: dummyQuestions.implicitas,
    inferencias: dummyQuestions.inferencias,
  };

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  return (
    <div className="py-8">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Image and Text Section */}
        <div className="flex flex-col md:flex-row -mx-4 mb-8">
          <div className="md:flex-1 px-4">
            <div className="h-[460px] w-[460px] rounded-lg bg-gray-300 dark:bg-gray-700 mb-4">
              <img className="w-full h-full object-cover border-8 border-amber-300 rounded-lg shadow-lg"  src={`${process.env.PUBLIC_URL}/images/${unidadId}.png`} 
 alt={nombre} />
            </div>
          </div>
          <div className="px-4 flex-grow">
            <div className="p-6 bg-white rounded-lg shadow-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700 flex-grow">
              <h2 className="text-2xl font-bold text-custom-blue dark:text-white mb-2">
                <FontAwesomeIcon icon={faBookReader} className="text-2xl mr-3" /> Unidad: "{nombre}"
              </h2>
              {texto.split("\n").map((line, index) => (
                <p key={index} className={`mb-4 ${unidadId.startsWith("B2") ? "text-sm" : "text-lg"} text-gray-600 dark:text-gray-300`}>
                  {line}
                </p>
              ))}
            </div>
          </div>
        </div>
  
        {/* New Words Section */}
        <h3 className="text-2xl font-bold text-custom-blue dark:text-gray-200 mb-4 flex items-center">
          <FontAwesomeIcon icon={faSpellCheck} className="text-2xl mr-3" />
          Nuevas Palabras
        </h3>
        <div className="mb-8 p-6 bg-white rounded-lg shadow-lg dark:bg-gray-800 transition-all duration-300">
          <ul className="list-disc pl-5 space-y-3">
            {Object.entries(palabras_nuevas).map(([palabra, definicion], index) => (
              <li key={index} className="text-base text-gray-700 dark:text-gray-300 hover:text-blue-500 transition-colors duration-200">
                <strong className="font-semibold dark:text-white">{palabra}</strong>: {definicion}
              </li>
            ))}
          </ul>
        </div>
  
        {/* Activity Questions Section */}
        <div className="mb-8">
          <h3 className="text-2xl font-bold text-custom-blue dark:text-gray-300 mb-4">
            <FontAwesomeIcon icon={faTasks} className="text-2xl mr-3" /> Actividades
          </h3>
  
          {/* Sección de Preguntas Explícitas */}
          <div className="mb-4">
            <button
              onClick={() => toggleDropdown('explicitas')}
              className="bg-blue-500 text-white px-4 py-3 rounded w-full text-left text-base transition duration-300 hover:bg-blue-600 flex items-center justify-between"
            >
              Preguntas Explícitas
              <FontAwesomeIcon icon={activeDropdown === 'explicitas' ? faChevronUp : faChevronDown} />
            </button>
            {activeDropdown === 'explicitas' && (
              <div className="mt-2 bg-white dark:bg-gray-700 p-4 rounded shadow-md transition-all duration-300">
                {preguntasConDummy.explicitas.map((q, qIndex) => (
                  <div key={qIndex} className="mb-8">
                    <p className="font-semibold text-base text-gray-700 dark:text-gray-300 mb-4">{q.pregunta}</p>
                    <div className="grid grid-cols-2 gap-4">
                      {q.opciones.map((option, oIndex) => (
                        <div
                          key={oIndex}
                          className={`p-4 text-center rounded-lg border ${
                            option.esCorrecta
                              ? 'bg-green-500 text-white border-green-700'
                              : 'text-red-500 border-red-500'
                          } shadow-md flex items-center justify-center space-x-2`}
                        >
                          <FontAwesomeIcon icon={option.esCorrecta ? faCheckCircle : faTimesCircle} className="text-xl" />
                          <span>{option.respuesta}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
  
          {/* Sección de Preguntas Implícitas */}
          <div className="mb-4">
            <button
              onClick={() => toggleDropdown('implicitas')}
              className="bg-blue-500 text-white px-4 py-3 rounded w-full text-left text-base transition duration-300 hover:bg-blue-600 flex items-center justify-between"
            >
              Preguntas Implícitas
              <FontAwesomeIcon icon={activeDropdown === 'implicitas' ? faChevronUp : faChevronDown} />
            </button>
            {activeDropdown === 'implicitas' && (
              <div className="mt-2 bg-white dark:bg-gray-700 p-4 rounded shadow-md transition-all duration-300">
                {preguntasConDummy.implicitas.map((q, qIndex) => (
                  <div key={qIndex} className="mb-8">
                    <p className="font-semibold text-base text-gray-700 dark:text-gray-300 mb-4">{q.pregunta}</p>
                    <div className="grid grid-cols-2 gap-4">
                      {q.opciones.map((option, oIndex) => (
                        <div
                          key={oIndex}
                          className={`p-4 text-center rounded-lg border ${
                            option.esCorrecta
                              ? 'bg-green-500 text-white border-green-700'
                              : 'text-red-500 border-red-500'
                          } shadow-md flex items-center justify-center space-x-2`}
                        >
                          <FontAwesomeIcon icon={option.esCorrecta ? faCheckCircle : faTimesCircle} className="text-xl" />
                          <span>{option.respuesta}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
  
          {/* Sección de Preguntas Inferencias */}
          <div className="mb-4">
            <button
              onClick={() => toggleDropdown('inferencias')}
              className="bg-blue-500 text-white px-4 py-3 rounded w-full text-left text-base transition duration-300 hover:bg-blue-600 flex items-center justify-between"
            >
              Preguntas de Inferencias
              <FontAwesomeIcon icon={activeDropdown === 'inferencias' ? faChevronUp : faChevronDown} />
            </button>
            {activeDropdown === 'inferencias' && (
              <div className="mt-2 bg-white dark:bg-gray-700 p-4 rounded shadow-md transition-all duration-300">
                {preguntasConDummy.inferencias.map((q, qIndex) => (
                  <div key={qIndex} className="mb-8">
                    <p className="font-semibold text-base text-gray-700 dark:text-gray-300 mb-4">{q.pregunta}</p>
                    <div className="grid grid-cols-2 gap-4">
                      {q.opciones.map((option, oIndex) => (
                        <div
                          key={oIndex}
                          className={`p-4 text-center rounded-lg border ${
                            option.esCorrecta
                              ? 'bg-green-500 text-white border-green-700'
                              : 'text-red-500 border-red-500'
                          } shadow-md flex items-center justify-center space-x-2`}
                        >
                          <FontAwesomeIcon icon={option.esCorrecta ? faCheckCircle : faTimesCircle} className="text-xl" />
                          <span>{option.respuesta}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
  
        {/* Ordenamiento Section */}
        <div className="mb-8">
          <h3 className="text-2xl font-bold text-custom-blue dark:text-gray-300 mb-4">
            <FontAwesomeIcon icon={faTimeline} className="text-2xl mr-3" /> Ordenamiento
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {(unidadId === 'B1U002' ? [1, 2, 3, 4] : [1, 2, 3]).map((num) => (
              <div key={num} className="flex flex-col items-center">
                <img 
                  className="h-64 w-64 object-cover rounded-lg border-4 border-blue-500 shadow-md mb-2" 
                  src={`${process.env.PUBLIC_URL}/images/${unidadId}_${num}.png`} 
                  alt={`Escena ${num}`} 
                />
                <span className="text-lg font-semibold text-gray-800 dark:text-gray-200">Escena {num}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}  

export default LevelDetail;
