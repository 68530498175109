import { useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './services/AuthProvider'; // Asegúrate de que la ruta sea correcta
import { ToastContainer } from 'react-toastify';
import Sidebar from './components/Sidebar'; // Asegúrate de que la ruta sea correcta
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCaretDown, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import LoadingScreen from './components/LoadingScreen'; 
import BreadCrumbs from './components/BreadCrumbs'; 

const App = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { user, logOut, loading } = useAuth();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);

    const isLoginPage = location.pathname === '/login';
    const isCursosRoute = /\/cursos\/?$/.test(location.pathname); // Checks if the path ends with "/cursos/"
    const shouldHideSidebar = isLoginPage || isCursosRoute;

    // En App.js
    useEffect(() => {
        // Esperar a que se complete la carga antes de realizar la redirección
        if (loading) return;

        if (!user && !isLoginPage) {
            navigate('/login'); // Redirigir a la página de inicio de sesión si no está autenticado
        }
    }, [user, navigate, loading, isLoginPage]); // Solo chequeamos la carga y la autenticación aquí

    const handleLogout = () => {
        logOut().then(() => {
            navigate('/login'); // Redirect to login page after logout
        });
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    if (loading) {
        return <LoadingScreen />;
      }

    return (
            <div className="App">
                <ToastContainer />
                {shouldHideSidebar ? (
                    <div className="flex h-screen">
                        <div className="flex-1">

                            <Outlet />
                        </div>
                    </div>
                ) : (
                    <div className="flex h-screen">
                        <Sidebar
                            sidebarOpen={sidebarOpen}
                            setSidebarOpen={setSidebarOpen}
                        />
                        <div className="flex flex-col flex-1 relative">
                        {/* <BreadCrumbs /> Add Breadcrumbs here */}

                            <div className="flex justify-end items-center p-4">
                                {user && (
                                    <div className="relative">
                                        <button 
                                            onClick={toggleDropdown} 
                                            className="bg-blue-500 text-white px-4 py-2 rounded flex items-center"
                                        >
                                            <FontAwesomeIcon icon={faUser} className="mr-2" />
                                            Perfil
                                            <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
                                        </button>
                                        {dropdownOpen && (
                                            <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-md z-10">
                                                <button 
                                                    onClick={() => alert('Profile clicked')}
                                                    className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center"
                                                >
                                                    <FontAwesomeIcon icon={faUser} className="mr-2" />
                                                    Ver Perfil
                                                </button>
                                                <button 
                                                    onClick={handleLogout} 
                                                    className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center"
                                                >
                                                    <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                                                    Cerrar Sesión
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
    
                            {/* Main Content */}
                            <div className="flex-1 p-6 overflow-auto">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                )}
            </div>
    );
};

export default App;
