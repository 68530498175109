import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../services/AuthProvider';
import { useClasses } from '../services/ClassesContext'; // Asegúrate de que la ruta sea correcta
import { collection, doc, getDocs, query, where, updateDoc, deleteDoc, Timestamp, runTransaction } from 'firebase/firestore';
import { db } from '../services/db';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faEdit, faMinus, faTrash, faCaretDown, faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import cursos from '../styles/cursos.jpg'; 
import { toast, Flip } from 'react-toastify';
import { nanoid } from 'nanoid';
import 'react-toastify/dist/ReactToastify.css';

const ClassesPage = () => {
  const navigate = useNavigate();
  const { teacherId, logOut } = useAuth();
  const { loading, classes, addClass, updateClass, deleteClass, selectedClass, updateSelectedClass } = useClasses();

  const [newClassName, setNewClassName] = useState('');
  const [newClassGrade, setNewClassGrade] = useState('');
  const [error, setError] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [schedule, setSchedule] = useState([{ dia: '', hora_inicio: '', hora_termino: '' }]);
  const [showModal, setShowModal] = useState(false);
  const [editingClass, setEditingClass] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false); // Dropdown state

  const handleClassClick = (classItemId) => {
    updateSelectedClass(classItemId);
    console.log("Clicked class ID:", classItemId);
  };

  // Effect to log selectedClass when it changes
  useEffect(() => {
    console.log("Updated selectedClass:", selectedClass);
    console.log(classes);
  }, [selectedClass]);

  const handleAddSchedule = () => {
    if (schedule.length < 5) {
      setSchedule([...schedule, { dia: '', hora_inicio: '', hora_termino: '' }]);
    }
  };

  const handleScheduleChange = (index, field, value) => {
    const newSchedule = [...schedule];
    newSchedule[index][field] = value;
    setSchedule(newSchedule);
  };

  const handleRemoveSchedule = (index) => {
    const newSchedule = schedule.filter((_, i) => i !== index);
    setSchedule(newSchedule);
  };

  // Convert Firestore Timestamp to YYYY-MM-DD string
  const formatDateForInput = (timestamp) => {
    console.log()
    const date = timestamp.toDate(); // Convert Timestamp to Date
    return date.toISOString().split('T')[0]; // Convert Date to YYYY-MM-DD
  };

  const createClassWithSubcollections = async (code, classData, startDate, endDate) => {
    try {
      const classRef = doc(db, 'cursos', code);
      console.log(startDate, endDate);
      await runTransaction(db, async (transaction) => {
        // Add the class data to the transaction
        transaction.set(classRef, classData);
  
        // Fetch levels data
        const niveles = await getLevelsData(classData.grado);
        if (niveles.length === 0) {
          throw new Error('No levels found for the specified class grade');
        }
  
        // Calculate release dates
        const releaseDates = calculateReleaseDates(startDate, endDate, niveles.length);
  
        // Add documents to 'liberar_niveles' subcollection
        for (const [index, nivel] of niveles.entries()) {
          const nivelData = {
            calificacion_personalizada: false,
            calificaciones_esperadas: { explicita: 2, implicita: 2, inferencia: 2 },
            fecha_liberacion: Timestamp.fromDate(new Date(releaseDates[index])),
            tipo_liberacion: 1
          };
          // Set the document ID to nivel.id
          console.log(nivelData);
          const nivelRef = doc(classRef, 'liberar_niveles', nivel.id);
          transaction.set(nivelRef, nivelData);
        }
  
        console.log('Class and subcollections created successfully');
      });
  
    } catch (error) {
      console.error('Error creating class and subcollections:', error);
      throw error; // Re-throw error to be handled by calling function
    }
  };
  
  // Helper function to get levels data
  const getLevelsData = async (classGrade) => {
    console.log("curso", classGrade);
    const levelsQuery = query(collection(db, 'niveles'), where('curso', '==', classGrade));
    const levelsSnapshot = await getDocs(levelsQuery);
  
    const nivelDocs = [];
    levelsSnapshot.forEach(doc => {
      nivelDocs.push({
        id: doc.id,
        ...doc.data(),
      });
    });
  
    return nivelDocs;
  };

  const calculateReleaseDates = (startDate, endDate, numberOfLevels) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const totalDays = Math.floor((end - start) / (1000 * 60 * 60 * 24));
    
    // Calcular el intervalo en días para cada nivel
    const interval = Math.floor(totalDays / (numberOfLevels - 1));
  
    // Generar fechas de liberación
    const releaseDates = [];
    for (let i = 0; i < numberOfLevels; i++) {
        const releaseDate = new Date(start);
        releaseDate.setDate(start.getDate() + i * interval);
        releaseDate.setHours(8, 0, 0, 0); // Establecer la hora a las 8 AM

        // Verificar que la fecha de liberación no exceda la fecha de finalización
        if (releaseDate > end) {
            releaseDates.push(end.toISOString());
            break;
        } else {
            releaseDates.push(releaseDate.toISOString());
        }
    }

    console.log(releaseDates);
    return releaseDates;
};


// Function to reset form fields and state
const resetForm = () => {
  setNewClassName('');
  setNewClassGrade('');
  setStartDate('');
  setEndDate('');
  setSchedule([{ dia: '', hora_inicio: '', hora_termino: '' }]);
  setEditingClass(null);
  setShowModal(false);
  setError('');
};


  const handleAddClass = async () => {
    if (!newClassName.trim() || !newClassGrade || !startDate || !endDate || !schedule.every(slot => slot.dia.trim() && slot.hora_inicio.trim() && slot.hora_termino.trim())) {
      setError('Todos los campos son requeridos y no pueden estar vacíos.');
      return;
    }

    if (startDate > endDate){
      setError('La fecha de inicio no puede ser después que la fecha de término de clases.');
      return;
    }

    // Validación para asegurarse de que el horario de inicio no sea después del horario de término
    if (!schedule.every(slot => {
      const startHour = new Date(`1970-01-01T${slot.hora_inicio}`);
      const endHour = new Date(`1970-01-01T${slot.hora_termino}`);
      return startHour <= endHour;
    })) {
      setError('El horario de inicio de clases no puede ser después que el horario de término.');
      return;
    }

    try {
      if (editingClass) {
        const start = Timestamp.fromDate(new Date(startDate));
        const end = Timestamp.fromDate(new Date(endDate));
        console.log(startDate,endDate);
        console.log(start,end);
        // Update class
        const updatedClass = {
          ...editingClass,
          nombre: newClassName,
          grado: parseInt(newClassGrade, 10),
          inicio_clases: start,
          termino_clases: end,
          horarios: schedule,
        };

        await updateDoc(doc(db, 'cursos', editingClass.id), updatedClass);
        updateClass(updatedClass);
        toast.success('Curso actualizado correctamente', {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip,
        });
        resetForm();
      } else {
        // Add new class
        const uniqueCode = nanoid(8); // Generate an 8-character unique code
        createClassWithSubcollections(
          uniqueCode,
          {
            nombre: newClassName,
            grado: parseInt(newClassGrade, 10),
            profesor_id: teacherId,
            inicio_clases: new Date(startDate),
            termino_clases: new Date(endDate),
            horarios: schedule,
            codigo: uniqueCode
          },
          startDate,
          endDate
        );
  
        const newClass = {
          id: uniqueCode,
          nombre: newClassName,
          grado: newClassGrade,
          inicio_clases: Timestamp.fromDate(new Date(startDate)),
          termino_clases: Timestamp.fromDate(new Date(endDate)),
          horarios: schedule,
        };
  
        addClass(newClass);
        toast.success('Curso agregado exitosamente', {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip,
        });
        updateSelectedClass(uniqueCode);
        console.log(selectedClass);
        console.log("ALOOOO");
        navigate(`/${teacherId}/cursos/${uniqueCode}`);
      }

      resetForm();

    } catch (error) {
      console.error('Error adding/updating class:', error);
    }
  };

  const handleEditClass = (classItem) => {

    setEditingClass(classItem);
    setNewClassName(classItem.nombre);
    setNewClassGrade(classItem.grado);
    setStartDate(formatDateForInput(classItem.inicio_clases));
    setEndDate(formatDateForInput(classItem.termino_clases));
    setSchedule(classItem.horarios);
    setShowModal(true);
  };

  const handleDeleteClass = async (id) => {
    try {
      await deleteDoc(doc(db, 'cursos', id));
      deleteClass(id);
      setShowConfirmDelete(false);
      setEditingClass(null);
      toast.success('Curso eliminado exitosamente', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Flip,
      });
    } catch (error) {
      console.error('Error deleting class:', error);
    }
  };

  // Handle dropdown
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    logOut().then(() => {
      window.location.href = '/login';
    });
  };

  return (
    <div className="mx-auto bg-blue-400 flex flex-col gap-4 p-4 h-screen items-center justify-center">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div>Cargando...</div>
        </div>
      ) : (
        <>
          {/* Dropdown Button */}
          <div className="absolute top-4 right-4">
            <div className="relative">
              <button
                onClick={toggleDropdown}
                className="bg-white text-blue-500 px-4 py-2 rounded flex items-center"
              >
                <FontAwesomeIcon icon={faUser} className="mr-2" />
                Perfil
                <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
              </button>
              {dropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-md z-10">
                  <button
                    onClick={() => alert('Profile clicked')}
                    className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center"
                  >
                    <FontAwesomeIcon icon={faUser} className="mr-2" />
                    Ver Perfil
                  </button>
                  <button
                    onClick={handleLogout}
                    className="w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                    Cerrar Sesión
                  </button>
                </div>
              )}
            </div>
          </div>

          <h1 className="text-2xl font-bold mb-6">Tus Cursos</h1>
          <div className="flex items-center gap-8">
          <ul className="space-y-4">
          {classes.length > 0 ? (
            classes.map(classItem => (
              <li key={classItem.id}>
                <Link
                  to={`/${teacherId}/cursos/${classItem.id}/`}
                  className="block p-3 w-64 bg-white shadow rounded-md hover:bg-gray-100 hover:shadow-lg transition delay-150 duration-200 ease-in-out hover:scale-105 transform"
                  onClick={() => handleClassClick(classItem.id)}
                >
                  <div className="flex justify-between items-center">
                    <span>{classItem.nombre}</span>
                    <div className="flex gap-2">
                      <button
                        onClick={(e) => {
                          e.preventDefault(); // Prevent the link from being triggered
                          handleEditClass(classItem);
                        }}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault(); // Prevent the link from being triggered
                          setEditingClass(classItem);
                          setShowConfirmDelete(true);
                        }}
                        className="text-red-600 hover:text-red-800"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  </div>
                </Link>
              </li>
            ))
          ) : (
                <li className="p-3 w-64 bg-white shadow rounded-md hover:bg-gray-100 hover:shadow-lg duration-200 ease-in-out hover:scale-105 transform">
                  <p className="text-gray-500">No tienes cursos aún.</p>
                </li>
              )}
              <li className="group p-3 w-64 bg-amber-300 shadow rounded-md hover:cursor-pointer hover:bg-blue-700 hover:shadow-lg duration-200 ease-in-out hover:scale-105 transform flex items-center justify-start" onClick={() => setShowModal(true)}>
                <FontAwesomeIcon icon={faCirclePlus} className="text-blue-400 text-xl mr-2 h-6 w-6" />
                <a className="block text-blue-600 group-hover:text-white transition-colors duration-200 ease-in-out">Agregar Nuevo Curso</a>
              </li>
            </ul>
            <img
              src={cursos} 
              className="h-32 rounded-full object-cover border-4 border-white"
            />
          </div>

          {showModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-4 rounded-md w-full max-w-md">
                <h2 className="text-xl font-bold mb-4">{editingClass ? 'Editar Curso' : 'Agregar Nuevo Curso'}</h2>
                
                <label className="block mb-2 text-sm font-medium text-gray-700">Nombre del curso</label>
                <input
                  type="text"
                  className="border p-2 w-full mb-4"
                  placeholder="Nombre del curso"
                  value={newClassName}
                  onChange={(e) => setNewClassName(e.target.value)}
                />

                <label className="block mb-2 text-sm font-medium text-gray-700">Grado del curso</label>
                <select
                    value={newClassGrade}
                    onChange={(e) => setNewClassGrade(e.target.value)}
                    className="p-2 mb-4 border border-gray-300 rounded-md"
                >
                    <option value="">Selecciona un grado</option>
                    <option value="1">Primero Básico</option>
                    <option value="2">Segundo Básico</option>
                </select>

                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-700">Inicio del Año</label>
                    <input
                      type="date"
                      className="border p-2 w-full"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-700">Fin del Año</label>
                    <input
                      type="date"
                      className="border p-2 w-full"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700">Horario de Clases</label>
                  {schedule.map((timeSlot, index) => (
                    <div key={index} className="flex items-center gap-4 mb-2">
                      <select
                        className="border p-2"
                        value={timeSlot.dia}
                        onChange={(e) => handleScheduleChange(index, 'dia', e.target.value)}
                      >
                        <option value="">Día</option>
                        <option value="Lunes">Lunes</option>
                        <option value="Martes">Martes</option>
                        <option value="Miércoles">Miércoles</option>
                        <option value="Jueves">Jueves</option>
                        <option value="Viernes">Viernes</option>
                      </select>

                      <input
                        type="time"
                        className="border p-2"
                        value={timeSlot.hora_inicio}
                        onChange={(e) => handleScheduleChange(index, 'hora_inicio', e.target.value)}
                      />

                      <input
                        type="time"
                        className="border p-2"
                        value={timeSlot.hora_termino}
                        onChange={(e) => handleScheduleChange(index, 'hora_termino', e.target.value)}
                      />

                      <button
                        className="text-red-600 hover:text-red-800"
                        onClick={() => handleRemoveSchedule(index)}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </button>
                    </div>
                  ))}
                  {schedule.length < 5 && (
                    <button
                      className="text-blue-600 hover:underline mt-2"
                      onClick={handleAddSchedule}
                    >
                      + Agregar otro horario
                    </button>
                  )}
                </div>
                {error && <div className="bg-red-200 text-red-800 p-2 rounded mb-4">{error}</div>}

                <div className="flex justify-end gap-2">
                  <button
                    className="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
                    onClick={handleAddClass}
                  >
                    {editingClass ? 'Actualizar Curso' : 'Agregar Curso'}
                  </button>
                  <button
                    className="text-gray-600 hover:text-gray-900"
                    onClick={() => {setShowModal(false); resetForm();}}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Confirm Delete Modal */}
          {showConfirmDelete && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white p-6 rounded-lg w-1/3">
                <h2 className="text-xl font-semibold mb-4">Confirmar Eliminación</h2>
                <p className="mb-4">¿Estás seguro de que quieres eliminar este curso?</p>
                <div className="flex justify-end gap-2">
                  <button
                    onClick={() => setShowConfirmDelete(false)}
                    className="bg-gray-300 text-gray-700 p-2 rounded hover:bg-gray-400"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={() => handleDeleteClass(editingClass.id)}
                    className="bg-red-500 text-white p-2 rounded hover:bg-red-700"
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ClassesPage;