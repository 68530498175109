import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import Login from '../pages/Login';
import ResponseTable from '../pages/ResponseTable';
import Cursos from '../pages/Cursos';
import Estudiantes from '../pages/Estudiantes';
import PrivateRoute from '../components/PrivateRoute';
import RedirectToCursos from '../components/RedirectTo';
import NotFound from '../pages/NotFound';
import CursoLevels from '../pages/CursoLevels';
import ListaNiveles from '../pages/Levels';
import LevelDetail from '../pages/LevelDetail';
import ClasificacionDePalabras from '../pages/Clasificacion';
import Performance from '../pages/Performance';
import Extras from '../pages/Extras';
import ExtraActivities from '../pages/ExtraActivities';
import WritingActivities from '../pages/WritingActivities';

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <App />,  // Wrap the other routes inside App
      children: [
        {
          path: "login",  // No need for leading slash here
          element: <Login />,
        },
        {
          path: ":teacherId/cursos/:cursoId/niveles",
          element: (
            <PrivateRoute element={<CursoLevels />} />
          ),
        },
        {
          path: ":teacherId/cursos",
          element: (
            <PrivateRoute element={<Cursos />} />
          ),
        },
        {
          path: ":teacherId/cursos/:cursoId",
          element: (
            <PrivateRoute element={<Estudiantes />} />
          ),
        },
        {
          path: ":teacherId/cursos/:cursoId/resultados",
          element: (
            <PrivateRoute element={<ResponseTable />} />
          ),
        },
        {
          path: "*",  // Catch-all wildcard
          element: <NotFound />,  // Componente NotFound    
        },
        {
          path: "/",  // When the path is exactly "/ARmadillo"
          element: <RedirectToCursos />,

        },
        {
          path: "/unidades",  // When the path is exactly "/ARmadillo"
          element: <ListaNiveles />,

        },
        {
          path: "/unidad/:unidadId",  // When the path is exactly "/ARmadillo"
          element: <LevelDetail />,

        },
        {
          path: "/clasificacion/:unidadId",  // When the path is exactly "/ARmadillo"
          element: <ClasificacionDePalabras />,

        },
        {
          path: ":teacherId/cursos/:cursoId/estudiante/:studentId",  // When the path is exactly "/ARmadillo"
          element: (
            <PrivateRoute element={<Performance />} />
          ),
        },
        {
          path: ":teacherId/cursos/:cursoId/extras/",  // When the path is exactly "/ARmadillo"
          element: (
            <PrivateRoute element={<Extras />} />
          ),
        },
        {
          path: ":teacherId/cursos/:cursoId/extras/otras",  // When the path is exactly "/ARmadillo"
          element: (
            <PrivateRoute element={<ExtraActivities />} />
          ),
        },
        {
          path: ":teacherId/cursos/:cursoId/extras/escritura",  // When the path is exactly "/ARmadillo"
          element: (
            <PrivateRoute element={<WritingActivities />} />
          ),
        },
        
      ],
    },
  ],
  {
    basename: "/",  // This prepends /ARmadillo to all paths
  }
);

export default router;