import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from '../services/AuthProvider';

function RedirectToCursos() {
  const navigate = useNavigate();
  const { teacherId }= useAuth();
  useEffect(() => {
    if (teacherId) {
        navigate(`/${teacherId}/cursos/`);

    }
  }, [teacherId, navigate]);

  return null; // No renderiza nada, solo redirige.
}

export default RedirectToCursos;