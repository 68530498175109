import React, { useEffect, useState, useCallback } from 'react';
import { useClasses } from '../services/ClassesContext';
import LevelTable from '../components/LevelTable';
import { collection, query, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../services/db';
import LoadingScreen from '../components/LoadingScreen'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
//import { subscribeReleasedLevels, subscribeStudentResponses, calculateAndStoreResults } from "../services/FetchResults";  // Funciones auxiliares

    const calculateClassMeanScores = (prefixes, students) => {
        const meanScoresByActivity = {};
        
        prefixes.forEach((prefix) => {
            let totalScore = 0;
            let count = 0;
    
            students.forEach((student) => {
                Object.keys(student.actividades).forEach((actividadId) => {
                    if (actividadId.includes(prefix)) {
                        const actividad = student.actividades[actividadId];
                        totalScore += actividad.Puntaje || 0;
                        count += 1;
                    }
                });
            });
    
            meanScoresByActivity[`promedio${prefix}`] = count > 0 ? (totalScore / count).toFixed(2) : "-";
        });
    
        return meanScoresByActivity;
    };


const ResponseTable = () => {
    const [data, setData] = useState([]);
    const [filterOptions, setFilterOptions] = useState({});
    const [namesByLevel, setNamesByLevel] = useState({});  // New object to store level names
    const [selectedLevel, setSelectedLevel] = useState('General');
    const [selectedActivity, setSelectedActivity] = useState('');
    const [loading, setLoading] = useState(true);
    const [classResults, setClassResults] = useState(null);  // Guardar los resultados de localStorage
    const { selectedClass, classes, levels } = useClasses();

    const getClassNameById = (id) => {
        const classItem = classes.find((cls) => cls.id === id);
        return classItem ? classItem.nombre : 'Unknown Class';
    };    
    
    //
    //const fetchLevelNameById = async (levelId) => {
    //    const levelDoc = await getDoc(doc(db, 'niveles', levelId));
    //    return levelDoc.exists() ? levelDoc.data().nombre : 'Unknown Level';
    //};

        //
    // Function to generate activity names from IDs
    const generateActivityName = (activityId) => {
        const type = activityId.substring(6, 10); // Extract type part of ID
        console.log(type);
        const typeMap = {
            'A000': 'Cazapalabra',
            'A001': 'Explícita',
            'A002': 'Implicita',
            'A003': 'Inferencial',
            'A004': 'Ordenamiento'
        };
        console.log(typeMap[type]);
        return typeMap[type] || `Unknown lol ${activityId}`;
    };


    // Envuelves la función fetchLevelNameById en useCallback
    const fetchLevelNameById = useCallback(async (levelId) => {
        const level = levels.find(l => l.id === levelId);
        return level ? level.nombre : 'Unidad desconocida';
    }, [levels]); 
    
    const calculateAverageTimeByActivity = (prefixes, students) => {
        const meanTimeByActivity = {};
    
        prefixes.forEach((prefix) => {
            let totalTime = 0;
            let count = 0;
    
            students.forEach((student) => {
                Object.keys(student.actividades).forEach((actividadId) => {
                    if (actividadId.includes(prefix)) {
                        const actividad = student.actividades[actividadId];
                        if (actividad.FechaInicio && actividad.FechaFin) {
                            const tiempoInicio = actividad.FechaInicio.seconds * 1000;  // Convertir a milisegundos
                            const tiempoFinal = actividad.FechaFin.seconds * 1000;    // Convertir a milisegundos
                            const tiempoDemorado = (tiempoFinal - tiempoInicio) / (1000 * 60); // Convertir a minutos
                            totalTime += tiempoDemorado;
                            count += 1;
                        }
                    }
                });
            });
    
            meanTimeByActivity[`tiempoPromedio${prefix}`] = count > 0 ? (totalTime / count).toFixed(2) : "-";
        });
    
        return meanTimeByActivity;
    };
    


    const calculateMeanScores = (prefixes, students) => {
        return students.map((student) => {
            const meanScores = {};

            prefixes.forEach((prefix) => {
                let totalScore = 0;
                let count = 0;

                Object.keys(student.actividades).forEach((actividadId) => {
                    if (actividadId.includes(prefix)) {
                        const actividad = student.actividades[actividadId];
                        totalScore += actividad.Puntaje || 0;
                        count += 1;
                    }
                });

                const meanScore = count > 0 ? (totalScore / count).toFixed(2) : "-";
                meanScores[`promedio${prefix}`] = meanScore;
            });

            return { ...student, ...meanScores };
        });
    };

    useEffect(() => {

        const fetchReleasedLevels = async () => {
            const releasedLevels = new Set();
            const levelsCollection = collection(db, `cursos/${selectedClass}/liberar_niveles`);
            const levelsSnapshot = await getDocs(levelsCollection);
            const activitiesByLevel = {};
            const levelNames = {};
            const currentDate = new Date();
            
            const fetchLevelPromises = levelsSnapshot.docs.map(async (doc) => {
                const releaseDate = new Date(doc.data().fecha_liberacion.seconds * 1000 + doc.data().fecha_liberacion.nanoseconds / 1000000);
                if (releaseDate <= currentDate) {
                    const levelId = doc.id;
                    releasedLevels.add(levelId);
                    activitiesByLevel[levelId] = [
                        `${levelId}A000`,
                        `${levelId}A001`,
                        `${levelId}A002`,
                        `${levelId}A003`,
                        `${levelId}A004`
                    ];
        
                    const levelName = await fetchLevelNameById(levelId);
                    levelNames[levelId] = levelName;
                }
            });
        
            await Promise.all(fetchLevelPromises);  // Wait for all level fetches to complete
            setFilterOptions(activitiesByLevel);
            setNamesByLevel(levelNames);
        };
        

        if (selectedClass) {
            fetchReleasedLevels();

            const studentsCollection = collection(db, `cursos/${selectedClass}/estudiantes`);
            const studentsQuery = query(studentsCollection);

            const unsubscribe = onSnapshot(studentsQuery, async (snapshot) => {
                const students = await Promise.all(snapshot.docs.map(async (studentDoc) => {
                    const studentData = studentDoc.data();
                    const responsesCollection = collection(db, `cursos/${selectedClass}/estudiantes/${studentDoc.id}/respuestas_actividades`);
                    const responsesSnapshot = await getDocs(responsesCollection);
                    const actividades = {};
                    responsesSnapshot.docs.forEach(responseDoc => {
                        const responseData = responseDoc.data();
                        const activityId = responseData.ActividadId;
                        actividades[activityId] = responseData;
                        //console.log(responseData);
                    });
                    return {
                        id: studentDoc.id,
                        ...studentData,
                        actividades
                    };
                }));

                // Apply the appropriate transformation based on the selected level
                const prefixes = ["A000", "A001", "A002", "A003", "A004"];
                const transformedStudents = calculateMeanScores(prefixes, students); 
                setData(transformedStudents);
                const meanScores = calculateClassMeanScores(prefixes, students);
                const averageTimes = calculateAverageTimeByActivity(prefixes, students);

            // Actualizar el estado y guardar en localStorage
            const newClassResults = {
                meanScores,
                averageTimes
            };

            // Establecer el nuevo valor en el estado
            setClassResults(newClassResults);

            // Guardar en localStorage usando el nuevo valor directamente
            localStorage.setItem(`promedios_${selectedClass}`, JSON.stringify(newClassResults));

            // Dejar de mostrar el estado de carga
            setLoading(false);

                });
                const storedClassResults = localStorage.getItem(`promedios_${selectedClass}`);
                console.log("aqui van los stored results", storedClassResults);
                

            return () => unsubscribe();
        }
    }, [selectedClass, selectedLevel, fetchLevelNameById]);

    useEffect(() => {
        // Reset the selected activity when the selected level changes
        if (selectedLevel !== 'General') {
            setSelectedActivity(''); // Clear the selected activity
        }
    }, [selectedLevel]);

    if (loading) {
        return <LoadingScreen />;
    }

    const renderPromedios = () => {
        if (!classResults) return null;
    
        const { meanScores } = classResults;
    
        const prefixDescriptions = {
            A000: "Promedio Cazapalabras",
            A001: "Promedio Explícita",
            A002: "Promedio Implícita",
            A003: "Promedio Inferencia",
            A004: "Promedio Ordenamiento"
        };
    
        const bloques = Object.keys(prefixDescriptions).map((prefix) => (
            <div key={prefix} className="bg-blue-100 text-center px-4 py-2 mx-1 rounded-lg shadow-sm">
                <h3 className="text-sm font-medium">{prefixDescriptions[prefix]}</h3>
                <p className="text-lg font-bold">{meanScores[`promedio${prefix}`] || "N/A"}</p>
            </div>
        ));
    
        const totalScores = Object.values(meanScores).reduce((sum, score) => sum + parseFloat(score || 0), 0);
        const numScores = Object.keys(prefixDescriptions).length;
        const promedioGeneral = (totalScores / numScores).toFixed(2);
    
        return (
            <div className="flex justify-center items-center space-x-8 mb-4">
                {bloques}
                <div className="bg-green-100 text-center px-4 py-2 rounded-lg shadow-sm">
                    <h3 className="text-sm font-medium">Promedio General</h3>
                    <p className="text-lg font-bold">{promedioGeneral}</p>
                </div>
            </div>
        );
    };
    


    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-semibold mb-8">Resultados del curso "{getClassNameById(selectedClass)}"</h1>
    
            {renderPromedios()}  {/* Mostrar los bloques de promedios */}

            {/* Filters Section */}
            <div className="mb-6">
                <div className="flex items-center space-x-6">
                    <label className="form-control w-full max-w-xs">
                    <div className="label">
                        <span className="label-text text-xs text-semibold">Filtrar por unidad</span>
                    </div>
                        <select
                            id="unitFilter"
                            onChange={(e) => setSelectedLevel(e.target.value)}
                            value={selectedLevel}
                            className="select select-bordered select-sm select-primary text-gray-700"
                        >
                            <option value="General">General</option>
                            {Object.keys(filterOptions).map(levelId => (
                                <option value={levelId} key={levelId}>
                                    {namesByLevel[levelId] || levelId} 
                                </option>
                            ))}
                        </select>
                    </label>
    
                    {selectedLevel !== 'General' && (
                        <label className="form-control w-full max-w-xs">
                        <div className="label">
                            <span className="label-text text-xs text-semibold">Filtrar por actividad</span>
                        </div>
                            <div className="flex items-center space-x-2">
                                <select
                                    id="activityFilter"
                                    onChange={(e) => setSelectedActivity(e.target.value)}
                                    value={selectedActivity}
                                    className="select select-bordered select-sm flex-1 select-primary text-gray-700"
                                >
                                    <option value="">Seleccionar Actividad</option>
                                    {filterOptions[selectedLevel]?.map(activityId => (
                                        <option value={activityId} key={activityId}>
                                            {generateActivityName(activityId)}
                                        </option>
                                    ))}
                                </select>
                                <button
                                    onClick={() => {
                                        setSelectedLevel('General'); // Reset level to "General"
                                        setSelectedActivity('');      // Clear activity selection
                                    }}
                                    className="btn btn-sm btn-outline font-medium text-xs"
                                >
                                    <FontAwesomeIcon icon={faFilterCircleXmark} className="text-sm" />
                                    Borrar filtros
                                </button>
                            </div>
                        </label>
                    )}
                </div>
            </div>
    
            {/* Level Table */}
            <LevelTable 
                data={data} 
                selectedLevel={selectedLevel} 
                selectedActivity={selectedActivity}
                selectedLevelName={namesByLevel[selectedLevel]} 
                filterOptions={filterOptions}
                releasedLevelsName={namesByLevel}
                generateActivityName={generateActivityName} // Pass the function as a prop
            />
        </div>
    );
}     

export default ResponseTable;