import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt, faStar as faStarEmpty } from '@fortawesome/free-solid-svg-icons';

const StarRating = ({ initialRating, onRatingChange }) => {
  const [rating, setRating] = useState(initialRating);

  useEffect(() => {
    setRating(initialRating); // Actualiza el estado si la calificación inicial cambia
  }, [initialRating]);

  const handleRating = (newRating) => {
    setRating(newRating); // Actualiza el estado de la calificación
    onRatingChange(newRating); // Llama a la función para actualizar la calificación
  };

  return (
    <div className="flex space-x-1">
      {[1, 2, 3, 4, 5].map((star) => (
        <FontAwesomeIcon
          key={star}
          icon={rating >= star ? faStar : faStarEmpty} // Estrella llena o vacía según la calificación
          className="cursor-pointer text-3xl"
          color={rating >= star ? '#fcd34d' : '#FFFFFF'} // Amarillo para las estrellas llenas, gris para las vacías
          onClick={() => handleRating(star)} // Actualiza la calificación cuando se hace clic
        />
      ))}
    </div>
  );
};

export default StarRating;
