// DateFormatter.js

// From Firebase timestamp to normal string
export const convertTimestamp = (timestamp) => {
    let date = timestamp.toDate();
    const yyyy = date.getFullYear();
    const mm = ("0" + (date.getMonth() + 1)).slice(-2);
    const dd = ("0" + date.getDate()).slice(-2);
    const hh = ("0" + date.getHours()).slice(-2);
    const min = ("0" + date.getMinutes()).slice(-2);
  
    return `${yyyy}-${mm}-${dd}T${hh}:${min}`;
  };
  