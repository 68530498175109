import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

// Registrar los elementos de Chart.js
Chart.register(ArcElement, Tooltip, Legend);

const CompletionChart = ({ data, releasedLevels }) => {
  const uniqueActivityIds = new Set();
  data.forEach(activity => {
    uniqueActivityIds.add(activity.ActividadId);
  });
  const completedActivities = uniqueActivityIds.size;
  const totalActivitiesExpected = releasedLevels.size * 5; // Total esperado de actividades
  
  const chartData = {
    labels: ['Completadas', 'Pendientes'],
    datasets: [
      {
        data: [completedActivities, totalActivitiesExpected - completedActivities],
        backgroundColor: ['#3b82f6', '#fcd34d'],
        hoverBackgroundColor: ['#2563eb', '#eab308']
      }
    ]
  };

  // Calcular el porcentaje de participación
  const participationPercentage = totalActivitiesExpected > 0 
    ? ((completedActivities / totalActivitiesExpected) * 100).toFixed(2) 
    : 0;

  return (
    <div className="mb-5 w-full h-full"> {/* Asegura que el contenedor tenga un tamaño fijo */}
      {/* Gráfico de torta */}
      <Pie 
        data={chartData} 
        options={{ 
          maintainAspectRatio: false, 
          plugins: { 
            legend: {
              position: 'bottom' // Puedes ajustar la posición de la leyenda
            } 
          } 
        }} 
      />
      {/* Mostrar el porcentaje de participación debajo del gráfico */}
      <div className="mt-2 text-center">
        Porcentaje de participación: {participationPercentage}%
      </div>
    </div>
  );
};

export default CompletionChart;



  

