import { useEffect, useState } from 'react';
import { useAuth } from '../services/AuthProvider';
import { useClasses } from '../services/ClassesContext';
import { useNavigate, useParams } from 'react-router-dom';
import sad from '../styles/sad.png'; // Ruta a tu icono PNG
import LoadingScreen from '../components/LoadingScreen'; 

// Component to show when access is denied
const NoPermission = () => (
  <div className="flex flex-col items-center justify-center min-h-screen p-6 overflow-hidden">
    <img 
      src={sad} 
      alt="No Permission" 
      className="w-48 mb-4 object-cover" // Ajusta el tamaño según sea necesario
    />
    <h2 className="text-xl font-semibold text-center mb-4">
      Lo sentimos, no tienes permiso para ver esta página.
    </h2>
    <p className="text-center mb-4">
      Si crees que es un error, contáctanos a armadilloapp.cl@gmail.com.
    </p>
  </div>
);

const PrivateRoute = ({ element }) => {
  const { user, loading, teacherId } = useAuth();
  const { selectedClass } = useClasses();
  const storedClass = localStorage.getItem('selectedClass');
  const { teacherId: urlTeacherId } = useParams();
  const navigate = useNavigate();
  const [showNoPermission, setShowNoPermission] = useState(false);

  useEffect(() => {
      if (loading) return;

      if (!user) {
          navigate('/login');
      } else if (!(selectedClass || storedClass)) {
          console.log("Está logged in pero no hay selected class");
          navigate(`/${user.teacherId}/cursos`);
      } else if (teacherId !== urlTeacherId) {
          // Si el ID del docente no coincide, podrías establecer un estado para mostrar NoPermission
          setShowNoPermission(true);
      }
  }, [user, loading, selectedClass, storedClass, navigate, teacherId, urlTeacherId]);

  if (loading) {
      return <LoadingScreen />;
  }

  if (showNoPermission) {
      return <NoPermission />;
  }

  return element; 
};

export default PrivateRoute;