import React, { useEffect, useState } from 'react';
import { useClasses } from '../services/ClassesContext';
import { useAuth } from '../services/AuthProvider';
import DataTable from 'react-data-table-component';
import { collection, query, onSnapshot, doc, addDoc, deleteDoc, updateDoc, setDoc, getDocs } from 'firebase/firestore';
import { db } from '../services/db';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faFileCsv, faCopy, faCirclePlus, faInfoCircle, faHourglassEnd } from '@fortawesome/free-solid-svg-icons';
import CSVUpload from '../components/CSV_Upload'; 
import LoadingScreen from '../components/LoadingScreen'; 
import { toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const customStyles = {
    rows: {
        style: {
            backgroundColor: '#f9f9f9', // Color de fondo por defecto
            '&:hover': {
                backgroundColor: '#e5efff', // Color de fondo al pasar el mous
            },
        },
    },
    headCells: {
        style: {
            backgroundColor: 'rgba(8, 83, 184,1)', // Color de fondo de los encabezados
            color: '#fcfcfc', // Color de texto de los encabezados
            fontSize: '14px',
            padding: '10px',
            border: '1px solid rgba(28,71,137,0.3)', // Borde superior con menos opacidad

        },
    },
    cells: {
        style: {
            padding: '8px',
            border: '1px solid rgba(188, 205, 228, 0.3)', // Borde superior con menos opacidad
            width: 'auto', // Dejar que el ancho se ajuste automáticamente

        },
    },
};

const EstudiantesTable = () => {
    //Generales
    const [estudiantes, setEstudiantes] = useState([]);
    const [loading, setLoading] = useState(true);
    const { cursoId } = useParams();
    const [nuevoEstudiante, setNuevoEstudiante] = useState({ nombre: '', apellido: '', email: '', datos_juego: {estrellas: 0} });
    const [error, setError] = useState(''); // Estado para mensajes de error
    const [errorMessage, setErrorMessage] = useState(''); // Estado para mensajes de error
    const [enabled, setEnabled] = useState();
    const [timeLimit, setTimeLimit] = useState();

    const { selectedClass, classes } = useClasses();
    const { user } = useAuth();

    console.log(classes);
     console.log(user);

    const toggleSwitch = async () => {
        const newEnabledState = !enabled;
        setEnabled(newEnabledState);
        // Reference to the Firestore document
        const docRef = doc(db, "cursos", cursoId);

        try {
        // Update the 'enabled' field in Firestore
        await updateDoc(docRef, {
            inicio_simple: newEnabledState
        });
        console.log("Document updated successfully!");
        } catch (error) {
        console.error("Error updating document: ", error);
        }
        
    };

    const getClassNameById = (id) => {
        const classItem = classes.find((cls) => cls.id === id);
        return classItem ? classItem.nombre : 'Unknown Class';
    };    

    const getEnabledById = (id) => {
        const classItem = classes.find((cls) => cls.id === id);
        return classItem ? classItem.inicio_simple : 'Unknown';
    };    

    const getTimeById = (id) => {
        const classItem = classes.find((cls) => cls.id === id);
        return classItem ? classItem.tiempo_maximo : 'Unknown';
    };  


    const copyToClipboardWithToast = async (text) => {
        try {
          await navigator.clipboard.writeText(text);
          toast.info('Código copiado al portapapeles!', {
            position: "top-right",
            autoClose: 1100,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Flip,
        });
        } catch (error) {
          console.error('Failed to copy text: ', error);
          toast.error('Error al copiar el código.');
        }
      };

    //Modals
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedEstudianteId, setSelectedEstudianteId] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState({ isOpen: false, id: null });
    const [loadingButton, setLoadingButton] = useState(false); // Loading state


    useEffect(() => {
        //Version que se actualiza automaticamente
        const studentsCollectionRef = collection(doc(db, "cursos", cursoId), "estudiantes");
        const q = query(studentsCollectionRef);
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const fetchedData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setEstudiantes(fetchedData);
            setLoading(false);
        });
        setEnabled(getEnabledById(cursoId));
        setTimeLimit(getTimeById(cursoId));
        console.log(classes);

        return () => unsubscribe();
    }, [cursoId, classes]);

    if (loading) {
        return <LoadingScreen />;
    }


    const saveTimeChange = async () => {
        // Referencia al documento en Firestore
        const docRef = doc(db, "cursos", cursoId); // Asegúrate de tener el cursoId disponible

        try {
            // Actualizar el campo 'tiempo_maximo' en Firestore
            await updateDoc(docRef, {
                tiempo_maximo: parseInt(timeLimit, 10) // Cambia 'value' por el nuevo valor
            });
            console.log("Document updated successfully!");
            // Mostrar toast de éxito
            toast.success("¡Tiempo máximo actualizado con éxito!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Flip,
            });
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    const handleTimeChange = (e) => {
        const value = e.target.value;
      
         // Validate input and display message if invalid
         if (value < 0 || value > 120) {
            setErrorMessage('El tiempo debe estar entre 0 y 120 minutos.');
        } else {
            setErrorMessage(''); // Clear message if valid
            setTimeLimit(value);
            }
        };

      
    const handleChange = (e) => {
        const { name, value } = e.target;
        setNuevoEstudiante(prev => ({ ...prev, [name]: value }));
    };

    const toggleModal = () => {
        if (modalIsOpen) {
        // Esperar a que termine la transición antes de resetear los estados
        setTimeout(() => {
            setIsEditMode(false);
            setNuevoEstudiante({ nombre: '', apellido: '', email: '' , datos_juego: {estrellas: 0}});
            setSelectedEstudianteId(null);
            setError(''); // Limpiar mensaje de error
        }, 300); // Ajusta este tiempo según la duración de la transición
    }
    setModalIsOpen(!modalIsOpen);
    document.getElementById('student_modal').close(); // Cierra el modal
    };


    const guardarEstudiante = async () => {
        if (!nuevoEstudiante.nombre.trim() || !nuevoEstudiante.apellido.trim() || !nuevoEstudiante.email.trim()) {
            setError('Todos los campos son requeridos y no pueden estar vacíos.');
            return;
        }

        const nameRegex = /^[^\d_]+$/u;
        if (!nameRegex.test(nuevoEstudiante.nombre) || !nameRegex.test(nuevoEstudiante.apellido)) {
            setError('El nombre y el apellido deben contener solo letras.');
            setLoading(false); // Stop loading if there's an error
            return; // Exit the function
        }
        console.log(nuevoEstudiante);

        try {
            if (isEditMode && selectedEstudianteId) {
                // Actualizar estudiante existente
                await updateDoc(doc(db, 'cursos', cursoId, 'estudiantes',  selectedEstudianteId), nuevoEstudiante);

            } else {
                // Agregar nuevo estudiante
                await addDoc(collection(db, 'cursos', cursoId, 'estudiantes'), { ...nuevoEstudiante, curso_id: cursoId });
                toast.success('Estudiante agregado correctamente', {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Flip,
                });
            }
            toggleModal(); // Cerrar el modal después de guardar
        } catch (error) {
            setError('Error al guardar los datos. Intenta nuevamente.');
            console.log("error lol");
        }
    };

    const openEditModal = (estudiante) => {
        setNuevoEstudiante(estudiante);
        setSelectedEstudianteId(estudiante.id);
        setIsEditMode(true);
        setModalIsOpen(true);
        document.getElementById('student_modal').showModal(); // Cierra el modal
    };

    const openDeleteModal = (id) => {
        setConfirmDeleteModal({ isOpen: true, id });
        document.getElementById('delete_modal').showModal(); // Cierra el modal

    };

    const closeDeleteModal = () => {
        setConfirmDeleteModal({ isOpen: false, id: null });
        document.getElementById('delete_modal').close(); // Cierra el modal

    };

    const eliminarEstudiante = async () => {
        if (confirmDeleteModal.id) {
            setLoadingButton(true);
            try {
                await deleteDoc(doc(db, 'cursos', cursoId, 'estudiantes', confirmDeleteModal.id));
                setLoadingButton(false);
                closeDeleteModal();
                toast.success('Estudiante eliminado exitosamente', {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Flip,
                });
            } catch (error) {
                setLoadingButton(false);
                toast.error('Error al eliminar estudiante', {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Flip,
                });            }
        }
    };

    const columns = [
        { name: 'Nombre', selector: row => row.nombre, sortable: true },
        { name: 'Apellido', selector: row => row.apellido, sortable: true },
        { name: 'Email del tutor', selector: row => row.email, sortable: true },
        {
            name: 'Acciones',
            maxWidth: '180px',
            cell: row => (
                <div className="w-full flex justify-center">
                    <div className="flex items-center space-x-4">
                        <button
                            onClick={() => openEditModal(row)}
                            className="bg-blue-500 rounded py-2 px-4 text-white hover:bg-blue-700"
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </button>
                        <button
                            onClick={() => openDeleteModal(row.id)}
                            className="bg-red-500 rounded py-2 px-4 text-white hover:bg-red-700"
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                </div>
            )
        }
    ];

    const paginationOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        noDataComponent: 'No hay datos para mostrar'
    };

    return (
        <div className="container mx-auto p-4">
            <div className="flex items-center justify-between mb-8">
                <h1 className="text-3xl font-semibold">
                    Curso "{getClassNameById(selectedClass)}"
                </h1>
    
                <div className="flex items-center mx-5">
                    <label className="font-semibold text-gray-700 mr-2">Código de la clase:</label>
                    <input
                        type="text"
                        value={cursoId}
                        readOnly
                        className="border border-gray-300 p-2 rounded-l bg-gray-100 text-gray-700 w-24"
                    />
                    <button
                        onClick={() => copyToClipboardWithToast(cursoId)}
                        className="bg-gray-500 text-white px-2 py-2 rounded-r"
                    >
                        <FontAwesomeIcon icon={faCopy} />
                    </button>
                </div>
            </div>
    
            {/* Agrupando todos los botones en una sola línea */}
            <div className="flex items-center mb-4 space-x-4">
                <button
                    onClick={() => document.getElementById('student_modal').showModal()}
                    className="btn btn-primary btn-sm h-10 text-white font-normal"
                >
                    <FontAwesomeIcon icon={faCirclePlus} className="mr-2" />
                    Agregar Estudiante
                </button>
                <button
                    className="btn btn-success btn-sm h-10 text-white font-normal"
                    onClick={() => document.getElementById('csv_modal').showModal()}
                >
                    <FontAwesomeIcon icon={faFileCsv} className="mr-2" />
                    Subir CSV
                </button>
                <button
                    onClick={() => document.getElementById('time_modal').showModal()}
                    className="btn btn-neutral btn-sm h-10 text-white font-normal"
                >
                    <FontAwesomeIcon icon={faHourglassEnd} className="mr-2" />
                    Tiempo máximo de uso
                </button>
    
                <div className="flex items-center">
                    <div
                        className={`w-12 h-6 rounded-full p-1 cursor-pointer transition-colors duration-300 ${
                            enabled ? "bg-blue-600" : "bg-gray-300"
                        }`}
                        onClick={() => document.getElementById('toggle_modal').showModal()}
                    >
                        {/* Switch Button */}
                        <div
                            className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform duration-300 ${
                                enabled ? "translate-x-6" : "translate-x-0"
                            }`}
                        ></div>
                    </div>
                    <span className="ml-2 font-semibold text-gray-700">
                        {enabled ? "Inicio de sesión simple activado" : "Inicio de sesión simple desactivado"}
                    </span>
    
                    {/* Tooltip con el ícono de información */}
                    <div className="tooltip ml-2" data-tip="El inicio de sesión simple permite que los alumnos accedan sin ingresar el correo del tutor.">
                        {/* Ícono de información usando FontAwesome */}
                        <FontAwesomeIcon icon={faInfoCircle} className="text-blue-500 text-sm cursor-pointer" />
                    </div>
                </div>
    
            </div>

    <dialog id="toggle_modal" className="modal">
    <div className="modal-box">
        <h3 className="font-bold text-xl mb-4">Confirmar Inicio de sesión</h3>
        <p className="py-4">¿Estás seguro que deseas {enabled ? 'desactivar' : 'activar'} el inicio de sesión simple?
        </p>
        {/* Contenedor para los botones de acción del modal */}
        <div className="modal-action">
        {/* Primer botón para la acción personalizada */}
        <button className="btn btn-success text-white font-normal mr-2" onClick={() => {
            toggleSwitch()
            document.getElementById('toggle_modal').close(); // Cierra el modal
        }}>
            Si
        </button>

        {/* Segundo botón que cierra el modal */}
        <form method="dialog">
            <button className="btn font-normal">No</button>
        </form>
        </div>
    </div>
    </dialog>


    <dialog id="time_modal" className="modal">
  <div className="modal-box">
    <h3 className="font-bold text-xl mb-4">Modificar tiempo máximo de uso</h3>

    <label className="block mb-1">Tiempo máximo de uso</label>

    {/* Contenedor flex para alinear el input con el texto de minutos */}
    <div className="flex items-center">
      <input
        type="number"
        name="tiempo"
        value={timeLimit}
        onChange={handleTimeChange}
        placeholder="Ingresa el tiempo máximo"
        className="border blue-400 p-2 mb-2 w-1/3"
        min="0"
        max="120"
      />
      {/* Etiqueta para la unidad de medida */}
      <span className="ml-2">minutos</span>
    </div>

    {/* Mostrar mensaje de error en caso de que exista */}
    {errorMessage && <p className="text-red-500">{errorMessage}</p>}

    <div className="modal-action">
      {/* Primer botón para la acción personalizada */}
      <button
        className="btn btn-success font-normal text-white"
        onClick={() => {
          saveTimeChange();
          document.getElementById('time_modal').close(); // Cierra el modal
        }}
      >
        Si
      </button>

      {/* Segundo botón que cierra el modal */}
      <form method="dialog">
        <button className="btn font-normal">No</button>
      </form>
    </div>
  </div>
</dialog>




    


    <dialog id="delete_modal" className="modal">
  <div className="modal-box">
    <h3 className="font-bold text-xl mb-4">Confirmar eliminación</h3>
    <p className="py-4">¿Estás seguro de que deseas eliminar a este estudiante?</p>

    {/* Contenedor para los botones de acción del modal */}
    <div className="modal-action">
      {/* Botón de eliminar con indicador de carga */}
      <button 
        onClick={eliminarEstudiante} 
        className="btn btn-error text-white px-4 py-2 mr-2"
        disabled={loadingButton}  // Disable button during loading
      >
        {loadingButton ? (
          <>
            <span className="loading loading-spinner"></span>
            Eliminando
          </>
        ) : (
          'Eliminar'
        )}
      </button>

      {/* Botón de cancelar */}
      <button onClick={() => {
          closeDeleteModal(); // Close modal function
          document.getElementById('delete_modal').close(); // Close modal dialog
        }} 
        className="btn px-4 py-2"
      >
        Cancelar
      </button>
    </div>
  </div>
</dialog>



    <dialog id="student_modal" className="modal">
        <div className="modal-box">
        <h3 className="font-bold text-xl mb-4">
        {isEditMode ? 'Modificar Estudiante' : 'Agregar Estudiante'}
        </h3>
        <label className="block mb-1">Nombre</label>
            <input
                type="text"
                name="nombre"
                value={nuevoEstudiante.nombre}
                onChange={handleChange}
                placeholder="Ingresa el nombre"
                className="border blue-400 p-2 mb-2 w-full"
            />
        <label className="block mb-1">Apellido</label>
            <input
                type="text"
                name="apellido"
                value={nuevoEstudiante.apellido}
                onChange={handleChange}
                placeholder="Ingresa el apellido"
                className="border p-2 mb-2 w-full"
            />
        <label className="block mb-1">Email del tutor</label>
            <input
                type="email"
                name="email"
                value={nuevoEstudiante.email}
                onChange={handleChange}
                placeholder="Ingresa el email"
                className="border p-2 mb-2 w-full"
            />
            {error && <div className="bg-red-200 text-red-800 p-2 rounded mb-4">{error}</div>}
            <div className="mt-5 flex justify-end">
                <button onClick={guardarEstudiante} className="btn btn-success btn btn-success text-white font-normal mr-2">
                    {isEditMode ? 'Guardar Cambios' : 'Agregar'}
                </button>
                <button onClick={toggleModal} className="btn font-normal">
                    Cancelar
                </button>
            </div>
            </div>
            </dialog>


            <dialog id="student_modal" className="modal">
        <div className="modal-box">
        <h3 className="font-bold text-xl mb-4">
        {isEditMode ? 'Modificar Estudiante' : 'Agregar Estudiante'}
        </h3>
        <label className="block mb-1">Nombre</label>
            <input
                type="text"
                name="nombre"
                value={nuevoEstudiante.nombre}
                onChange={handleChange}
                placeholder="Ingresa el nombre"
                className="border blue-400 p-2 mb-2 w-full"
            />
        <label className="block mb-1">Apellido</label>
            <input
                type="text"
                name="apellido"
                value={nuevoEstudiante.apellido}
                onChange={handleChange}
                placeholder="Ingresa el apellido"
                className="border p-2 mb-2 w-full"
            />
        <label className="block mb-1">Email del tutor</label>
            <input
                type="email"
                name="email"
                value={nuevoEstudiante.email}
                onChange={handleChange}
                placeholder="Ingresa el email"
                className="border p-2 mb-2 w-full"
            />
            {error && <div className="bg-red-200 text-red-800 p-2 rounded mb-4">{error}</div>}
            <div className="mt-5 flex justify-end">
                <button onClick={guardarEstudiante} className="btn btn-success btn btn-success text-white font-normal mr-2">
                    {isEditMode ? 'Guardar Cambios' : 'Agregar'}
                </button>
                <button onClick={toggleModal} className="btn font-normal">
                    Cancelar
                </button>
            </div>
            </div>
            </dialog>

            <dialog id="csv_modal" className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">Subir CSV</h3>
                    <CSVUpload cursoId={cursoId} onClose={() => document.getElementById('csv_modal').close()} />
                    <form method="dialog">
                    {/* if there is a button in form, it will close the modal */}
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                    </form>
                </div>
            </dialog>

            <DataTable
                columns={columns}
                data={estudiantes}
                pagination
                paginationComponentOptions={paginationOptions}
                //selectableRows
                noDataComponent= 'No hay datos para mostrar'
                customStyles={customStyles}


            />
        </div>
    );
};

export default EstudiantesTable;