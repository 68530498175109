import React, { useState, useEffect } from 'react';
import { useClasses } from '../services/ClassesContext';
import LoadingScreen from '../components/LoadingScreen'; 
import { collection, doc, getDocs, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../services/db';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faBoxOpen, faFloppyDisk, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom'; // Importa Link desde react-router-dom

const CursoLevels = () => {
  const { cursoId } = useParams();
  const { classes } = useClasses();

  const [levels, setLevels] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(null);
  const [changedLevelIds, setChangedLevelIds] = useState(new Set());
  const [loading, setLoading] = useState(true); // Add loading state

  const convertTimestamp = (timestamp) => {
    let date = timestamp.toDate();
    const yyyy = date.getFullYear();
    const mm = ("0" + (date.getMonth() + 1)).slice(-2);
    const dd = ("0" + date.getDate()).slice(-2);
    const hh = ("0" + date.getHours()).slice(-2);
    const min = ("0" + date.getMinutes()).slice(-2);
    
    return `${yyyy}-${mm}-${dd}T${hh}:${min}`;
  }

  const getMinDate = (levelId) => {
    const index = levels.findIndex(level => level.id === levelId);
    if (index > 0) {
      console.log(levels[index - 1].fecha_liberacion);
      return levels[index - 1].fecha_liberacion;
    }
    return null;
  };

  const getMaxDate = (levelId) => {
    const index = levels.findIndex(level => level.id === levelId);
    if (index < levels.length - 1) {
      // La fecha máxima es la fecha de liberación del siguiente nivel
      return levels[index + 1].fecha_liberacion;
    }
    return null; // No hay nivel siguiente, no hay restricción de máxima
  };


  const getCurrentDateTime = () => {
    const now = new Date();
    // Format the date to YYYY-MM-DDTHH:MM
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;  };

    useEffect(() => {
      const fetchLevels = async () => {
        const subcollectionRef = collection(doc(db, "cursos", cursoId), "liberar_niveles");
        try {
          const querySnapshot = await getDocs(subcollectionRef);
          const nivelesCompletos = [];
          const storedLevels = localStorage.getItem('levels');
    
          // Si hay niveles en localStorage, los parseamos
          const levelsFromStorage = storedLevels ? JSON.parse(storedLevels) : [];
    
          for (const docSnap of querySnapshot.docs) {
            const data = docSnap.data();
            const idNivel = docSnap.id; // Usa el ID del documento directamente
            
            console.log("liberar_niveles data:", data);
    
            // Busca el nivel en localStorage
            const nivel = levelsFromStorage.find(level => level.id === idNivel);
    
            if (nivel) {
              // Si el nivel se encuentra en localStorage
              const nivelCompleto = {
                id: idNivel,
                calificacion_personalizada: data.calificacion_personalizada || false,
                calificaciones: data.calificaciones_esperadas,
                fecha_liberacion: data.fecha_liberacion ? convertTimestamp(data.fecha_liberacion) : null,
                tipo_liberacion: data.tipo_liberacion,  // Default to '0' if missing
                descripcion: nivel.descripcion || "Descripción no disponible",
                nombre: nivel.nombre || "Nombre no disponible"
              };
              nivelesCompletos.push(nivelCompleto);
            } else {
              // Si el nivel no se encuentra en localStorage, lo buscamos en Firestore
              const nivelDocSnap = await getDoc(doc(db, "niveles", idNivel));
    
              if (nivelDocSnap.exists()) {
                const nivelData = nivelDocSnap.data();
    
                const nivelCompleto = {
                  id: idNivel,
                  calificacion_personalizada: data.calificacion_personalizada || false,
                  calificaciones: data.calificaciones_esperadas,
                  fecha_liberacion: data.fecha_liberacion ? convertTimestamp(data.fecha_liberacion) : null,
                  tipo_liberacion: data.tipo_liberacion,  // Default to '0' if missing
                  descripcion: nivelData.descripcion || "Descripción no disponible",
                  nombre: nivelData.nombre || "Nombre no disponible"
                };
    
                nivelesCompletos.push(nivelCompleto);
              } else {
                console.error(`No se encontró el nivel con id: ${idNivel}`);
              }
            }
          }
          nivelesCompletos.sort((a, b) => a.id.localeCompare(b.id));
    
          setLevels(nivelesCompletos);
          setLoading(false); // Establecer la carga en falso después de que se obtenga la información
          console.log(nivelesCompletos);
        } catch (error) {
          console.error("Error fetching documents: ", error);
          setLoading(false); // Establecer la carga en falso después de que se obtenga la información
        }
      };
    
      fetchLevels();
    }, [cursoId]);
    
  
  const isFieldDisabled = (level) => {
    
    switch (level.tipo_liberacion) {
      case 0: // Inmediata
        return true; // Deshabilitar cambio de fecha
      case 1: // Por fecha y requisitos
        return false; // No deshabilitar nada
      case 2: // Por fecha
        return false; // No deshabilitar nada
      case 3: // Nivel
        return true; // Deshabilitar todos los campos
      default:
        return false; // Por defecto, no deshabilitar nada
    }
  };  

  const handleAchievementChange = (levelId, value) => {
    const [score, achievement] = value.split('|');
    const scoreInt = parseInt(score, 10); // Convert score to integer
    setLevels(levels.map(level => {
      if (level.id === levelId) {
        if (achievement !== 'Personalizado') {
          return {
            ...level,
            logroEsperado: achievement,
            calificaciones: {
              explicita: scoreInt,
              implicita: scoreInt,
              inferencia: scoreInt,
            },
            calificacion_personalizada: false,
          };
        } else {
          return {
            ...level,
            logroEsperado: achievement,
            calificacion_personalizada: true,
          };
        }
      }
      return level;
    }));
    setChangedLevelIds(prev => new Set(prev).add(levelId));
  };
  
  const openModal = (level) => {
    setCurrentLevel({ ...level }); // Clone the level object to avoid direct state mutation
    setModalOpen(true);
  };
  

  // Función para liberar todas las actividades inmediatamente
  const handleReleaseAll = () => {
    setLevels(prevLevels => {
      const updatedLevels = prevLevels.map(level => ({
        ...level,
        tipo_liberacion: 0,
        fecha_liberacion: getCurrentDateTime()
      }));
      setChangedLevelIds(new Set(updatedLevels.map(level => level.id)));
      return updatedLevels;
    });
  };

  const handleModalAchievementChange = (type, value) => {
    const valueInt = parseInt(value, 10); // Convert value to integer
    setCurrentLevel({
      ...currentLevel,
      calificaciones: {
        ...currentLevel.calificaciones,
        [type]: valueInt
      }
    });
    setChangedLevelIds(prev => new Set(prev).add(currentLevel.id));

  };
  

  
  const handleRelease = (levelId, value) => {
    const valueInt = parseInt(value, 10); // Convertir valor a entero
  
    setLevels((prevLevels) => {
      const index = prevLevels.findIndex(level => level.id === levelId);
      if (index === -1) return prevLevels; // Si no se encuentra el nivel, devolver array original
  
      const previousLevel = index > 0 ? prevLevels[index - 1] : null;
      const nextLevel = index < prevLevels.length - 1 ? prevLevels[index + 1] : null;
  
      // Si el nuevo valor es 0, realizar las validaciones antes de cambiar
      if (valueInt === 0) {
        const currentDate = new Date(); // Fecha actual
  
      // Validación: la fecha actual no debe ser anterior a la del nivel anterior
      if (previousLevel) {
        const previousDate = new Date(previousLevel.fecha_liberacion);
        if (currentDate < previousDate) {
          toast.error("La fecha de liberación no puede ser anterior a la del nivel anterior.", {
            position: "top-right",
            autoClose: 5000,
            transition: Flip,
          });
          return prevLevels; // No aplicar cambios si la validación falla
        }
      }

      // Validación: la fecha actual no debe ser posterior a la del siguiente nivel
      if (nextLevel) {
        const nextDate = new Date(nextLevel.fecha_liberacion);
        if (currentDate > nextDate) {
          toast.error("La fecha de liberación no puede ser posterior a la del siguiente nivel.", {
            position: "top-right",
            autoClose: 5000,
            transition: Flip,
          });
          return prevLevels; // No aplicar cambios si la validación falla
        }
      }
    }
  
      // Crear un nuevo array con el nivel actualizado
      const updatedLevels = [...prevLevels];
      updatedLevels[index] = {
        ...updatedLevels[index],
        tipo_liberacion: valueInt,
        fecha_liberacion: valueInt === 0 ? getCurrentDateTime() // Si tipo_liberacion es 0, asignar fecha actual
                          : updatedLevels[index].fecha_liberacion // Mantener fecha existente en caso contrario
      };
  
      // Registrar el cambio de nivel
      setChangedLevelIds(prev => new Set(prev).add(levelId));
      return updatedLevels;
    });
  };


  const saveData = () => {
    setLevels(levels.map(level =>
      level.id === currentLevel.id ? currentLevel : level
    ));
    closeModal();
  };
  

  const closeModal = () => {
    setModalOpen(false);
    setCurrentLevel(null);
  };



  
  // Handle date change
const handleDateChange = (levelId, newDate) => {
  const index = levels.findIndex(level => level.id === levelId);
  const indexClass = classes.findIndex(classItem => classItem.id === cursoId);

  const previousLevel = index > 0 ? levels[index - 1] : null;
  const nextLevel = index < levels.length - 1 ? levels[index + 1] : null; // Nuevo: obtener el siguiente nivel
  const cursoStartDate = new Date(convertTimestamp(classes[indexClass].inicio_clases));
  const selectedDate = new Date(newDate);

  // Validación para el primer nivel
  if (index === 0 && cursoStartDate && selectedDate < cursoStartDate) {
    toast.error("La fecha de liberación del primer nivel no puede ser anterior a la fecha de inicio del curso.", {
      position: "top-right",
      autoClose: 5000,
      transition: Flip,
    });
  } else if (previousLevel) {
    const previousDate = new Date(previousLevel.fecha_liberacion);
    if (selectedDate < previousDate) {
      toast.error("La fecha de liberación no puede ser anterior a la del nivel anterior.", {
        position: "top-right",
        autoClose: 5000,
        transition: Flip,
      });
    } else {
      // Si pasa la validación con el nivel anterior, actualizamos la fecha del nivel
      setLevels(levels.map(level =>
        level.id === levelId ? { ...level, fecha_liberacion: newDate } : level
      ));
    }
  } else if (nextLevel) {
    const nextDate = new Date(nextLevel.fecha_liberacion);
    if (selectedDate > nextDate) {
      toast.error("La fecha de liberación no puede ser posterior a la del nivel siguiente.", {
        position: "top-right",
        autoClose: 5000,
        transition: Flip,
      });
    } else {
      // Si pasa la validación con el nivel siguiente, actualizamos la fecha del nivel
      setLevels(levels.map(level =>
        level.id === levelId ? { ...level, fecha_liberacion: newDate } : level
      ));
    }
  } else {
    // Si no hay ni nivel anterior ni siguiente, actualizamos la fecha sin validaciones
    setLevels(levels.map(level =>
      level.id === levelId ? { ...level, fecha_liberacion: newDate } : level
    ));
  }

  // Registrar el nivel modificado
  setChangedLevelIds(prev => new Set(prev).add(levelId));
};

  
  const handleSaveChanges = async () => {
    if (changedLevelIds.size === 0) return; // Deshabilitar botón si no hay cambios

  // Validar que las fechas de liberación estén en el orden correcto
  for (const levelId of changedLevelIds) {
    const level = levels.find(l => l.id === levelId);
    const index = levels.findIndex(l => l.id === levelId);

    // Verificar si la fecha del nivel anterior es mayor
    if (index > 0) {
      const previousLevel = levels[index - 1];
      if (new Date(level.fecha_liberacion) < new Date(previousLevel.fecha_liberacion)) {
        toast.error("La fecha de liberación no puede ser anterior a la del nivel anterior.", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip,
        });
        return; // Detener el proceso si la fecha no está en el orden correcto
      }
    }

    // Verificar si la fecha del nivel siguiente es menor
    if (index < levels.length - 1) {
      const nextLevel = levels[index + 1];
      if (new Date(level.fecha_liberacion) > new Date(nextLevel.fecha_liberacion)) {
        toast.error("La fecha de liberación no puede ser posterior a la del nivel siguiente.", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip,
        });
        return; // Detener el proceso si la fecha no está en el orden correcto
      }
    }
  }

    
    const updates = [];
    console.log(changedLevelIds);
    for (const levelId of changedLevelIds) {
      const level = levels.find(l => l.id === levelId);
      if (level) {
        const docRef = doc(db, "cursos", cursoId, "liberar_niveles", levelId);
        const updatedData = {
          calificacion_personalizada: level.calificacion_personalizada,
          calificaciones_esperadas: level.calificaciones,
          fecha_liberacion: new Date(level.fecha_liberacion), // Ensure it's a timestamp
          tipo_liberacion: level.tipo_liberacion
        };
        updates.push(updateDoc(docRef, updatedData));
      }
    }
  
    try {
      await Promise.all(updates);
      toast.success('Datos actualizados exitosamente', {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Flip,
      });
      setChangedLevelIds(new Set()); // Clear the set after saving
    } catch (error) {
      console.error("Error updating documents: ", error);
      toast.error('Error al actualizar los datos', {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip,
      }); 
    }
  };
  

  return (
    <div className="container mx-auto p-4">
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <h1 className="text-3xl font-semibold mb-8">Programación de unidades</h1>
          <div className="overflow-x-auto">
          
                      {/* Agrupando todos los botones en una sola línea */}
                      <div className="flex items-center mb-4 space-x-4">
                <button
                    onClick={handleReleaseAll}
                    className="btn btn-sm btn-primary h-10 text-white font-normal"
                >
                    <FontAwesomeIcon icon={faBoxOpen} className="mr-2" />
                    Liberar todas
                </button>
                <button
                    className="btn btn-success btn-sm h-10 font-normal text-white"
                    onClick={handleSaveChanges}
                    disabled={changedLevelIds.size === 0}
                >
                    <FontAwesomeIcon icon={faFloppyDisk} className="mr-2" />
                    Guardar cambios
                </button>
                </div>

            <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-lg mb-8">
              <thead className="bg-gray-50">
                <tr>
                  <th className="py-3 px-6 text-left font-semibold text-custom-blue" style={{ width: '150px' }}>Nombre del Nivel</th>
                  <th className="py-3 px-6 text-left font-semibold text-custom-blue" style={{ width: '250px' }}> Descripción</th>
                  <th className="py-3 px-6 text-left font-semibold text-custom-blue">Tipo de liberación
                    <div className="tooltip tooltip-bottom ml-2 inline text-xs font-normal" data-tip="Indica cuales son los requerimientos para poder jugar una unidad. 'Inmediata' significa que se libera en el mismo instante, 'Por fecha' permite programar una liberación para todo el curso, y 'Por fecha y requisitos' pide que se haya alcanzado la calificación esperada en la unidad anterior.">
                      <FontAwesomeIcon icon={faInfoCircle} className="text-blue-500 text-sm cursor-pointer" />
                    </div>
                  </th>
                  <th className="py-3 px-6 text-left font-semibold text-custom-blue">Fecha de Liberación</th>
                  <th className="py-3 px-6 text-left font-semibold text-custom-blue">Logro Esperado</th>
                </tr>
              </thead>
              <tbody>
                {levels.length === 0 ? (
                  <tr>
                    <td colSpan="5" className="py-3 px-6 text-center">No hay niveles liberados.</td>
                  </tr>
                ) : (
                  levels.map((level, index) => (
                    <tr key={level.id} className="border-t border-gray-200 hover:bg-gray-100" style={{ height: '80px' }}>
                      <td className="py-3 px-6">
                      <Link to={`/unidad/${level.id}`} className="link link-secondary link-hover">
                        {level.nombre}
                      </Link>
                      </td>
                      <td className="py-3 px-6">
                        <div className="text-xs overflow-hidden max-h-16 overflow-y-auto">{level.descripcion}</div>
                      </td>
                      <td className="py-3 px-6">
                        <select
                          value={level.tipo_liberacion}
                          className="select select-primary select-sm w-full max-w-xs"
                          onChange={(e) => handleRelease(level.id, e.target.value)}
                        >
                          <option value="0">Inmediata</option>
                          <option value="1" disabled={index === 0}>Por fecha y requisitos</option>
                          <option value="2">Por fecha</option>
                        </select>
                      </td>
                      <td className="py-3 px-6">
                        <input
                          type="datetime-local"
                          value={level.fecha_liberacion}
                          onChange={(e) => handleDateChange(level.id, e.target.value)}
                          className={`p-2 border border-gray-300 rounded-md ${isFieldDisabled(level) ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : ''}`}
                          disabled={isFieldDisabled(level)}
                          min={getMinDate(level.id)}
                          max={getMaxDate(level.id)} 
                        />
                      </td>
                      <td className="py-3 px-6">
                        <div className="flex items-center">
                          <select
                            value={level.calificacion_personalizada ? 'Personalizado' : level.calificaciones.explicita}
                            onChange={(e) => handleAchievementChange(level.id, `${e.target.value}|${e.target.options[e.target.selectedIndex].text}`)}
                            className="select select-warning select-sm w-full max-w-xs mr-2"
                          >
                            <option value="0">0 estrellas ☆☆☆</option>
                            <option value="1">1 estrella ★☆☆</option>
                            <option value="2">2 estrellas ★★☆</option>
                            <option value="3">3 estrellas ★★★</option>
                            <option value="Personalizado">Personalizado</option>
                          </select>
                          {level.calificacion_personalizada && (
                            <button
                              onClick={() => openModal(level)}
                              className="h-8 w-8 bg-blue-500 text-white rounded-md flex items-center justify-center"
                            >
                              <FontAwesomeIcon icon={faGear} />
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </>
      )}

      {modalOpen && currentLevel && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg overflow-hidden shadow-lg p-6 max-w-md mx-auto">
            <h2 className="text-2xl font-semibold mb-4">Personalizar Calificaciones Esperadas</h2>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Explicita</label>
                <select
                  value={currentLevel.calificaciones.explicita}
                  onChange={(e) => handleModalAchievementChange('explicita', e.target.value)}
                  className="mt-1 select select-warning select-sm w-full max-w-xs"
                >
                  <option value="0">0 estrellas ☆☆☆</option>
                  <option value="1">1 estrella ★☆☆</option>
                  <option value="2">2 estrellas ★★☆</option>
                  <option value="3">3 estrellas ★★★</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Implícita</label>
                <select
                  value={currentLevel.calificaciones.implicita}
                  onChange={(e) => handleModalAchievementChange('implicita', e.target.value)}
                  className="mt-1 select select-warning select-sm w-full max-w-xs"
                >
                  <option value="0">0 estrellas ☆☆☆</option>
                  <option value="1">1 estrella ★☆☆</option>
                  <option value="2">2 estrellas ★★☆</option>
                  <option value="3">3 estrellas ★★★</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Inferencia</label>
                <select
                  value={currentLevel.calificaciones.inferencia}
                  onChange={(e) => handleModalAchievementChange('inferencia', e.target.value)}
                  className="mt-1 select select-warning select-sm w-full max-w-xs"
                >
                  <option value="0">0 estrellas ☆☆☆</option>
                  <option value="1">1 estrella ★☆☆</option>
                  <option value="2">2 estrellas ★★☆</option>
                  <option value="3">3 estrellas ★★★</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Ordenamiento</label>
                <select
                  value={currentLevel.calificaciones.inferencia}
                  onChange={(e) => handleModalAchievementChange('ordenamiento', e.target.value)}
                  className="mt-1 select select-warning select-sm w-full max-w-xs"
                >
                  <option value="0">0 estrellas ☆☆☆</option>
                  <option value="1">1 estrella ★☆☆</option>
                  <option value="2">2 estrellas ★★☆</option>
                  <option value="3">3 estrellas ★★★</option>
                </select>
              </div>
            </div>
            <div className="mt-6 flex justify-end">
              <button
                onClick={closeModal}
                className="btn font-normal rounded-md mr-2"
              >
                Cerrar
              </button>
              <button
                onClick={saveData}
                className="btn btn-success font-normal text-white"
              >
                Guardar Datos
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default CursoLevels;