// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import router from './services/Routes'; // Importa el router definido
import { AuthProvider } from './services/AuthProvider'; // Importa el AuthProvider aquí
import { ClassesProvider } from './services/ClassesContext'; // Importa el AuthProvider aquí

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider> {/* Proporciona el contexto aquí */}
    <ClassesProvider> {/* Proporciona el contexto aquí */}
      <RouterProvider router={router} />
    </ClassesProvider>
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();

