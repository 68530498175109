import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { collection, onSnapshot, getDocs } from "firebase/firestore";
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import { db } from '../services/db';
import { useClasses } from '../services/ClassesContext';
import LoadingScreen from '../components/LoadingScreen';

// Mapeo de IDs de actividades a nombres descriptivos
const actividadNombreMap = {
    "CLU001": "Clasificación de palabras M-L",
    "TNL001": "Textos no literarios",
    // Puedes agregar más IDs y nombres aquí según lo necesites
};

// Styles for DataTable
const StyledDataTable = styled(DataTable)`
    .rdt_Table { width: 100%; }
    .rdt_TableHeadRow th { text-transform: uppercase; font-size: 12px; font-weight: bold; color: #789868; padding: 12px; border-bottom: 2px solid #cbd5e0; }
    .rdt_TableBodyRow td { padding: 12px; text-align: center; }
    .rdt_Pagination { margin-top: 16px; }
`;

const ExtraActivities = () => {
    
    const [students, setStudents] = useState([]);
    const [uniqueActividadIds, setUniqueActividadIds] = useState([]);
    const [selectedActividadId, setSelectedActividadId] = useState('');
    const [loading, setLoading] = useState(true);
    const { selectedClass } = useClasses();

    const renderPuntaje = (puntaje) => {
        if (puntaje === undefined || puntaje === null) {
            puntaje = '-'; // Handle undefined or null values
        } else {
            // Convert puntaje to a number and format it to 2 decimal places
            const numericPuntaje = parseFloat(puntaje);
            if (!isNaN(numericPuntaje)) {
                puntaje = numericPuntaje.toFixed(2); // Format to 2 decimal places
            }
        }
    
        const color = obtenerColorPuntaje(puntaje);
        return (
            <div style={{ backgroundColor: color, borderRadius: '12px', padding: '4px 8px', display: 'inline-block', minWidth: '60px', textAlign: 'center' }}>
                {puntaje}
            </div>
        );
    };

// Función para calcular el color basado en el puntaje 
const obtenerColorPuntaje = (puntaje) => {
    if (puntaje === '-') {
        return `rgba(128, 128, 128, 0.1)`;
    }

    const percentage = puntaje / 100;

    if (percentage <= 0.33) {
        // Rojo para puntajes entre 0 y 33
        return `#ef4444`; // Rojo
    } else if (percentage <= 0.66) {
        // Amarillo para puntajes entre 34 y 66
        return `#fcd34d`; // Amarillo
    } else {
        // Verde para puntajes entre 67 y 100
        return `#a3e635`; // Verde
    }
};

    const ExpandedComponent = ({ data }) => {
        const { actividades } = data;
    
        if (!actividades || actividades.length === 0) {
            return <p>No hay respuestas disponibles para esta actividad.</p>;
        }
    
        // Ordenar actividades por fecha más reciente primero
        const sortedActividades = actividades.sort((a, b) => b.FechaFin.toDate() - a.FechaFin.toDate());
    
        // Actividad más reciente
        const latestActividad = sortedActividades[0];
        const previousActividades = sortedActividades.slice(1);
    
        // Función para renderizar respuestas con chequeo de "TNL" en ActividadId
        const renderRespuestas = (respuestas, actividadId) => {
            return respuestas.map((respuesta, idx) => (
                <div key={idx} className={`p-2 text-center ${respuesta.Correcto ? 'bg-green-100' : 'bg-red-100'}`}>
                    {actividadId.includes("TNL") ? (
                        <>
                            <strong>Intento {idx + 1}:</strong> {respuesta.TipoTexto} ({respuesta.Correcto ? 'Correcta' : 'Incorrecta'})
                        </>
                    ) : (
                        <>
                            <strong>Intento {idx + 1}:</strong> {respuesta.Oracion} ({respuesta.Correcto ? 'Correcta' : 'Incorrecta'})
                        </>
                    )}
                </div>
            ));
        };
    
        return (
            <div>
                <h4 className="font-semibold mb-2">Respuesta más reciente</h4>
                {renderRespuestas(latestActividad.Respuestas, latestActividad.ActividadId)}
    
                {previousActividades.length > 0 && (
                    <>
                        <h4 className="font-semibold mt-4 mb-2">Intentos Anteriores</h4>
                        {previousActividades.map((actividad, index) => (
                            <div key={index} className="mb-4">
                                {renderRespuestas(actividad.Respuestas, actividad.ActividadId)}
                            </div>
                        ))}
                    </>
                )}
            </div>
        );
    };
    
    

    const calcularTiempoTranscurrido = (inicio, fin) => {
        if (!inicio || !fin) return '-';
        const diffInMilliseconds = fin.toDate() - inicio.toDate();
        return (diffInMilliseconds / (1000 * 60)).toFixed(2);
    };

    useEffect(() => {
        const studentsCollection = collection(db, `cursos/${selectedClass}/estudiantes`);
        const unsubscribe = onSnapshot(studentsCollection, async (snapshot) => {
            const actividadIdsSet = new Set();
            const studentsData = await Promise.all(snapshot.docs.map(async (studentDoc) => {
                const studentData = studentDoc.data();
                const responsesCollection = collection(db, `cursos/${selectedClass}/estudiantes/${studentDoc.id}/respuestas_actividades_extras`);
                const responsesSnapshot = await getDocs(responsesCollection);

                const actividades = responsesSnapshot.docs.map((responseDoc) => {
                    const responseData = responseDoc.data();
                    actividadIdsSet.add(responseData.ActividadId); // Collect unique ActividadId
                    return responseData;
                }).sort((a, b) => b.FechaFin.toDate() - a.FechaFin.toDate());

                return actividades.length > 0 ? {
                    id: studentDoc.id,
                    ...studentData,
                    actividades: actividades
                } : null;
            }));

            const actividadIdsArray = Array.from(actividadIdsSet);
            setStudents(studentsData.filter(student => student !== null));
            setUniqueActividadIds(actividadIdsArray);

            if (actividadIdsArray.length > 0) {
                setSelectedActividadId(actividadIdsArray[0]); // Set default to the first ActividadId
            }
            setLoading(false); // Se ejecuta aquí, después de que se han actualizado los estados

        });

        return () => unsubscribe();
    }, [selectedClass]);

    const handleSelectChange = (event) => {
        setSelectedActividadId(event.target.value);
    };

    const filteredStudents = selectedActividadId
        ? students.map(student => ({
            ...student,
            actividades: student.actividades.filter(activity => activity.ActividadId === selectedActividadId)
        })).filter(student => student.actividades.length > 0)
        : [];

    // Define columns for DataTable
    const columns = [
        { name: 'Nombre', selector: row => row.nombre || '-', sortable: true },
        { name: 'Apellido', selector: row => row.apellido || '-', sortable: true },
        {
            name: 'Puntaje (%)',
            selector: row => {
                // Obtener el puntaje, redondearlo a 2 decimales
                const puntaje = parseFloat(row.actividades[0]?.Puntaje) || 0;
                return Math.round(puntaje * 100) / 100;  // Redondear a 2 decimales sin usar toFixed
            },
            sortable: true,
            cell: row => {
                const puntaje = row.actividades[0]?.Puntaje;
                return (
                    <div className="text-sm font-medium text-gray-900 text-center">
                        {renderPuntaje(puntaje)}
                    </div>
                );
            }
        },      
        {
            name: 'Fecha de Inicio',
            selector: row => row.actividades[0]?.FechaInicio ? format(row.actividades[0].FechaInicio.toDate(), "dd/MM/yyyy HH:mm", { locale: esLocale }) : '-',
            sortable: true,
        },
        
        {
            name: 'Tiempo (min)',
            selector: row => calcularTiempoTranscurrido(row.actividades[0]?.FechaInicio, row.actividades[0]?.FechaFin) || '-',
            sortable: true,
        }
    ];

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-semibold mb-4">Actividades extras</h1>
            {uniqueActividadIds.length > 0 ? (
                <>
                    <label className="form-control w-full max-w-xs mb-6">
                    <div className="label">
                        <span className="label-text text-xs text-semibold">Elegir actividad</span>
                    </div>
                    <select className="select select-bordered select-sm select-primary text-gray-700" onChange={handleSelectChange} value={selectedActividadId}>
                        {uniqueActividadIds.map((id, index) => (
                            <option key={index} value={id}>{actividadNombreMap[id] || id}</option>
                        ))}
                    </select>
                    </label>

                    <StyledDataTable
                        columns={columns}
                        data={filteredStudents}
                        expandableRows
                        expandableRowsComponent={ExpandedComponent}
                        highlightOnHover
                        pagination
                    />
                </>
            ) : (
                <p>No hay respuestas disponibles para mostrar.</p>
            )}
        </div>
    );
};

export default ExtraActivities;






