import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../services/db';
import { toast, Flip } from 'react-toastify';
import Papa from 'papaparse';

const CSVUpload = ({ cursoId, onClose }) => {
    const [csvData, setCsvData] = useState([]);
    const [mapping, setMapping] = useState({ nombre: '', apellido: '', email: '' });
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    const [showMapping, setShowMapping] = useState(false);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [columns, setColumns] = useState([]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleFileUpload = () => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const arrayBuffer = event.target.result;
                const uint8Array = new Uint8Array(arrayBuffer);
                let text;
                try {
                    // Attempt to decode using UTF-8 first
                    text = new TextDecoder('iso-8859-1').decode(uint8Array);
                } catch (e) {
                    // If UTF-8 fails, attempt with ISO-8859-1
                    text = new TextDecoder('iso-8859-1').decode(uint8Array);
                }

                // Parse CSV
                Papa.parse(text, {
                    header: true,
                    delimiter: "",
                    skipEmptyLines: true,
                    complete: (results) => {
                        console.log("entre lol");
                        setColumns(results.meta.fields);
                        setCsvData(results.data);
                        setShowMapping(true);
                    },
                    error: (error) => {
                        console.error('Error al procesar el CSV:', error);
                        setError('Error al procesar el archivo CSV.');
                    }
                });
            };

            reader.readAsArrayBuffer(file);
        }
    };

    const handleMappingChange = (e) => {
        const { name, value } = e.target;
        setMapping(prev => ({ ...prev, [name]: value }));
    };

    const handleSave = async () => {
        if (!cursoId) {
            setError('El ID del curso no está disponible.');
            return;
        }
        const errors = [];
        setLoading(true); // Start loading
        setError(null); // Clear previous errors
        setSuccessMessage(''); // Clear previous success message

        for (const row of csvData) {
            const nombre = row[mapping.nombre];
            const apellido = row[mapping.apellido];
            const email = row[mapping.email];

            // Validate the presence of fields
            if (!nombre.trim() || !apellido.trim() || !email.trim()) {
                setError('Algunos campos están vacíos o no existe la columna indicada. Por favor, revisa el archivo.');
                setLoading(false); // Stop loading if there's an error
                return; // Exit the function
            }

            // Validate that nombre and apellido contain only letters
            
            const nameRegex = /^[^\d_]+$/u;
            if (!nameRegex.test(nombre) || !nameRegex.test(apellido)) {
                setError('El nombre y el apellido deben contener solo letras.');
                setLoading(false); // Stop loading if there's an error
                return; // Exit the function
            }
        }

        try {
            for (const row of csvData) {
                const nombre = row[mapping.nombre];
                const apellido = row[mapping.apellido];
                const email = row[mapping.email];
    
                const data = {
                    nombre,
                    apellido,
                    email,
                    curso_id: cursoId
                };
    
                await addDoc(collection(db, 'cursos', cursoId, 'estudiantes'), data);
            }
            toast.success('Datos guardados correctamente', {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Flip,
            });
            setTimeout(() => {
                onClose(); // Close modal after success
            }, 1500);
        } catch (error) {
            console.error('Error al guardar los datos:', error);
            setError('Error al guardar los datos. Por favor, intenta nuevamente.');
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <div className="container mx-auto p-4">
            <input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                className="mb-4 rounded bg-gray-200"
            />
            <button 
                onClick={handleFileUpload}
                className="bg-blue-500 text-white px-4 py-2 rounded block mx-auto"
            >
                Subir Archivo
            </button>
            
            <div
                style={{
                    transition: 'max-height 0.5s ease-in-out, opacity 0.5s ease-in-out',
                    maxHeight: showMapping ? '1000px' : '0',
                    opacity: showMapping ? 1 : 0,
                    overflow: 'hidden',
                }}
                className="overflow-hidden"
            >
                <h3 className="text-xl font-bold mb-4 mt-4">Asignar Columnas</h3>
                <div className="mb-4">
                    <label className="block mb-1">Nombre</label>
                    <select
                        name="nombre"
                        value={mapping.nombre}
                        onChange={handleMappingChange}
                        className="border p-2 mb-2 w-full"
                    >
                        <option value="">Selecciona una columna</option>
                        {columns.map((col, index) => (
                            <option key={index} value={col}>{col}</option>
                        ))}
                    </select>
                    <label className="block mb-1">Apellido</label>
                    <select
                        name="apellido"
                        value={mapping.apellido}
                        onChange={handleMappingChange}
                        className="border p-2 mb-2 w-full"
                    >
                        <option value="">Selecciona una columna</option>
                        {columns.map((col, index) => (
                            <option key={index} value={col}>{col}</option>
                        ))}
                    </select>
                    <label className="block mb-1">Email</label>
                    <select
                        name="email"
                        value={mapping.email}
                        onChange={handleMappingChange}
                        className="border p-2 mb-2 w-full"
                    >
                        <option value="">Selecciona una columna</option>
                        {columns.map((col, index) => (
                            <option key={index} value={col}>{col}</option>
                        ))}
                    </select>
                </div>
                <div className="flex justify-center items-center space-x-4">
                    <button
                        onClick={handleSave}
                        className="bg-green-500 text-white px-4 py-2 rounded flex items-center"
                        disabled={loading}
                    >
                        {loading ? (
                            <>
                            <svg aria-hidden="true" className="ml-3 mr-3 inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.4322 21.3669 83.015 25.3965C86.9278 29.6611 90.2241 34.2596 92.7838 39.0423C93.0146 39.1767 93.3316 39.0792 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            <span>Guardando...</span>
                            </>
                        ) : (
                            'Guardar Datos'
                        )}
                    </button>
                    <button
                        onClick={onClose}
                        className="bg-gray-500 text-white px-4 py-2 rounded"
                    >
                        Cancelar
                    </button>
                </div>
                {error && <p className="text-red-500 mt-4">{error}</p>}
                {successMessage && <p className="text-green-500 mt-4">{successMessage}</p>}
            </div>
        </div>
    );
};

export default CSVUpload;