import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto'; // Requerido para Chart.js 3.x
import { convertTimestamp } from "../services/DateFormatter";

const activityNames = {
  A000: 'Caza Palabras',
  A001: 'Comprensión Explícita',
  A002: 'Comprensión Implícita',
  A003: 'Inferencia',
  A004: 'Ordenamiento de Sucesos'
};

const groupDataByActivity = (data) => {
  // Agrupación de datos por actividad
  const groupedData = {
    A000: [],
    A001: [],
    A002: [],
    A003: [],
    A004: []
  };

  data.forEach((entry) => {
    const activityType = entry.ActividadId.match(/A\d{3}$/);
    if (activityType) {
      const type = activityType[0];
      groupedData[type].push({ fecha: convertTimestamp(entry.FechaInicio), puntaje: entry.Puntaje });
    }
  });

  return groupedData;
};

const ProgressChart = ({ data }) => {
  const [selectedActivity, setSelectedActivity] = useState('A000'); // Actividad por defecto
  const groupedData = groupDataByActivity(data);

  // Formato de fecha mejorado que incluye la hora
  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
    return new Date(date).toLocaleString('es-ES', options);
  };

  // Crear un select para elegir la actividad
  const handleChange = (event) => {
    setSelectedActivity(event.target.value);
  };

  // Obtener los datos de la actividad seleccionada
  const selectedData = groupedData[selectedActivity];

  // Preparar el dataset para la actividad seleccionada
  const dataset = {
    label: `Progreso en ${activityNames[selectedActivity]}`,
    data: selectedData.length > 0 ? selectedData.map(entry => entry.puntaje) : [],
    borderColor: '#ff6384', // Color para la actividad
    fill: false
  };

  const chartData = {
    labels: selectedData.length > 0 ? selectedData.map(entry => formatDate(entry.fecha)) : [], // Formato mejorado de fecha
    datasets: [dataset] // Solo un dataset para la actividad seleccionada
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        mode: 'index',
        intersect: false
      }
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Fechas',
          font: {
            weight: 'bold', // Aplica negrita al título del eje x
          }
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10
        }
      },
      y: {
        title: {
          display: true,
          text: 'Puntaje',
          font: {
            weight: 'bold', // Aplica negrita al título del eje x
          }
        },
        min: 0,
        max: 120
      }
    }
  };

  return (
    <div className="chart-container flex flex-col items-center w-full p-6">
      {/* Contenedor del select con descripción */}
      <div className="flex flex-col items-center sm:flex-row">
        <label className="text-gray-700 mr-2 font-semibold">
          Selecciona una actividad:
        </label>
        <select
          onChange={handleChange}
          value={selectedActivity}
          className="select select-bordered select-primary select-sm"
        >
          {Object.keys(groupedData).map(activity => (
            <option key={activity} value={activity}>
              {activityNames[activity]} {/* Mostrar el nombre de la actividad */}
            </option>
          ))}
        </select>
      </div>

      {/* Verificar si hay datos para la actividad seleccionada */}
      {selectedData.length > 0 ? (
        <div className="chart-wrapper w-full h-full">
          <Line data={chartData} options={options} />
        </div>
      ) : (
        <p>No hay datos disponibles para la actividad seleccionada.</p>
      )}
    </div>
  );
};

export default ProgressChart;
