import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faChartBar, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

// Mapeo de códigos de actividades a descripciones
const descripcionActividades = {
  A000: 'Caza Palabras',
  A001: 'Comprensión Explícita',
  A002: 'Comprensión Implícita',
  A003: 'Inferencia',
  A004: 'Ordenamiento',
};

const calculateStats = (data) => {
  const activityData = {
    A000: { times: [], scores: [] },
    A001: { times: [], scores: [] },
    A002: { times: [], scores: [] },
    A003: { times: [], scores: [] },
    A004: { times: [], scores: [] },
  };

  data.forEach((activity) => {
    const activityType = activity.ActividadId.match(/A\d{3}$/);

    if (activityType) {
      const { FechaInicio, FechaFin, Puntaje } = activity;
      const type = activityType[0];

      if (
        FechaInicio &&
        FechaFin &&
        FechaInicio.seconds !== undefined &&
        FechaFin.seconds !== undefined
      ) {
        const startTime = FechaInicio.seconds + (FechaInicio.nanoseconds || 0) / 1e9;
        const endTime = FechaFin.seconds + (FechaFin.nanoseconds || 0) / 1e9;
        const duration = endTime - startTime;
        activityData[type].times.push(duration);
      }

      if (Puntaje !== undefined && Puntaje !== null) {
        activityData[type].scores.push(Puntaje);
      }
    }
  });

  const stats = {};
  for (const type in activityData) {
    const times = activityData[type].times;
    const scores = activityData[type].scores;

    const avgTime =
      times.length ? times.reduce((acc, time) => acc + time, 0) / times.length : 'No se han realizado esas actividades';
    const avgScore =
      scores.length ? scores.reduce((acc, score) => acc + score, 0) / scores.length : 'No se han realizado esas actividades';

    stats[type] = {
      avgTime,
      avgScore,
      totalActivities: scores.length 
    };
  }

  return stats;
};

// Función para convertir segundos a formato minutos
const formatTime = (time) => {
  return time > 60 ? `${(time / 60).toFixed(2)} minutos` : `${time.toFixed(2)} segundos`;
};

// Componente de visualización de estadísticas
const ActivityStatsDisplay = ({ data, classData }) => {
  const stats = calculateStats(data);
  console.log(classData);

  return (
    <div className="mb-5">
      <h3 className="bg-blue-500 text-white p-2 rounded-t-md flex items-center text-xl font-semibold mb-4">
        <FontAwesomeIcon icon={faChartBar} className="mr-3 text-yellow-500" />
        Estadísticas por tipo de actividad
      </h3>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
        {Object.entries(stats).map(([type, stat]) => {
          // Obtener los promedios de clase para esta actividad
          const classAvgScore = parseFloat(classData.meanScores[`promedio${type}`] || 0);
          // Calcular la diferencia directa
          const scoreDifference = stat.avgScore - classAvgScore;
          const isHigher = scoreDifference >= 0;
          const isNoActivity = typeof stat.avgScore !== 'number'; // Verificamos si avgScore es un número

          // Mensaje de tooltip
          const tooltipText = isNoActivity
            ? "El alumno no ha realizado ninguna actividad de este tipo."
            : `De un total de ${stat.totalActivities} actividades`;

          return (
            <div key={type} className="bg-gray-100 shadow-md rounded-lg p-4 flex flex-col items-center">
              <h4 className="text-base font-semibold text-gray-700 mb-4 text-center">
                {/* Muestra la descripción de la actividad en lugar del código */}
                {descripcionActividades[type] || `Actividad ${type}`}
              </h4>
            
              {/* Progreso radial para puntaje promedio con tooltip */}
              <div className="tooltip" data-tip={tooltipText}>
                <div
                  className={classNames('radial-progress text-white items-center border-4 mb-4 transition-transform duration-300 ease-in-out transform hover:scale-105', {
                    'bg-gray-400 border-gray-400': isNoActivity,
                    'bg-red-500 border-red-500': stat.avgScore < 34 && !isNoActivity,
                    'bg-amber-300 border-amber-300': stat.avgScore >= 34 && stat.avgScore < 68,
                    'bg-lime-500 border-lime-500': stat.avgScore >= 68,
                  })}
                  style={{ '--value': isNoActivity ? 0 : Math.round(stat.avgScore), '--thickness': '5px' }}
                  role="progressbar"
                >
                  {isNoActivity ? 'N/A' : `${stat.avgScore.toFixed(2)}%`}
                </div>
              </div>
            
              {/* Tiempo promedio */}
              <div className="text-gray-600 text-center">
                <FontAwesomeIcon icon={faClock} className="mr-2 text-yellow-500" />
                <span className="font-semibold text-sm text-grey-500">
                  {typeof stat.avgTime === 'number' ? formatTime(stat.avgTime) : 'No disponible'}
                </span>
              </div>
            
              {/* Diferencia respecto al promedio */}
              <div className="mt-4 w-full flex justify-between items-center">
                <div className="border border-gray-300 rounded-lg bg-transparent p-2 flex items-center">
                  <FontAwesomeIcon
                    icon={isNoActivity ? faArrowDown : (isHigher ? faArrowUp : faArrowDown)} // Cambia el icono según si es mayor o menor
                    className={isNoActivity ? 'text-gray-400 mr-2' : (isHigher ? 'text-green-500 mr-2' : 'text-red-500 mr-2')} // Cambia el color según si es mayor o menor
                  />
                  <span className="text-sm font-semibold">
                    {isNoActivity ? '0' : Math.abs(scoreDifference).toFixed(2)} {/* Diferencia directa */}
                  </span>
                </div>
                
                {/* Mensaje cuando no hay actividades */}
                <div className="flex justify-center w-full ml-2 text-xs">
                  {isNoActivity ? 
                    "No se puede comparar ya que no ha realizado actividades." :
                    `Con respecto a un promedio de curso de ${classAvgScore.toFixed(2) || 0}`
                  }
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ActivityStatsDisplay;


