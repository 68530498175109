import React, { useEffect, useState } from "react";
import StarRating from "../components/StarRating"; // Componente de calificación de estrellas
import { toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";
import { es } from "date-fns/locale"; // Para formato de fecha en español
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faBook,
  faCheckCircle,
  faExclamationCircle,
  faAngleRight,
  faAngleLeft,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import {
  collection,
  query,
  getDocs,
  onSnapshot,
  runTransaction,
  doc,
} from "firebase/firestore";
import { db } from "../services/db";
import { useClasses } from "../services/ClassesContext";
import { convertTimestamp } from "../services/DateFormatter";
import LoadingScreen from "../components/LoadingScreen";

const WritingActivities = () => {
  const [respuestasExtras, setRespuestasExtras] = useState([]); // Estado para las respuestas extra
  const [filtro, setFiltro] = useState("todos"); // Estado para el filtro: 'todos', 'revisados', 'pendientes'
  const [loading, setLoading] = useState(true); // Estado de carga
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [tipoFiltro, setTipoFiltro] = useState("todos"); // State for tipo filter
  const { selectedClass } = useClasses();

  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const itemsPerPage = 6; // Number of items to display per page
  // Función para determinar el tipo de actividad según el prefijo de ActividadId
  const obtenerTipoActividad = (actividadId) => {
    if (actividadId.startsWith("ESP")) {
      return "Escritura Espontánea";
    } else if (actividadId.startsWith("PRL")) {
      return "Préstamo literario"; // Cambia el nombre según corresponda
    } else if (actividadId.startsWith("TNL")) {
      return "Texto no literario"; // Cambia el nombre según corresponda
    }
    // Aquí puedes agregar más condiciones para diferentes tipos de actividad
    return "Desconocido"; // Valor por defecto si no coincide con ninguna condición
  };

  // useEffect para cargar los datos reales desde Firestore
  useEffect(() => {
    const obtenerRespuestas = async () => {
      try {
        // Obtener la colección de estudiantes de la clase seleccionada
        const studentsCollection = collection(
          db,
          `cursos/${selectedClass}/estudiantes`
        );
        const studentsQuery = query(studentsCollection);

        // Suscripción en tiempo real para obtener los estudiantes
        const unsubscribe = onSnapshot(studentsQuery, async (snapshot) => {
          const allRespuestas = await Promise.all(
            snapshot.docs.map(async (studentDoc) => {
              const studentData = studentDoc.data(); // Datos del estudiante
              const studentId = studentDoc.id; // ID del estudiante (Firestore)

              // Obtener la subcolección de respuestas del estudiante
              const responsesCollection = collection(
                db,
                `cursos/${selectedClass}/estudiantes/${studentId}/respuestas_escritura`
              );
              const responsesSnapshot = await getDocs(responsesCollection);

              const respuestasData = responsesSnapshot.docs
                .map((responseDoc) => {
                  const data = responseDoc.data(); // Datos de la respuesta
                  const tipoActividad = obtenerTipoActividad(data.ActividadId); // Determinar el tipo de actividad

                  // Construir los datos de la respuesta según tipoActividad
                  if (tipoActividad === "Escritura Espontánea") {
                    return {
                      estudianteId: studentId, // Identificador del estudiante
                      nombreAlumno:
                        studentData.nombre + " " + studentData.apellido ||
                        "Nombre desconocido",
                      actividadId: data.ActividadId,
                      fechaInicio: convertTimestamp(data.FechaInicio),
                      fechaFin: convertTimestamp(data.FechaFin),
                      tipo: tipoActividad,
                      puntaje: data.Puntaje,
                      revisado: data.Revisado,
                      respuestas: data.Respuestas, // Array de respuestas (como imágenes y textos)
                    };
                  } else if (tipoActividad === "Préstamo literario") {
                    return {
                      estudianteId: studentId,
                      nombreAlumno:
                        studentData.nombre + " " + studentData.apellido ||
                        "Nombre desconocido",
                      actividadId: data.ActividadId,
                      fechaInicio: convertTimestamp(data.FechaInicio),
                      fechaFin: convertTimestamp(data.FechaFin),
                      tipo: tipoActividad,
                      puntaje: data.Puntaje,
                      revisado: data.Revisado,
                      respuesta: data.Respuesta, // Usar la respuesta única directamente
                    };
                  }
                  // Ignorar las actividades de tipo TNL
                  return null; // Indicar que no se debe incluir
                })
                .filter((respuesta) => respuesta !== null); // Filtrar las respuestas nulas (TNL)

              return respuestasData; // Devuelve las respuestas del estudiante
            })
          );

          // Aplanar las respuestas de todos los estudiantes en un solo array
          const flattenedRespuestas = allRespuestas.flat();
          // Ordenar las respuestas por la fecha de inicio (fechaInicio)
          const respuestasOrdenadas = flattenedRespuestas.sort((a, b) => new Date(b.fechaInicio) - new Date(a.fechaInicio));

          setRespuestasExtras(respuestasOrdenadas); // Guardar las respuestas ordenadas
          console.log(flattenedRespuestas);
          setLoading(false);
        });

        return () => unsubscribe(); // Cancelar la suscripción cuando se desmonta el componente
      } catch (error) {
        console.error("Error obteniendo las respuestas:", error);
        setLoading(false);
      }
    };

    obtenerRespuestas(); // Llama a la función para obtener las respuestas
  }, [selectedClass]); // Dependencia para ejecutar cuando cambie la clase seleccionada

  // Función para manejar la actualización de la calificación
  const handleRatingChange = async (combinacionId, newRating, oldRating) => {
    const [estudianteId, actividadId] = combinacionId.split("-");
    console.log(estudianteId, actividadId);

    const estudianteDocRef = doc(
      db,
      `cursos/${selectedClass}/estudiantes/${estudianteId}/respuestas_escritura/${actividadId}`
    );

    // Referencia al documento del estudiante (donde está datos_juego, estrellas, etc.)
    const estudianteInfoRef = doc(
      db,
      `cursos/${selectedClass}/estudiantes/${estudianteId}`
    );

    // Usar `toast.promise` para mostrar los diferentes estados de la transacción
    toast.promise(
      runTransaction(db, async (transaction) => {
        const estudianteDoc = await transaction.get(estudianteInfoRef);
        if (!estudianteDoc.exists()) {
          throw new Error("El documento del estudiante no existe!");
        }

        const estudianteData = estudianteDoc.data();
        const estrellasActuales = estudianteData.datos_juego.estrellas || 0; // Evita null si no existe

        const estrellasActualizadas = estrellasActuales - oldRating + newRating; // O ajusta aquí la lógica de la diferencia

        transaction.update(estudianteInfoRef, {
          "datos_juego.estrellas": estrellasActualizadas, // Actualizar las estrellas
        });

        // Actualizar también la calificación en respuestas_escritura
        transaction.update(estudianteDocRef, {
          Puntaje: newRating,
          Revisado: true,
        });
      }),
      {
        pending: "Actualizando calificación...", // Mensaje mientras se ejecuta la promesa
        success: "Entrega calificada con éxito", // Mensaje de éxito
        error: "Error al actualizar la entrega", // Mensaje de error
      },
      {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Flip,
      }
    );
  };

  const filtrarRespuestas = (respuestas) => {
    let filtered = respuestas;

    // Filter by reviewed status
    if (filtro === "revisados") {
      filtered = filtered.filter((r) => r.puntaje > 0);
    } else if (filtro === "pendientes") {
      filtered = filtered.filter((r) => r.puntaje === 0);
    }

    // Further filter by search query
    if (searchQuery) {
      filtered = filtered.filter((respuesta) =>
        respuesta.nombreAlumno.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Further filter by tipo
    if (tipoFiltro !== "todos") {
      filtered = filtered.filter((respuesta) => respuesta.tipo === tipoFiltro);
    }

    return filtered; // Return filtered responses
  };

  // Formateo de la fecha
  const formatearFecha = (fechaISO) => {
    return format(new Date(fechaISO), "dd 'de' MMMM 'de' yyyy, HH:mm", {
      locale: es,
    });
  };

  const paginatedResponses = (filteredResponses) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredResponses.slice(startIndex, startIndex + itemsPerPage);
  };

  const totalPages = Math.ceil(
    filtrarRespuestas(respuestasExtras).length / itemsPerPage
  );
  console.log("total pages", totalPages);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-3xl font-semibold mb-4">Entregas extras</h2>

  {/* Search and Tipo Filter Section with Buttons */}
  <div className="flex flex-col md:flex-row items-center gap-3 mb-4">
        <div className="relative flex md:w-96">
          <span className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400">
            <FontAwesomeIcon icon={faSearch} />
          </span>
          <input
            type="text"
            placeholder="Buscar por alumno..."
            className="w-full pl-10 h-10 rounded-md border-2 border-gray-400 focus:outline-none focus:border-gray-400"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <select
          value={tipoFiltro}
          onChange={(e) => setTipoFiltro(e.target.value)}
          className="md:w-64 h-10 border-2 border-primary focus:outline-none focus:border-primary text-primary rounded-md px-2 md:px-3 py-0 md:py-1"
        >
          <option value="todos">Todos los tipos de actividad</option>
          <option value="Préstamo literario">Préstamo literario</option>
          <option value="Escritura Espontánea">Escritura espontánea</option>
          {/* Add more options as needed based on your types */}
        </select>

        {/* Filtro de entregas Buttons */}
        <div className="flex flex-row gap-2 ml-auto">
          <button
            className={`btn btn-sm btn-neutral h-10 w-36 ${filtro === "todos" ? "text-white" : "font-normal btn-outline"}`}
            onClick={() => setFiltro("todos")}
          >
            Todas
          </button>
          <button
            className={`btn btn-sm btn-success h-10 w-36 ${filtro === "revisados" ? "text-white" : "font-normal btn-outline"}`}
            onClick={() => setFiltro("revisados")}
          >
            Revisadas
          </button>
          <button
            className={`btn btn-sm btn-error h-10 w-36 ${filtro === "pendientes" ? "text-white" : "font-normal btn-outline"}`}
            onClick={() => setFiltro("pendientes")}
          >
            Pendientes
          </button>
        </div>
      </div>

      <ul className="mt-4 space-y-4">
  {paginatedResponses(filtrarRespuestas(respuestasExtras)).length === 0 ? (
    <li className="flex justify-center p-4 border rounded shadow">
      <p className="text-gray-500">No se encontraron resultados.</p>
    </li>
  ) : (
    paginatedResponses(filtrarRespuestas(respuestasExtras)).map((respuesta) => (
      <li
        key={`${respuesta.estudianteId}-${respuesta.actividadId}`}
        className="flex flex-col md:flex-row items-start md:items-center justify-between p-4 border rounded shadow"
      >
        {/* Parte izquierda: Información del alumno */}
        <div className="flex items-center md:w-1/3 w-full mb-4 md:mb-0">
          <div className="mr-4">
            {/* Selecciona el icono según el tipo de actividad */}
            {respuesta.tipo === "Préstamo literario" ? (
              <FontAwesomeIcon
                icon={faBook}
                className="text-primary w-8 h-8"
              />
            ) : (
              <FontAwesomeIcon
                icon={faPencil}
                className="text-primary w-8 h-8"
              />
            )}
          </div>
          <div>
            <h3 className="font-medium">{respuesta.nombreAlumno}</h3>
            <p className="text-sm text-gray-500">{respuesta.tipo}</p>
            <p className="text-sm text-gray-500">
              Fecha: {formatearFecha(respuesta.fechaInicio)}
            </p>
          </div>
        </div>

        {/* Parte derecha: Estado de revisión o rating */}
        <div className="flex flex-col items-end md:w-2/3 w-full">
          {respuesta.revisado ? (
            <span className="text-green-500 font-semibold flex items-center">
              <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
              Revisado
            </span>
          ) : (
            <span className="text-red-500 font-semibold flex items-center">
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className="mr-2"
              />
              Pendiente
            </span>
          )}

          <div className="collapse mt-2 bg-base-200 w-full">
            <input type="checkbox" />
            <div className="collapse-title text-sm font-medium">
              Ver entrega
            </div>
            <div className="collapse-content">
              {/* Contenido condicional basado en el tipo de actividad */}
              {respuesta.tipo === "Escritura Espontánea" ? (
                // Escritura espontánea: Muestra imágenes y textos
                <div className="flex flex-col">
                  {respuesta.respuestas[0] && (
                    <div className="flex mb-4">
                      <div className="w-1/3 flex justify-center">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/${respuesta.respuestas[0].Imagen}.png`}
                          alt="Entrega 1"
                          className="w-48 h-48"
                        />
                      </div>
                      <div className="w-2/3 pl-4">
                        <div className="h-44 mr-5 bg-white p-4 rounded shadow">
                          <p className="text-base mb-4">
                            {respuesta.respuestas[0].Texto}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {respuesta.respuestas[1] && (
                    <div className="flex">
                      <div className="w-1/3 flex justify-center">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/${respuesta.respuestas[1].Imagen}.png`}
                          alt="Entrega 2"
                          className="w-48 h-48"
                        />
                      </div>
                      <div className="w-2/3 pl-4">
                        <div className="h-44 mr-5 bg-white p-4 rounded shadow">
                          <p className="text-base">
                            {respuesta.respuestas[1].Texto}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                // Préstamo literario: Muestra solo el texto
                <div className="flex flex-col">
                  <div className="w-full bg-white p-4 rounded shadow mb-4">
                    <p className="text-base italic">"{respuesta.respuesta}"</p>
                  </div>
                </div>
              )}

              {/* Calificación general al final */}
              <div className="w-full flex justify-center mb-4">
                <div className="w-96 px-4 py-2 rounded">
                  <div className="flex flex-col items-center">
                    <span className="text-base font-semibold text-custom-blue mb-2">
                      Calificación:
                    </span>
                    <div className="flex items-center bg-custom-blue px-4 py-2 rounded shadow-lg">
                      <StarRating
                        initialRating={respuesta.puntaje || 0}
                        onRatingChange={(newRating) =>
                          handleRatingChange(
                            `${respuesta.estudianteId}-${respuesta.actividadId}`,
                            newRating,
                            respuesta.puntaje
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    ))
  )}
</ul>
      {/* Pagination Controls */}
      <div className="flex justify-center items-center mt-4 space-x-2">
        {/* Previous Button */}
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="btn btn-outline btn-secondary btn-sm font-normal text-xs"
        >
          <FontAwesomeIcon icon={faAngleLeft} className="text-lg" />
          Anterior
        </button>

        {/* Page Numbers */}
        <div className="flex items-center space-x-2">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => setCurrentPage(index + 1)}
              className={`btn btn-sm ${currentPage === index + 1 ? 'btn-accent' : 'btn-outline btn-secondary'}`}
            >
              {index + 1}
            </button>
          ))}
        </div>

        {/* Next Button */}
        <button
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          className="btn btn-outline btn-secondary btn-sm font-normal text-xs"
        >
          Siguiente
          <FontAwesomeIcon icon={faAngleRight} className="text-lg" />
        </button>
      </div>
    </div>
  );
};

export default WritingActivities;