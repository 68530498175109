import React from 'react';
import sad from '../styles/sad.png'; // Ruta a tu icono PNG

const NotFound = () => (
  <div className="flex flex-col items-center justify-center min-h-screen p-6 overflow-hidden">
    <img 
      src={sad} 
      alt="Not Found" 
      className="w-48 mb-4 object-cover" // Ajusta el tamaño según sea necesario
    />
    <h2 className="text-xl font-semibold text-center mb-4">
      Lo sentimos, la ruta que estás buscando no existe.
    </h2>
    <p className="text-center mb-4">
      Verifica la URL o vuelve a la página anterior.
    </p>
    <p className="text-center mb-4">
      Si crees que es un error, contáctanos a armadilloapp.cl@gmail.com.
    </p>
  </div>
);

export default NotFound;
