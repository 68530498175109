import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../services/AuthProvider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import icon from '../styles/logo.png'; // Ruta a tu icono PNG
import LoadingScreen from '../components/LoadingScreen'; 

const Login = () => {
  const { loginUser, loading, teacherId } = useAuth();
  const navigate = useNavigate();
  const [authError, setAuthError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (teacherId) {
      navigate(`/${teacherId}/cursos`);
    } else {
      console.log("No hay un teacher ID");
    }
  }, [teacherId, navigate]);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    setEmailError('');
    setPasswordError('');
    setAuthError('');

    if (email.trim() === '') {
      setEmailError('¡No olvides añadir tu dirección de correo electrónico!');
      return;
    }

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError('Por favor ingrese un correo válido.');
      return;
    }

    if (password.trim() === '') {
      setPasswordError('¡No olvides añadir tu contraseña!');
      return;
    }

    loginUser(email, password)
        .then(() => {
            // Asegúrate de redirigir solo después de un inicio de sesión exitoso
            if (teacherId) {
                navigate(`/${teacherId}/cursos`);
            }
        })
        .catch((error) => {
            setAuthError("Correo electrónico o contraseña incorrectos. Inténtalo de nuevo.");
            console.log("Login Error:", error.message);
        });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (authError) {
      console.log("Mensaje de error:", authError); // Imprime el mensaje configurado con setError
    }
  }, [authError]); // Este effect se ejecuta cuando 'error' cambia
  
  return (
    <div className="flex h-screen bg-blue-400">
      <div className="w-full max-w-xs m-auto bg-blue-100 rounded p-5 relative">
      {loading && <LoadingScreen />}  {/* Show loading screen when loading */}

        <header>
          <img
            className="w-20 mx-auto mb-5"
            src={icon}
            alt="Logo"
          />
        </header>
        <form onSubmit={handleFormSubmit}>
          <div>
            <label className="block mb-2 text-blue-500" htmlFor="email">
              Correo electrónico
            </label>
            <input
              className={`w-full p-2 mb-2 text-blue-700 border-b-2 outline-none focus:bg-gray-300 ${
                emailError ? 'border-red-500' : 'border-blue-500'
              }`}
              type="text"
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Introduce tu correo electrónico"
              spellCheck="false"
            />
            {emailError && (
              <div className="mb-4 text-red-500 text-sm">
                {emailError}
              </div>
            )}
          </div>
          <div>
            <label className="block mb-2 text-blue-500" htmlFor="password">
              Contraseña
            </label>
            <div className="relative">
              <input
                className={`w-full p-2 text-blue-700 border-b-2 outline-none focus:bg-gray-300 pr-10 ${
                  passwordError ? 'border-red-500' : 'border-blue-500'
                }`}
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Introduce tu contraseña"
                spellCheck="false"
              />
              <button
                type="button"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                className="absolute right-2 top-2/4 transform -translate-y-2/4"
                aria-label={showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
              >
                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} size="lg" />
              </button>
            </div>
            {passwordError && (
              <div className="mt-2 text-red-500 text-sm">
                {passwordError}
              </div>
            )}
          </div>
          {authError && (
            <div className="mt-4 text-red-500 text-sm">
              {authError}
            </div>
          )}
          <button
            type="submit"
            className="w-full py-2 mt-4 text-white bg-blue-500 rounded"
            disabled={loading}
          >
            Iniciar sesión
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;