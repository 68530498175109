import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useClasses } from "../services/ClassesContext";

const ProgresoActividades = ({ data, releasedLevels }) => {
  const { levels } = useClasses(); 
  const [currentIndex, setCurrentIndex] = useState(0); 

  const nivelSeleccionado = Array.from(releasedLevels)[currentIndex]; 

  const descripcionActividades = {
    A000: 'Caza Palabras',
    A001: 'Comprensión Explícita',
    A002: 'Comprensión Implícita',
    A003: 'Inferencia',
    A004: 'Ordenamiento de sucesos',
  };

  const actividadPorNivel = {};

  data.forEach(activity => {
    const actividadId = activity.ActividadId;
    const nivel = actividadId.slice(0, 6); 
    
    if (releasedLevels.has(nivel)) {
      if (!actividadPorNivel[actividadId] || activity.Puntaje > actividadPorNivel[actividadId].Puntaje) {
        actividadPorNivel[actividadId] = {
          puntaje: activity.Puntaje,
          completado: true
        };
      }
    }
  });

  const progresoPorNivel = Array.from(releasedLevels).map(nivel => {
    const actividades = [];
    for (let i = 0; i < 5; i++) {
      const actividadId = `${nivel}A00${i}`;
      const actividadData = actividadPorNivel[actividadId];
      actividades.push({
        id: actividadId,
        completado: actividadData ? actividadData.completado : false,
        puntaje: actividadData ? actividadData.puntaje : null
      });
    }
    return { nivel, actividades };
  });

  const obtenerNombreNivel = (nivelId) => {
    const nivel = levels.find(l => l.id === nivelId);
    return nivel ? nivel.nombre : `Unidad ${nivelId}`;
  };

  const handleNext = () => {
    setCurrentIndex((currentIndex + 1) % releasedLevels.size);
  };

  const handlePrev = () => {
    setCurrentIndex((currentIndex - 1 + releasedLevels.size) % releasedLevels.size);
  };

  const progresoFiltrado = progresoPorNivel.find(({ nivel }) => nivel === nivelSeleccionado);

  const getColorByScore = (puntaje) => {
    if (puntaje === null) return 'bg-gray-300'; 
    if (puntaje <= 33) return 'bg-red-500'; 
    if (puntaje <= 67) return 'bg-yellow-500'; 
    return 'bg-green-500'; 
  };

  return (
    <div className="relative overflow-hidden px-4">
      {releasedLevels.size === 0 ? (
        <p>No se han liberado unidades todavía.</p>
      ) : (
        <>
          <div className="flex items-center justify-between mb-4">
            <button onClick={handlePrev}>
              <FontAwesomeIcon icon={faChevronLeft} size="2x" className="text-gray-600" />
            </button>

            <h3 className="text-xl font-semibold text-center">
              {obtenerNombreNivel(nivelSeleccionado)}
            </h3>

            <button onClick={handleNext}>
              <FontAwesomeIcon icon={faChevronRight} size="2x" className="text-gray-600" />
            </button>
          </div>

          {/* Grid de tarjetas de actividades */}
          <div className="flex transition-transform duration-500 ease-in-out" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
            {progresoPorNivel.map((progreso, index) => (
              <div key={index} className="flex-shrink-0 w-full">
                <div className="flex flex-wrap gap-4 justify-between">
                  {progreso.actividades.map(actividad => (
                    <div key={actividad.id} className="bg-white shadow-md rounded-lg p-4 flex-1 min-w-[150px]">
                      <div
                        className={`h-16 w-16 mx-auto rounded-full flex items-center justify-center ${getColorByScore(
                          actividad.puntaje
                        )}`}
                      >
                        <span className="text-white text-lg">
                          {actividad.puntaje !== null ? actividad.puntaje.toFixed(0) : '-'}
                        </span>
                      </div>
                      <div className="mt-4 text-center">
                        <h4 className="text-base font-semibold text-gray-700">
                          {descripcionActividades[actividad.id.slice(-4)] || `Actividad ${actividad.id.slice(-1)}`}
                        </h4>
                        <p>
                          {actividad.puntaje !== null ? `Puntaje: ${actividad.puntaje.toFixed(0)}` : 'No completada'}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ProgresoActividades;