import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import ActivityTable from '../components/ActivityTable'; // Import your component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons'; // Asegúrate de importar el ícono
import { Link } from 'react-router-dom'; // Asegúrate de importar Link
import { useClasses } from '../services/ClassesContext';
import { useAuth } from '../services/AuthProvider';


const customStyles = {
    rows: {
        style: {
            backgroundColor: '#f9f9f9', // Color de fondo por defecto
        },
    },
    headCells: {
        style: {
            backgroundColor: 'rgba(8, 83, 184,1)', // Color de fondo de los encabezados
            color: '#fcfcfc', // Color de texto de los encabezados
            fontSize: '12px',
            border: '1px solid rgba(28,71,137,0.3)', // Borde superior con menos opacidad
                       // Para permitir el ajuste en varias líneas
                       whiteSpace: 'normal', // Ajustar el texto en múltiples líneas
                       wordBreak: 'break-word', // Corta palabras largas si es necesario

        },
    },
    cells: {
        style: {
            width: 'auto', // Dejar que el ancho se ajuste automáticamente
           // Para permitir el ajuste en varias líneas
           whiteSpace: 'normal', // Ajustar el texto en múltiples líneas
           wordBreak: 'break-word', // Corta palabras largas si es necesario
        },
    },
};


const LevelTable = ({ data, selectedLevel, selectedActivity, generateActivityName, filterOptions, releasedLevelsName }) => {
    const [filtered, setFiltered] = useState(data);
    const { selectedClass } = useClasses();
    const { teacherId } = useAuth();
    const selectedActivityName = generateActivityName(selectedActivity);
    console.log("filtered", filtered);

    const paginationOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        noDataComponent: 'No hay datos para mostrar',
    };

    const calculateCompletedLevelsForStudent = (student, namesByLevel) => {
        let completedLevelsCount = 0;
        const totalLevels = Object.keys(namesByLevel).length;
      
        // Access the student's activities (assuming it's an array stored in student.activities)
        const studentActivities = student.actividades || [];
        //console.log(`Student Name: ${student.nombre}`); // Print student's name for context
        // Loop over each level in namesByLevel (e.g., { Level1: 'Beginner', Level2: 'Intermediate', ... })
        Object.keys(namesByLevel).forEach((levelId) => {
          const requiredActivities = [
            `${levelId}A000`,
            `${levelId}A001`,
            `${levelId}A002`,
            `${levelId}A003`,
            `${levelId}A004`,
          ];
    // Check if all required activities for this level exist as keys in studentActivities
    const missingActivities = requiredActivities.filter(activityId => !(activityId in studentActivities));

    const isLevelComplete = missingActivities.length === 0;
      
          // If the level is complete, increment the counter
          if (isLevelComplete) {
            completedLevelsCount += 1;
          }
        });
      
        return {
          completedLevelsCount,
          totalLevels,
        };
      };
      


// Función para renderizar el puntaje con colores y estilo
const renderPuntaje = (puntaje) => {
    if (puntaje === undefined || puntaje === null || puntaje === -1) {
        return (
            <div style={{ 
                backgroundColor: 'rgba(128, 128, 128, 0.1)', 
                borderRadius: '12px', 
                padding: '4px 8px', 
                display: 'inline-block', 
                minWidth: '60px', 
                textAlign: 'center', 
                color: '#808080', // Color de texto gris
                fontStyle: 'italic' // Estilo en cursiva para indicar que es "N/A"
            }}>
                N/A
            </div>
        );
    } else {
        // Convertir puntaje a número y formatearlo a 2 decimales
        const numericPuntaje = parseFloat(puntaje);
        if (!isNaN(numericPuntaje)) {
            puntaje = numericPuntaje.toFixed(2); // Formatear a 2 decimales
        }
    }

    const color = obtenerColorPuntaje(puntaje);
    return (
        <div style={{ backgroundColor: color, borderRadius: '12px', padding: '4px 8px', display: 'inline-block', minWidth: '60px', textAlign: 'center' }}>
            {puntaje}
        </div>
    );
};

// Función para calcular el color basado en el puntaje 
const obtenerColorPuntaje = (puntaje) => {
    if (puntaje === '-') {
        return `rgba(128, 128, 128, 0.1)`;
    }

    const percentage = puntaje / 100;

    if (percentage <= 0.33) {
        return `#ef4444`; // Rojo
    } else if (percentage <= 0.66) {
        return `#fcd34d`; // Amarillo
    } else {
        return `#a3e635`; // Verde
    }
};


useEffect(() => {
    let filteredData = data;

    if (selectedLevel !== 'General' && selectedActivity) {
        filteredData = data.map(student => ({
            ...student,
            [selectedActivity]: student[selectedActivity] || 0,
        }));
    }

    setFiltered(filteredData);
}, [selectedLevel, selectedActivity, data]);


    // Function to calculate mean of specified columns
    const calculateMean = (row) => {
        // Extract values and convert to numbers, filtering out NaN
        const scores = [
            row.promedioA000,
            row.promedioA001,
            row.promedioA002,
            row.promedioA003,
            row.promedioA004,
        ]
        .map(value => parseFloat(value))
        .filter(value => !isNaN(value)); // Filter out NaN values
    
        if (scores.length === 0) return '-'; // No valid scores available

        // Calculate total and mean, considering only valid scores
        const total = scores.reduce((acc, score) => acc + score, 0);
        const mean =  total / scores.length;
    
        return mean.toFixed(2); // Adjust decimal places as needed
    };
    
// Function to calculate the average score from the activities
const calculateAverageOfALevel = (row) => {
    // Extract activity IDs from filterOptions based on the selected level
    const activityIds = filterOptions[selectedLevel] || [];

    // Retrieve scores from the row for the given activity IDs
    const scores = activityIds
        .map(activityId => {
            const score = row.actividades[activityId]?.Puntaje;

            // If the score is missing (i.e., no response), treat it as a negative number for sorting and calculations
            if (score === null || score === undefined) {
                return -1;  // Puntaje no respondido, tratado como -1 para ordenación
            }

            return parseFloat(score);
        })
        .filter(puntaje => puntaje !== -1 && !isNaN(puntaje)); // Filtrar los puntajes no respondidos y NaN

    // Si no hay puntajes válidos, devolver -1 para indicar que no se respondió
    if (scores.length === 0) return -1; // Devolver -1 si no hay puntajes válidos disponibles

    // Calcular el promedio solo de los puntajes válidos
    const total = scores.reduce((acc, score) => acc + score, 0);
    const average = total / scores.length;
    console.log(average);

    // Redondear el promedio a 2 decimales
    return Math.round(average * 100) / 100;
};



    // Define columns based on filterOptions
    const columns = [
        { name: 'Nombre', selector: row => row.nombre, sortable: true },
        { name: 'Apellido', selector: row => row.apellido, sortable: true },
        ...(selectedLevel !== 'General'
            ? [
                ...((filterOptions[selectedLevel] || []).map(activityId => ({
                    name: generateActivityName(activityId),
                    selector: row => {
                        const puntaje_final = row[activityId] || 0;
                        return Math.round(puntaje_final * 100) / 100;  // Redondear a 2 decimales sin usar toFixed
                    },
                    sortable: true,
                    cell: row => {
                        const puntaje = row.actividades[activityId]?.Puntaje;
                        const displayPuntaje = puntaje === -1 ? '-' : renderPuntaje(puntaje);  // Mostrar '-' si el valor es -1
                        return (
                            <div className="text-sm font-medium text-gray-900 text-center">
                                {displayPuntaje}  {/* Mostrar '-' si no hay respuesta, o el puntaje calculado */}
                            </div>
                        );
                    }
                }))),
                {
                    name: 'Puntaje final',
                    selector: row => {
                        // Obtener el puntaje, redondearlo a 2 decimales
                        const puntaje_final = calculateAverageOfALevel(row) || 0;
                        return Math.round(puntaje_final * 100) / 100;  // Redondear a 2 decimales sin usar toFixed
                    },
                    sortable: true,
                    cell: row => {
                        const puntaje = calculateAverageOfALevel(row);  // Calcular el puntaje
                
                        // Si el puntaje es -1 (no respondido), mostrar '-'
                        const displayPuntaje = puntaje === -1 ? '-' : renderPuntaje(puntaje);  // Mostrar '-' si el valor es -1
                        
                        return (
                            <div className="text-sm font-medium text-gray-900 text-center flex justify-center items-center">
                                {displayPuntaje}  {/* Mostrar '-' si no hay respuesta, o el puntaje calculado */}
                            </div>
                        );
                    }
                }
                
            ]
            : [
                { name: 'Cazapalabra', 
                    sortable: true,
                    selector: row => {
                        const puntaje_final = row.promedioA000 || 0;
                        return Math.round(puntaje_final * 100) / 100;  // Redondear a 2 decimales sin usar toFixed
                    },
                    cell: row => {
                        // Si el puntaje es -1 (no respondido), mostrar '-'
                        const puntaje = row.promedioA000
                        const displayPuntaje = puntaje === -1 ? '-' : renderPuntaje(puntaje);  // Mostrar '-' si el valor es -1
                        
                        return (
                            <div className="text-sm font-medium text-gray-900 text-center flex justify-center items-center">
                                {displayPuntaje}  {/* Mostrar '-' si no hay respuesta, o el puntaje calculado */}
                            </div>
                        );
                    }
                },
                { name: 'Explícita', selector: row => row.promedioA001, sortable: true,
                    cell: row => {
                        // Si el puntaje es -1 (no respondido), mostrar '-'
                        const puntaje = row.promedioA001
                        const displayPuntaje = puntaje === -1 ? '-' : renderPuntaje(puntaje);  // Mostrar '-' si el valor es -1
                        
                        return (
                            <div className="text-sm font-medium text-gray-900 text-center flex justify-center items-center">
                                {displayPuntaje}  {/* Mostrar '-' si no hay respuesta, o el puntaje calculado */}
                            </div>
                        );
                    }
                },
                { name: 'Implícita', selector: row => row.promedioA002, sortable: true,
                    cell: row => {
                        return (
                            <div className="text-sm font-medium text-gray-900 text-center">
                                {renderPuntaje(row.promedioA002)}
                            </div>
                        );
                    }
                 },
                { name: 'Inferencia', selector: row => row.promedioA003, sortable: true,
                    cell: row => {
                        return (
                            <div className="text-sm font-medium text-gray-900 text-center">
                                {renderPuntaje(row.promedioA003)}
                            </div>
                        );
                    }
                 },
                 { name: 'Ordenamiento', selector: row => row.promedioA004, sortable: true,
                    cell: row => {
                        return (
                            <div className="text-sm font-medium text-gray-900 text-center">
                                {renderPuntaje(row.promedioA004)}
                            </div>
                        );
                    }
                },
                     // Add a column for the overall mean
                { name: 'Promedio General', selector: row => calculateMean(row), sortable: true,
                    cell: row => (
                        <div className="text-sm font-medium text-gray-900 text-center">
                            {renderPuntaje(calculateMean(row))}
                        </div>
                )
                },
                { name: 'Participación', selector: row => calculateCompletedLevelsForStudent(row, releasedLevelsName).completedLevelsCount, // for sorting by completed levels
                    sortable: true,
                    cell: row => {
                        const { completedLevelsCount, totalLevels } = calculateCompletedLevelsForStudent(row, releasedLevelsName);
                        return (
                            <div className="text-sm font-medium text-gray-900 text-center">
                                {`${completedLevelsCount} / ${totalLevels}`}
                                
                                <Link to={`/${teacherId}/cursos/${selectedClass}/estudiante/${row.id}`}> {/* Cambia `row.id` por el campo que contiene el ID del estudiante */}
                                <div className="tooltip" data-tip="Ver más">
                                <FontAwesomeIcon icon={faCircleChevronRight} className="text-lg ml-2 text-blue-600 hover:text-blue-800 cursor-pointer" />
                                </div>
                                </Link>
                            </div>
                        );
                    }
                },
                
                 
                 
            ]
        )
    ];

    return (
        <div className="container mx-auto">
            {/* Render ActivityTable only if selectedLevel is not 'General' and selectedActivity is defined */}
            {selectedLevel !== 'General' && selectedActivity ? (
                <>
                    {/* <h2 className="text-2xl font-bold mb-4">Unidad {selectedLevelName}</h2> */}
                    <ActivityTable data={filtered} activityId={selectedActivity} name={selectedActivityName} />
                </>
            ) : (
                // Otherwise, render the DataTable
                <DataTable
                    columns={columns}
                    data={filtered}
                    highlightOnHover
                    pagination
                    paginationPerPage={10}
                    paginationComponentOptions={paginationOptions}
                    customStyles={customStyles}
                    noDataComponent='No hay datos para mostrar'
                />
            )}
        </div>
    );
}

export default LevelTable;

