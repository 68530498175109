  import { createContext, useContext, useEffect, useState, useRef } from "react";
  import PropTypes from "prop-types";
  import { db } from "./db"; // Asegúrate de que la configuración de Firebase esté bien
  import { collection, query, where, getDocs } from 'firebase/firestore';
  import { useAuth } from "./AuthProvider";

  const ClassesContext = createContext(null);
  
  export const useClasses = () => {
    return useContext(ClassesContext);
  };

  export const ClassesProvider = ({ children }) => {
    const [classes, setClasses] = useState([]);
    const { teacherId } = useAuth(); // Obtén teacherId directamente desde el contexto de autenticación
    const [selectedClass, setSelectedClass] = useState(null);
    const [loading, setLoading] = useState(true);
    const [levels, setLevels] = useState([
      {
        id: 'B1U001',
        nombre: 'Melisa la merluza',
        letra: 'Letra M',
        texto: `Melisa es una merluza que vive en las costas de Arica.
                Le gusta comer moluscos, peces y algas.
                Su amigo Memo es muy amoroso y amable. Ema y Memo madrugan para ir a buscar a los peces más ricos.
                En tiempo de otoño, migran para poner sus huevos en aguas más cálidas.
                Ema la Merluza es muy feliz en el fondo del mar.`,
        descripcion: 'Conoce a Melisa, una merluza que disfruta de la vida marina en Arica y realiza migraciones estacionales. Su historia destaca su amistad con Memo y su feliz vida en el mar.',
        palabras_nuevas: {
          'Moluscos': 'Dicho de un Metazoo: Que tiene simetría bilateral, no siempre perfecta, tegumentos blandos y cuerpo no segmentado en los adultos, y está desnudo o revestido de una concha.',
          'Madrugan': 'Del verbo Madrugar. Levantarse al amanecer o muy temprano.',
          'Migran': 'Del verbo Migrar, Trasladarse desde el lugar en que se habita a otro diferente.'
        },
        curso: 1,
        position: 'center center',
        preguntas: {
          explicitas: [
            {
              pregunta: '¿Dónde vive Melisa?',
              opciones: [
                { respuesta: 'En las costas de Coquimbo', esCorrecta: false },
                { respuesta: 'En las costas de Atacama', esCorrecta: false },
                { respuesta: 'En las costas de Arica.', esCorrecta: true },
                { respuesta: 'En las costas de Valparaíso.', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿En qué tiempo migra la Merluza?',
              opciones: [
                { respuesta: 'En otoño', esCorrecta: true },
                { respuesta: 'En primavera', esCorrecta: false },
                { respuesta: 'En verano.', esCorrecta: false },
                { respuesta: 'En invierno.', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Que le gusta comer a Melisa?',
              opciones: [
                { respuesta: 'Moluscos peces y algas', esCorrecta: true },
                { respuesta: 'Frutas', esCorrecta: false },
                { respuesta: 'Bananas.', esCorrecta: false },
              ]
            },
            {
              pregunta: '¿Quiénes son los protagonistas de la historia?',
              opciones: [
                { respuesta: '2 merluzas', esCorrecta: true },
                { respuesta: '3 lobos marinos', esCorrecta: false },
                { respuesta: '2 parinas', esCorrecta: false },
              ]
            },
            {
              pregunta: '¿Qué hacen en tiempos de otoño?',
              opciones: [
                { respuesta: 'Comen', esCorrecta: false },
                { respuesta: 'Madrugan', esCorrecta: false },
                { respuesta: 'Migran', esCorrecta: true },
              ]
            },
          ],
          implicitas: [
            {
              pregunta: '¿Por qué hay que madrugar para comer los peces más ricos?',
              opciones: [
                { respuesta: 'Porque de noche no se ve bien.', esCorrecta: false },
                { respuesta: 'Porque todos los prefieren y se acaban rápido.', esCorrecta: true },
                { respuesta: 'Porque en la tarde están durmiendo.', esCorrecta: false },
                { respuesta: 'Porque al mediodía no aparecen peces.', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Por qué Melisa y Memo tienen que migrar a poner sus huevos?',
              opciones: [
                { respuesta: 'Porque en Arica no hay espacio para más peces', esCorrecta: false },
                { respuesta: 'Porque en otoño el agua es más fría.', esCorrecta: false },
                { respuesta: 'Porque necesitan aguas más cálidas', esCorrecta: true },
                { respuesta: 'Porque en Arica hay depredadores.', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Que hay en el fondo del mar?',
              opciones: [
                { respuesta: 'Reloj', esCorrecta: false },
                { respuesta: 'Algas, peces y moluscos', esCorrecta: true },
                { respuesta: 'Sol', esCorrecta: false },
              ]
            },
            {
              pregunta: '¿A qué hora madrugan?',
              opciones: [
                { respuesta: 'Al amanecer', esCorrecta: true },
                { respuesta: 'Al atardecer', esCorrecta: false },
                { respuesta: 'Al mediodía', esCorrecta: false },
              ]
            },
            {
              pregunta: '¿Cómo es memo?',
              opciones: [
                { respuesta: 'Amoroso', esCorrecta: true },
                { respuesta: 'Gruñón', esCorrecta: false },
                { respuesta: 'Triste', esCorrecta: false },
              ]
            },
          ],
          inferencias: [
            {
              pregunta: '¿Cómo es la alimentación de Melisa y Memo?',
              opciones: [
                { respuesta: 'Herbívora', esCorrecta: false },
                { respuesta: 'Carnívora', esCorrecta: false },
                { respuesta: 'Omnívora', esCorrecta: true },
                { respuesta: 'Insectívora', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Por qué Melisa es feliz en el fondo del mar?',
              opciones: [
                { respuesta: 'Porque tiene amigos, comida y espacio.', esCorrecta: true },
                { respuesta: 'Porque Memo es su mejor amigo.', esCorrecta: false },
                { respuesta: 'Porque en Arica hay más merluzas.', esCorrecta: false },
                { respuesta: 'Porque le gusta comer peces.', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Qué tipo de agua prefiere Melisa?',
              opciones: [
                { respuesta: 'Agua normal', esCorrecta: true },
                { respuesta: 'Agua con hielo', esCorrecta: false },
                { respuesta: 'Agua termal', esCorrecta: false },
              ]
            },
            {
              pregunta: '¿Cómo son los peces más ricos?',
              opciones: [
                { respuesta: 'Peces delgados', esCorrecta: false },
                { respuesta: 'Peces grandes', esCorrecta: true },
                { respuesta: 'Un pez delgado y uno grande', esCorrecta: false },
              ]
            },
            {
              pregunta: '¿Qué moluscos les gusta comer?',
              opciones: [
                { respuesta: 'Almeja', esCorrecta: true },
                { respuesta: 'Krill', esCorrecta: false },
                { respuesta: 'Alga', esCorrecta: false },
              ]
            },
          ]
        }
      },
      {
        id: 'B1U002',
        nombre: 'Leopoldo el lobo marino',
        letra: 'Letra L',
        descripcion: 'Leopoldo es un lobo marino chileno famoso por su piel y bigotes largos. Vive en la costa del Pacífico, es conocido por su lealtad y aprecio por los mariscos.',
        texto: `Leopoldo es un Lobo Marino chileno.
                Vive en las costas del Pacífico.
                Luce una hermosa piel, suave y brillante, y unos Largos bigotes.
                Todos lo admiran y lo aman mucho, porque es amable y Leal con sus amigos del mar.
                Leo come peces, moluscos y cuando hay, le encantan los Langostinos.
                El Lobo Marino tiene una hermosa vida en el mar chileno.`,
        palabras_nuevas: {
          'Luce': 'Del verbo lucir, llevar a la vista, exhibir lo que alguien se ha puesto, normalmente como adorno.',
          'Leal': 'Dicho de una acción: Propia de una persona fiel.',
          'Langostinos': 'Crustáceo marino de 10 patas. Tiene el cuerpo comprimido, caparazón blando y cola larga. Es de color gris y cambia a color rosa cuando está cocido.'
        },
        curso: 1,
        position: 'center center',
        preguntas: {
          explicitas: [
            {
              pregunta: '¿Cómo es la piel del lobo marino?',
              opciones: [
                { respuesta: 'Dura y escamosa.', esCorrecta: false },
                { respuesta: 'Suave y brillante.', esCorrecta: true },
                { respuesta: 'Suave y escamosa.', esCorrecta: false },
                { respuesta: 'Dura y brillante.', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Cómo se llama el Lobo Marino?',
              opciones: [
                { respuesta: 'Leonidas', esCorrecta: false },
                { respuesta: 'Leonardo', esCorrecta: false },
                { respuesta: 'Leopoldo', esCorrecta: true },
                { respuesta: 'Teodoro', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Cómo son los bigotes de Leopoldo?',
              opciones: [
                { respuesta: 'Bigotes largos', esCorrecta: true },
                { respuesta: 'Bigotes en punta', esCorrecta: false },
                { respuesta: 'Bigotes gruesos', esCorrecta: false },
              ]
            },
            {
              pregunta: '¿Cuál es la cubierta corporal de Leopoldo?',
              opciones: [
                { respuesta: 'Escamas', esCorrecta: true },
                { respuesta: 'Plumas', esCorrecta: false },
                { respuesta: 'Piel brillante', esCorrecta: false },
              ]
            },
            {
              pregunta: '¿Qué es lo que más le gusta comer?',
              opciones: [
                { respuesta: 'Langostinos', esCorrecta: true },
                { respuesta: 'Cerezas', esCorrecta: false },
                { respuesta: 'Pescados', esCorrecta: false },
              ]
            },
          ],
          implicitas: [
            {
              pregunta: '¿En qué costas vive Leopoldo?',
              opciones: [
                { respuesta: 'De Arica a Magallanes', esCorrecta: true },
                { respuesta: 'En las costas de Valparaíso', esCorrecta: false },
                { respuesta: 'En las costas de Brasil', esCorrecta: false },
                { respuesta: 'En las costas del Atlántico', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Por qué se dice que su piel es brillante?',
              opciones: [
                { respuesta: 'Porque tiene piedras preciosas', esCorrecta: false },
                { respuesta: 'Porque brilla con el sol', esCorrecta: true },
                { respuesta: 'Porque es lisa como seda', esCorrecta: false },
                { respuesta: 'Porque está mojada', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Cómo es Leopoldo?',
              opciones: [
                { respuesta: 'Amoroso', esCorrecta: true },
                { respuesta: 'Gruñón', esCorrecta: false },
                { respuesta: 'Triste', esCorrecta: false },
              ]
            },
            {
              pregunta: '¿Cómo prefiere pasar sus días Leopoldo?',
              opciones: [
                { respuesta: 'Solo', esCorrecta: false },
                { respuesta: 'Con sus amigos', esCorrecta: true },
                { respuesta: 'Discutiendo con otros animales', esCorrecta: false },
              ]
            },
            {
              pregunta: '¿Cuáles son los amigos de Leopoldo?',
              opciones: [
                { respuesta: 'Degus', esCorrecta: false },
                { respuesta: 'Lobos Marinos', esCorrecta: true },
                { respuesta: 'Loros', esCorrecta: false },
              ]
            },
          ],
          inferencias: [
            {
              pregunta: '¿Por qué sus amigos dicen que es LEAL?',
              opciones: [
                { respuesta: 'Porque siempre los defiende', esCorrecta: true },
                { respuesta: 'Porque siempre juega con ellos', esCorrecta: false },
                { respuesta: 'Porque siempre dice la verdad', esCorrecta: false },
                { respuesta: 'Porque es muy entretenido.', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Cuál es la comida favorita de Leopoldo?',
              opciones: [
                { respuesta: 'Moluscos', esCorrecta: false },
                { respuesta: 'Langostinos', esCorrecta: false },
                { respuesta: 'Peces', esCorrecta: true },
                { respuesta: 'Algas', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿En qué región podemos encontrar a Leopoldo?',
              opciones: [
                { respuesta: 'Atacama', esCorrecta: false },
                { respuesta: 'Maule', esCorrecta: false },
                { respuesta: 'A lo largo de chile', esCorrecta: true },
              ]
            },
            {
              pregunta: '¿Qué hay en el plato de Leopoldo?',
              opciones: [
                { respuesta: 'Langostinos', esCorrecta: true },
                { respuesta: 'Algas', esCorrecta: false },
                { respuesta: 'Sopa', esCorrecta: false },
              ]
            },
            {
              pregunta: '¿Cómo reaccionaría Leopoldo?',
              opciones: [
                { respuesta: 'Defendería a sus amigos', esCorrecta: true },
                { respuesta: 'Acusaría a sus amigos', esCorrecta: false },
                { respuesta: 'Saldría huyendo', esCorrecta: false },
              ]
            },
          ]
        }
      },
      {
        id: 'B1U003',
        nombre: 'Paulina la parina',
        letra: 'Letra P',
        descripcion: 'Las parinas son aves que habitan en la Zona Norte de Chile. Su plumaje es distintivo, se alimentan de plancton y están en peligro de extinción.',
        texto: `Las parinas son pájaros de la Zona Norte del país.
                Su plumaje es blanco y rosa.
                Su pico es amarillo y negro, y les sirve para alimentarse del plancton del agua.
                Están en peligro de extinción y por eso ha bajado su población.`,
        palabras_nuevas: {
          'Plumaje': 'Conjunto de plumas que adornan y visten al ave.',
          'Plancton': 'Conjunto de organismos animales y vegetales, generalmente diminutos, que flotan y son desplazados pasivamente en aguas saladas o dulces.',
          'Población': 'Conjunto de individuos de la misma especie que ocupan determinada área geográfica.'
        },
        curso: 1,
        position: 'center top',
        preguntas: {
          explicitas: [
            {
              pregunta: '¿Qué tipo de animal son las parinas?',
              opciones: [
                { respuesta: 'Reptiles', esCorrecta: false },
                { respuesta: 'Peces', esCorrecta: false },
                { respuesta: 'Aves', esCorrecta: true },
                { respuesta: 'Mamíferos', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Por qué ha bajado su población?',
              opciones: [
                { respuesta: 'Porque están en peligro de extinción', esCorrecta: true },
                { respuesta: 'Porque se están muriendo de a poco', esCorrecta: false },
                { respuesta: 'Porque han migrado a otras zonas', esCorrecta: false },
                { respuesta: 'Porque no nacen más parinas', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿De qué color son las plumas de Paulina?',
              opciones: [
                { respuesta: 'Rosada', esCorrecta: true },
                { respuesta: 'Negro', esCorrecta: false },
                { respuesta: 'Verde', esCorrecta: false },
              ]
            },
            {
              pregunta: '¿Qué parte de su cuerpo usan para alimentarse?',
              opciones: [
                { respuesta: 'Pico', esCorrecta: true },
                { respuesta: 'Boca', esCorrecta: false },
                { respuesta: 'Pata', esCorrecta: false },
              ]
            },
            {
              pregunta: '¿Cómo ha cambiado su población?',
              opciones: [
                { respuesta: 'No quedan', esCorrecta: false },
                { respuesta: 'Hay algunas parinas', esCorrecta: true },
                { respuesta: 'Hay muchas parinas', esCorrecta: false },
              ]
            },
          ],
          implicitas: [
            {
              pregunta: '¿En qué Región podría encontrar una Parina?',
              opciones: [
                { respuesta: 'Valparaíso', esCorrecta: false },
                { respuesta: 'Santiago', esCorrecta: false },
                { respuesta: 'Arica', esCorrecta: true },
                { respuesta: 'Valdivia', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Cuál es el hábitat de las parinas?',
              opciones: [
                { respuesta: 'Acuático', esCorrecta: false },
                { respuesta: 'Aéreo', esCorrecta: false },
                { respuesta: 'Terrestre', esCorrecta: false },
                { respuesta: 'Acuático y terrestre', esCorrecta: true }
              ]
            },
            {
              pregunta: '¿Qué cubierta corporal tiene Paulina?',
              opciones: [
                { respuesta: 'Plumas', esCorrecta: true },
                { respuesta: 'Piel brillante', esCorrecta: false },
                { respuesta: 'Escamas Merluza', esCorrecta: false },
              ]
            },
            {
              pregunta: '¿Cómo se traslada Paulina?',
              opciones: [
                { respuesta: 'Nadando', esCorrecta: true },
                { respuesta: 'Saltando', esCorrecta: false },
                { respuesta: 'Volando', esCorrecta: false },
              ]
            },
            {
              pregunta: '¿Qué otro animal también es un ave?',
              opciones: [
                { respuesta: 'Ave', esCorrecta: true },
                { respuesta: 'Mamifero', esCorrecta: false },
                { respuesta: 'Reptil', esCorrecta: false },
              ]
            },
          ],
          inferencias: [
            {
              pregunta: '¿Por qué están en peligro de extinción las parinas?',
              opciones: [
                { respuesta: 'Porque han destruido sus hábitats', esCorrecta: true },
                { respuesta: 'Porque las han cazado', esCorrecta: false },
                { respuesta: 'Porque han migrado a otras zonas', esCorrecta: false },
                { respuesta: 'Porque han peleado con otras aves.', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Qué habrá en el agua que le gusta comer?',
              opciones: [
                { respuesta: 'Pequeños peces', esCorrecta: false },
                { respuesta: 'Algas', esCorrecta: true },
                { respuesta: 'Insectos', esCorrecta: false },
                { respuesta: 'Animales pequeños', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Cómo se ha dañado el hábitat de las parinas?',
              opciones: [
                { respuesta: 'Personas que dañan la tierra (Camión, Mineras)', esCorrecta: true },
                { respuesta: 'Incendio (Modelos de Fuegos)', esCorrecta: false },
                { respuesta: 'La falta de árboles (Modelo de Tala)', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Cómo es el clima en su hábitat?',
              opciones: [
                { respuesta: 'Clima cálido y soleado (Soleado)', esCorrecta: true },
                { respuesta: 'Clima frío (Agua de Hielo)', esCorrecta: false },
                { respuesta: 'Clima húmedo y con vegetación (Bosque)', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Por qué las parinas tienen el cuello tan largo?',
              opciones: [
                { respuesta: 'Para alcanzar mejor la comida.', esCorrecta: true },
                { respuesta: 'Para volar más rápido (Volando)', esCorrecta: false },
                { respuesta: 'Para llamar la atención de otras aves (otros pájaros)', esCorrecta: false }
              ]
            },
          ]
        }
      },
      {
        id: 'B1U004',
        nombre: 'Gaspar el sapito',
        letra: 'Letra S',
        descripcion: 'Gaspar es un sapo chileno que se destaca por su capacidad para solfear y ayudar a sus amigos a aprender a cantar en Atacama.',
        texto: `El sapo de cuatro ojos se llama Gaspar.
                Es un anfibio chileno que le encanta solfear.
                Si eres buen sabueso, en Atacama lo podrás encontrar.
                Socorriendo a sus amigos, para que aprendan a cantar.`,
        palabras_nuevas: {
          'Solfear': 'Cantar algo marcando el compás y pronunciando los nombres de las notas.',
          'Sabueso': 'Persona que sabe indagar, descubrir o averiguar los hechos.',
          'Socorriendo': 'Del verbo socorrer. Ayudar, favorecer en un peligro o necesidad.'
        },
        curso: 1,
        position: 'center center',
        preguntas: {
          explicitas: [
            {
              pregunta: '¿Qué hace Gaspar con sus amigos?',
              opciones: [
                { respuesta: 'Juegan a la escondida', esCorrecta: false },
                { respuesta: 'Comen insectos', esCorrecta: false },
                { respuesta: 'Saltan charcos', esCorrecta: false },
                { respuesta: 'Les enseña a cantar', esCorrecta: true }
              ]
            },
            {
              pregunta: '¿Cómo se llama el Sapito?',
              opciones: [
                { respuesta: 'Baltazar', esCorrecta: false },
                { respuesta: 'Gaspar', esCorrecta: true },
                { respuesta: 'Melchor', esCorrecta: false },
                { respuesta: 'Isaac', esCorrecta: false }
              ]
            },
          ],
          implicitas: [
            {
              pregunta: '¿Dónde podemos encontrar a Gaspar?',
              opciones: [
                { respuesta: 'En Pucón', esCorrecta: false },
                { respuesta: 'En Santiago', esCorrecta: false },
                { respuesta: 'En Copiapó', esCorrecta: true },
                { respuesta: 'En Concepción', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Qué le gusta hacer a Gaspar?',
              opciones: [
                { respuesta: 'Ayudar a sus amigos', esCorrecta: false },
                { respuesta: 'Cantar', esCorrecta: false },
                { respuesta: 'Saltar', esCorrecta: true },
                { respuesta: 'Bailar', esCorrecta: false }
              ]
            },
          ],
          inferencias: [
            {
              pregunta: '¿Cómo será la piel de Gaspar?',
              opciones: [
                { respuesta: 'Peluda y suave', esCorrecta: false },
                { respuesta: 'Fría y desnuda', esCorrecta: true },
                { respuesta: 'Fría y escamosa', esCorrecta: false },
                { respuesta: 'Desnuda y suave', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Dónde puedo encontrar solfeando a Gaspar?',
              opciones: [
                { respuesta: 'En la piscina', esCorrecta: false },
                { respuesta: 'En el parque', esCorrecta: false },
                { respuesta: 'En una sala de música', esCorrecta: true },
                { respuesta: 'En la playa', esCorrecta: false }
              ]
            },
          ]
        }
      },
      {
        id: 'B1U005',
        nombre: 'Lorenzo el Loro Tricahue',
        letra: 'Letra T',
        descripcion: 'Conoce a el loro tricahue, el más grande de Chile. Son aves sociales que viven en grupos y se alimentan de frutas y flores de los árboles.',
        texto: `Es el loro mas grande de Chile
                Su cabeza, cuello y lomo tienen una tonalidad verde Olivia.
                Su pecho es blanquecino, su pecho, es amarillo y naranjo al centro.
                Son aves sociales y bulliciosos, viven al menor, en trios, forman bandadas.
                Se alimentan de frutas y flores que sacan de los árboles.
                Un dato curioso: son tragadores, tragan pequeñas piedrecillas que ayudan en su proceso de digestión.
                Los loros tricahue están en serio peligro de extinción.`,
        palabras_nuevas: {
          'Tonalidad': 'Sistema de colores y tonos.',
          'Trío': 'Conjunto de tres personas, animales o cosas de características semejantes o con una función común.',
          'Tragadores': 'Del verbo tragar, que traga. Que come vorazmente.'
        },
        curso: 1,
        position: 'center center',
        preguntas: {
          explicitas: [
            {
              pregunta: '¿De qué se alimentan los Loros Tricahue?',
              opciones: [
                { respuesta: 'De semillas', esCorrecta: false },
                { respuesta: 'De insectos', esCorrecta: false },
                { respuesta: 'De frutas y flores que sacan de los árboles', esCorrecta: true },
                { respuesta: 'De plantas', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Cuáles son sus colores?',
              opciones: [
                { respuesta: 'Azul y blanco', esCorrecta: false },
                { respuesta: 'Verde oliva', esCorrecta: true },
                { respuesta: 'Verde limón', esCorrecta: false },
                { respuesta: 'Azul y verde', esCorrecta: false }
              ]
            },
          ],
          implicitas: [
            {
              pregunta: '¿Qué significa que están en serio peligro de extinción?',
              opciones: [
                { respuesta: 'Que ya no quedan Loros', esCorrecta: false },
                { respuesta: 'Que hay muchos Loros', esCorrecta: false },
                { respuesta: 'Que están a punto de extinguirse', esCorrecta: true },
                { respuesta: 'Que está lleno de Loros', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿De cuánto será una bandada de Loros?',
              opciones: [
                { respuesta: 'Al menos de un trío de loros', esCorrecta: true },
                { respuesta: 'De 2 loros', esCorrecta: false },
                { respuesta: 'De muchos loros', esCorrecta: false },
                { respuesta: 'Imposible de saber', esCorrecta: false }
              ]
            },
          ],
          inferencias: [
            {
              pregunta: '¿En qué estación del año encuentran frutas y flores para comer?',
              opciones: [
                { respuesta: 'Invierno', esCorrecta: false },
                { respuesta: 'Otoño y primavera', esCorrecta: false },
                { respuesta: 'Primavera y verano', esCorrecta: true },
                { respuesta: 'Verano', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Por qué serán tan coloridos los Loros Tricahue?',
              opciones: [
                { respuesta: 'Para encontrar pareja', esCorrecta: true },
                { respuesta: 'Para camuflarse', esCorrecta: true },
                { respuesta: 'Para admirar su belleza', esCorrecta: false },
                { respuesta: 'Para verlos fácilmente en el cielo', esCorrecta: false }
              ]
            },
          ]
        }
      },
      {
        id: 'B1U006',
        nombre: 'Nicolás el pato negro',
        letra: 'Letra N',
        descripcion: 'Pato Negro escribe a su amiga Parina, quien está enferma con neumonía, ofreciendo consejos y apoyo para su recuperación.',
        texto: `Querida amiga Parina:\n
                Me han contado que estás con Neumonía. ¡Pobrecita!\n
                Espero que estés mejor, también me dio una vez, incluso me dieron hasta náuseas.\n
                Mi abuelita me dijo que te hicieras una infusión con manzanilla, eucalipto y menta.\n
                No te niegues a ir al médico, a veces lo mejor es una buena inyección.\n
                Avísame cómo sigues.\n
                Te quiere mucho, Tu amigo, Pato Negro.`,
        palabras_nuevas: {
          'Neumonía': 'Infección respiratoria aguda que afecta a los pulmones',
          'Náuseas': 'Ganas de vomitar.',
          'Niegues': 'Del verbo Negar, Decir que no a lo que se pretende o se pide, o no concederlo.'
        },
        curso: 1,
        position: 'center center',
        preguntas: {
          explicitas: [
            {
              pregunta: '¿Qué enfermedad le dio a la Parina?',
              opciones: [
                { respuesta: 'Gastritis', esCorrecta: false },
                { respuesta: 'Resfrío', esCorrecta: false },
                { respuesta: 'Neumonía', esCorrecta: true },
                { respuesta: 'Apendicitis', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Qué contiene la infusión que le recomendaron?',
              opciones: [
                { respuesta: 'Boldo', esCorrecta: false },
                { respuesta: 'Manzanilla', esCorrecta: true },
                { respuesta: 'Cedrón', esCorrecta: false },
                { respuesta: 'Canela', esCorrecta: false }
              ]
            },
          ],
          implicitas: [
            {
              pregunta: '¿Quién escribe esta carta?',
              opciones: [
                { respuesta: 'La Parina', esCorrecta: false },
                { respuesta: 'La abuelita', esCorrecta: false },
                { respuesta: 'El Pato Negro', esCorrecta: true },
                { respuesta: 'El doctor', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Por qué la Parina no querría ir al doctor?',
              opciones: [
                { respuesta: 'Porque le queda muy lejos', esCorrecta: false },
                { respuesta: 'Porque le da miedo la inyección', esCorrecta: true },
                { respuesta: 'Porque no lo necesita', esCorrecta: false },
                { respuesta: 'Porque ya se mejoró', esCorrecta: false }
              ]
            },
          ],
          inferencias: [
            {
              pregunta: '¿Cómo se habrá enterado el Pato que la Parina estaba enferma?',
              opciones: [
                { respuesta: 'Se la encontró y estaba enferma', esCorrecta: false },
                { respuesta: 'Porque la Parina lo llamó para contarle', esCorrecta: false },
                { respuesta: 'Los otros animalitos le dijeron', esCorrecta: true },
                { respuesta: 'Lo adivinó', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Por qué el Pato le pidió consejo a la abuelita?',
              opciones: [
                { respuesta: 'Porque él ya había tenido Neumonía antes', esCorrecta: false },
                { respuesta: 'Porque la abuelita tiene más experiencia en hierbas', esCorrecta: true },
                { respuesta: 'Porque no había nadie más', esCorrecta: false },
                { respuesta: 'Porque la abuelita lo escuchó hablar de la Parina', esCorrecta: false }
              ]
            },
          ],
        }
      },
      {
        id: 'B1U007',
        nombre: 'El maravilloso y desconocido mundo de delia la degú',
        letra: 'Letra D',
        descripcion: 'El degú es un roedor chileno que vive en los alrededores de Santiago. Es diurno y tiene una vida social compleja.',
        texto: `El maravilloso y desconocido mundo del Degú.
                A pesar de que parece una rata, el degú es un roedor de cola apincelada que vive en los alrededores de Santiago, entre los árboles espinosos.
                A diferencia de otros roedores, es diurno, y es capaz de ver la luz ultravioleta.
                Su nombre proviene del mapudungun y significa "ratones de campo".
                El degú tiene una interesante vida social, que suele desarrollarse entre hogares subterráneos, arbustos y sitios de escasa humedad.
                Hoy en día ha disminuido la población de degú en Chile, debido al uso de suelo y urbanización.`,
        palabras_nuevas: {
          'Diurno': 'Dicho de un animal: Que busca el alimento durante el día.',
          'Diversos': 'De distinta naturaleza, especie, número, forma, etc.',
          'Disminuido': 'Del verbo Disminuir. Hacer menor la extensión, la intensidad o el número de algo.'
        },
        curso: 1,
        position: 'center center',
        preguntas: {
          explicitas: [
            {
              pregunta: '¿Qué tipo de animal es el Degú?',
              opciones: [
                { respuesta: 'Un roedor', esCorrecta: true },
                { respuesta: 'Un ave', esCorrecta: false },
                { respuesta: 'Un conejo', esCorrecta: false },
                { respuesta: 'Un gato', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Qué significa su nombre en Mapudungun?',
              opciones: [
                { respuesta: 'Conejo de campo', esCorrecta: false },
                { respuesta: 'Ratón de montaña', esCorrecta: false },
                { respuesta: 'Ratones de campo', esCorrecta: true },
                { respuesta: 'Conejo blanco', esCorrecta: false }
              ]
            },
          ],
          implicitas: [
            {
              pregunta: '¿Qué significa que sea un roedor?',
              opciones: [
                { respuesta: 'Que tiene cola larga', esCorrecta: false },
                { respuesta: 'Que tiene dientes largos y los gasta constantemente', esCorrecta: true },
                { respuesta: 'Que come vegetales', esCorrecta: false },
                { respuesta: 'Que es un ratón', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Dónde podría encontrar un Degú?',
              opciones: [
                { respuesta: 'En Valdivia', esCorrecta: false },
                { respuesta: 'En La Serena', esCorrecta: false },
                { respuesta: 'En Melipilla', esCorrecta: true },
                { respuesta: 'En Coquimbo', esCorrecta: false }
              ]
            },
          ],
          inferencias: [
            {
              pregunta: '¿Qué pasaría si un Degú se pierde en la noche?',
              opciones: [
                { respuesta: 'No podría ver nada.', esCorrecta: true },
                { respuesta: 'Vería mucho mejor de noche.', esCorrecta: false },
                { respuesta: 'Daría lo mismo.', esCorrecta: false },
                { respuesta: 'Se encandila con las luces.', esCorrecta: false }
              ]
            },
            {
              pregunta: '¿Cómo podrían volver los Degú a la Región Metropolitana?',
              opciones: [
                { respuesta: 'Trayendo muchas familias de Degú a Santiago', esCorrecta: false },
                { respuesta: 'Dejando de construir cerca de la cordillera', esCorrecta: true },
                { respuesta: 'Plantando más árboles', esCorrecta: false },
                { respuesta: 'Haciendo más vertederos', esCorrecta: false }
              ]
            },
          ],
        }
      },
      {
        id: 'CLU1',
        nombre: 'Clasificación de palabras',
        letra: 'Extra',
        descripcion: 'Actividades extra donde se debe elegir la inicial correcta de ciertas palabras.',
        texto: 'Esta actividad extra consiste en presentar a los estudiantes palabras con la primera letra faltante, desafiándolos a identificar la letra correcta a partir de dos opciones proporcionadas. Los estudiantes deben seleccionar la opción correcta para completar la palabra de manera adecuada.',
        "grupos": [
    {
      "titulo": "M-L",
      "palabras": [
        "Mano",
        "Mesa",
        "Mapa",
        "Lupa",
        "Lima",
        "Lona"
      ]
    },
    {
      "titulo": "S-P",
      "palabras": [
        "Paseo",
        "Piso",
        "Sol",
        "Sapo",
        "Saltar",
        "Pesa"
      ]
    },
    {
      "titulo": "T-N",
      "palabras": [
        "Tomate",
        "Tapa",
        "Tela",
        "Niño",
        "Nudo",
        "Nadar",
        "Tetera"
      ]
    }
  ],
        curso: 1,
        position: 'center center',
      },
      {
        id: 'B2U001',
        letra: 'Grupo Fr Fl',
        nombre: 'Lana la lagartija del azufre',
        descripcion: 'Este texto describe un reptil chileno que habita en la Cordillera de la Región del Maule. Se destaca su apariencia, con un flanco amarillento y blanco, y su piel fragmentada en verdes y grises.',
        texto: `¿Quién es este amiguito?
                Es un reptil chileno que habita en la Cordillera de la Región del Maule.
                Recibe el nombre “del azufre”, porque se puede encontrar en las áreas cercanas a los volcanes, rodeadas de aguas termales sulfurosas. 
                Es una lagartija de tamaño medio, con un flanco amarillento y blanco, con líneas muy definidas. También tiene una cola larga y gruesa. Y el color de su piel en la parte de la columna puede verse fragmentada, en colores verdes y grises.
                Su hábitat es terrestre, pero prefiere los afloramientos rocosos para tomar sol y ganar temperatura. 
                Se alimenta de insectos y plantas.
                Está fuera de peligro de extinción.`,
        palabras_nuevas: {
          'Flanco': 'Cada una de las dos partes laterales de un cuerpo considerado de frente.',
          'Fragmentada': 'Que está incompleto o constituye un fragmento.',
          'Afloramientos': 'Acción y efecto de aflorar.'
        },
        curso: 2,
        position: 'bottom center'
      },
      {
        id: 'B2U002',
        nombre: 'Puar el pudú',
        descripcion: 'Este texto presenta al Pudú, un ciervo chileno que vive en la zona precordillerana de los bosques del Maule hasta la región de Aysén. Se describe su anatomía y su dieta que incluye flores y frutos. Su caza está prohibida, y se esconde en las profundidades del bosque para protegerse.',
        texto: `Hola a todos! Me presento, yo soy el Pudú. 
                Soy una especie de ciervo típico chileno y te invito a saber más de mí:

                Vivo en la zona precordillerana de los bosques del Maule y puedes encontrarme hasta en la región de Aysén.
                Tengo cornamenta como todos los ciervos del mundo, aunque no tengo dientes delanteros (raro, ¿no?) 
                Pero sí que tengo tres premolares y dos molares, con los que puedo comer deliciosas flores y frutos, aunque mis favoritos son el maqui y el chilco.
                Soy un poco tímido y huidizo, es que soy presa fácil de los perros y los pumas. 
                Por eso hoy en día, mi caza está prohibida, y para protegerme, me escondo en las planicies y profundidades del bosque chileno.

                Hasta la próxima, amiguitos! Espero que hayan aprendido mucho de mí hoy. `,
        palabras_nuevas: {
          'Premolares': 'Que en la dentición del mamífero adulto está situado junto a un canino y cuya raíz es más sencilla que la de las otras muelas. ',
          'Prohibida': 'De prohibir, impedir el uso o la ejecución de algo',
          'Planicies': 'Terreno llano, especialmente de gran extensión.',
        },
        curso: 2,
        position: 'center center',
        letra: 'Grupo Pr Pl'
      },
      {
        id: 'B2U003',
        nombre: 'Rodrigo el rorcual astral',
        descripcion: 'Este texto es una carta dirigida al Rorcual Austral, expresando la preocupación por la amenaza que enfrenta su especie. Se mencionan factores como la contaminación del mar, el ruido de los transportes marítimos y la sobrepesca que afectan su hábitat.',
        texto: `Su señoría, Rorcual Austral:

                Esperamos que esté muy cómodo en su hermoso Trono marino. Somos un grupo de animales del Pacífico que estamos muy preocupados por usted.
                Hemos escuchado una serie de noticias que dice que su especie se está viendo amenazada principalmente por los medios de transporte marítimo, ya que eso provoca la contaminación del mar, además del alto ruido que emiten (que sabemos que le molesta muchísimo), sobrepesca, actividad militar y turística, en fin. Toda una invasión a su hermoso hábitat. 
                Pero lo que más nos preocupa y, la razón por la que escribimos, es que hemos oído que la semana pasada Su Señoría chocó con un transatlántico que se atrevió a interponerse en su camino.
                Esperamos, de todo corazón, su pronta recuperación, y que haya sido un golpe leve.
                Le mandamos cariños,

                Comisión Marina del Pacífico. `,
        palabras_nuevas: {
          'Trono': 'Asiento que usan los reyes y otras personas de alta dignidad, especialmente en los actos de ceremonia.',
          'Transporte': 'Sistema de medios para conducir personas y cosas de un lugar a otro.',
          'Transatlántico': 'Que se encuentra situado al otro lado del Atlántico.',
        },
        curso: 2,
        position: 'center center',
        letra: 'Grupo Tr Tl'
      },
      {
        id: 'B2U004',
        nombre: 'Gru el gruñidor del Sur',
        descripcion: 'Este texto relata la liberación de un Gruñidor del Sur encontrado en una caja de plátanos en una feria libre. Fue llevado a una clínica veterinaria para ser examinado y alimentado antes de ser devuelto a los bosques de la Araucanía, donde mostró su gratitud gruñendo a sus salvadores.',
        texto: `Autoridades de la Araucanía liberaron a un Gruñidor del Sur 

                Dentro de una caja de plátanos, en una feria libre encontraron a un Gruñidor del Sur.
                Los inspectores del Servicio Agrícola, lo pudieron identificar fácilmente porque tenía un color grisáceo y café rojizo en todo su cuerpo, pero alrededor de su cuello tenía una particular banda negra, lo que lo hace inconfundible.
                Además, al atraparlo, emitió un fuerte gruñido, con la boca abierta, que es signo de que se siente amenazado y quiere huir.
                Las autoridades envolvieron su grueso cuerpo para llevarlo a una clínica veterinaria cercana: donde le tomaron los signos vitales, la glicemia, además comprobaron su peso y medida.
                Ahí fue alimentado con insectos y lagartijas pequeñas, para luego devolverlo a su lugar de origen.
                Luego de que fue estabilizado, lo devolvieron finalmente a los bosques de la Araucanía, donde nuevamente le gruñó a sus salvadores. `,
        palabras_nuevas: {
          'Grisáceo': 'Que algo tiene un color parecido al gris, pero no es completamente gris.',
          'Gruñido': 'Voz ronca del perro u otros animales cuando amenazan.',
          'Glicemia': 'Tipo de azúcar que se encuentra en la sangre.',
        },
        curso: 2,
        position: 'center center',
        letra: 'Grupo Gr Gl'
      },
      {
        id: 'B2U005',
        nombre: 'Alberto el albatros oscuro de manto claro',
        descripcion: 'En este texto se presenta al Albatros de manto claro, un ave de gran tamaño que se caracteriza por su pecho y dorso más claros. Se describe su dieta y su reproducción.',
        texto: `¿Habías escuchado hablar del Albatros de manto claro?  ¡Yo tampoco! Leamos lo que investigué:

                Primero, un albatro es un ave de gran tamaño. Este en particular es un pájaro carnero negro, que se distingue de los otros porque tiene el pecho y el dorso más claro.
                Se alimenta principalmente de krill, calamares y distintos crustáceos, aunque a veces carroña y grasa de mamíferos que queda flotando. 
                Los Albatros ponen solamente dos crías al año, y el huevo es empollado por el macho y la hembra en zonas altas y rocosas. Y al nacer el polluelo, se mantienen aislados para criarlo en soledad. 
                El huevo eclosiona normalmente en diciembre o enero, y empieza a volar a los 141 días de vida.
                Actualmente, el Albatros está clasificado como una especie Casi Amenazada por la captura accidental de las pesqueras.


                ¡Qué interesante este amiguito!`,
        palabras_nuevas: {
          'Crustáceos': 'Del grupo de los artrópodos, con un número variable de apéndices, dos pares de antenas, cuerpo cubierto por un caparazón, generalmente calcificado, y respiración branquial.',
          'Eclosiona': 'Dicho del capullo de una flor: abrirse.',
          'Clasificado': 'Ordenado, separado por clases',
        },
        curso: 2,
        position: 'center center',
        letra: 'Grupo Cr Cl'
      },

    ]);
    
      localStorage.setItem('levels', JSON.stringify(levels)); // Store in localStorage
  

    // Cargar selectedClass desde localStorage al iniciar
    useEffect(() => {
        const storedSelectedClass = localStorage.getItem('selectedClass');
        if (storedSelectedClass) {
        setSelectedClass(storedSelectedClass);
        }
    }, []); // Solo se ejecuta una vez al montar

    const levelsRef = useRef(levels);

    useEffect(() => {
        const storedLevels = localStorage.getItem('levels');
        if (storedLevels) {
            setLevels(JSON.parse(storedLevels)); // Cargar desde localStorage
        } else {
            localStorage.setItem('levels', JSON.stringify(levelsRef.current)); // Guardar en localStorage si no existe
        }
        
    }, []); // Se ejecuta solo una vez al montar el componente   
  

    // Aquí es donde llamas a fetchClasses
    useEffect(() => {
        fetchClasses();
    }, [teacherId]); // Llama a fetchClasses cada vez que teacherId cambia

    const fetchClasses = async () => {
      if (!teacherId) return;
      setLoading(true);
      try {
        const q = query(collection(db, "cursos"), where("profesor_id", "==", teacherId));
        const querySnapshot = await getDocs(q);
        const fetchedClasses = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClasses(fetchedClasses);
      } catch (error) {
        console.error("Error fetching classes:", error);
      } finally {
        setLoading(false);
      }
    };
  
    const updateSelectedClass = (classItemId) => {
      setSelectedClass(classItemId);
      localStorage.setItem('selectedClass', classItemId);
    };
  
    const addClass = (newClass) => {
      setClasses(prevClasses => [...prevClasses, newClass]);
    };
  
    const updateClass = (updatedClass) => {
      setClasses(prevClasses =>
        prevClasses.map(classItem =>
          classItem.id === updatedClass.id ? updatedClass : classItem
        )
      );
    };
  
    const deleteClass = (classId) => {
      setClasses(prevClasses =>
        prevClasses.filter(classItem => classItem.id !== classId)
      );
    };
  
    const classesValue = {
      classes,
      selectedClass,
      loading,
      levels, // Asegúrate de exportar levels aquí si es necesario
      fetchClasses,
      updateSelectedClass,
      addClass,
      updateClass,
      deleteClass,
    };
  
    return (
      <ClassesContext.Provider value={classesValue}>
        {children}
      </ClassesContext.Provider>
    );
  };
  
  ClassesProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };
  
  
