import React from 'react';
import DataTable from 'react-data-table-component';
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';

const customStyles = {
    rows: {
        style: {
            backgroundColor: '#f9f9f9', // Color de fondo por defecto
        },
    },
    headCells: {
        style: {
            backgroundColor: 'rgba(8, 83, 184,1)', // Color de fondo de los encabezados
            color: '#fcfcfc', // Color de texto de los encabezados
            fontSize: '12px',
            border: '1px solid rgba(28,71,137,0.3)', // Borde superior con menos opacidad
                       // Para permitir el ajuste en varias líneas
                       whiteSpace: 'normal', // Ajustar el texto en múltiples líneas
                       wordBreak: 'break-word', // Corta palabras largas si es necesario

        },
    },
    cells: {
        style: {
            width: 'auto', // Dejar que el ancho se ajuste automáticamente
           // Para permitir el ajuste en varias líneas
           whiteSpace: 'normal', // Ajustar el texto en múltiples líneas
           wordBreak: 'break-word', // Corta palabras largas si es necesario
        },
    },
};

const ActivityTable = ({ data, activityId, name }) => {
    console.log(data);
    // Function to calculate time in minutes
    const calcularTiempoTranscurrido = (inicio, fin) => {
        if (!inicio || !fin) return '-'; // Return dash if either value is missing
        const date1 = inicio.toDate();
        const date2 = fin.toDate();

        // Calculate the difference in milliseconds
        const diffInMilliseconds = date2 - date1;

        // Convert milliseconds to minutes as a float
        const diffInMinutes = diffInMilliseconds / (1000 * 60);

        // If the time difference is less than 1 minute, show the decimal value
        return diffInMinutes.toFixed(2); // Return float rounded to 2 decimal places
        };

    // Function to get date and time in Spanish
    const obtenerFechaYHora = (date) => {
        return date ? format(date, "dd/MM/yyyy HH:mm", { locale: esLocale }) : '-'; // Return dash if date is missing
    };

    // Function to calculate average score
    const calcularPromedioPuntaje = (data) => {
        if (data.length === 0) return '0.00'; // Return '0.00' if data is empty
        const totalPuntaje = data.reduce((acc, curr) => acc + (curr.actividades[activityId]?.Puntaje || 0), 0);
        return (totalPuntaje / data.length).toFixed(2);
    };

    // Function to calculate average time
    const calcularPromedioTiempo = (data) => {
        if (data.length === 0) return '0.00'; // Return '0.00' if data is empty

        let totalTiempo = 0;
        let totalResponses = 0;

        data.forEach((student) => {
            if (student.actividades && student.actividades[activityId]) {
                const actividad = student.actividades[activityId];
                const tiempo = calcularTiempoTranscurrido(actividad.FechaInicio, actividad.FechaFin);
                if (tiempo !== '-') {
                    totalTiempo += parseFloat(tiempo);
                    totalResponses += 1;
                }
            }
        });

        return totalResponses > 0 ? (totalTiempo / totalResponses).toFixed(2) : '0.00';
    };

    // Función para renderizar el puntaje con colores y estilo
    const renderPuntaje = (puntaje) => {
        if (puntaje === undefined || puntaje === null) {
            puntaje = '-'; // Handle undefined or null values
        } else {
            // Convert puntaje to a number and format it to 2 decimal places
            const numericPuntaje = parseFloat(puntaje);
            if (!isNaN(numericPuntaje)) {
                puntaje = numericPuntaje.toFixed(2); // Format to 2 decimal places
            }
        }
    
        const color = obtenerColorPuntaje(puntaje);
        return (
            <div style={{ backgroundColor: color, borderRadius: '12px', padding: '4px 8px', display: 'inline-block', minWidth: '60px', textAlign: 'center' }}>
                {puntaje}
            </div>
        );
    };

// Función para calcular el color basado en el puntaje 
const obtenerColorPuntaje = (puntaje) => {
    if (puntaje === '-') {
        return `rgba(128, 128, 128, 0.1)`;
    }

    const percentage = puntaje / 100;

    if (percentage <= 0.33) {
        // Rojo para puntajes entre 0 y 33
        return `#ef4444`; // Rojo
    } else if (percentage <= 0.66) {
        // Amarillo para puntajes entre 34 y 66
        return `#fcd34d`; // Amarillo
    } else {
        // Verde para puntajes entre 67 y 100
        return `#a3e635`; // Verde
    }
};

// Expanded row component
const ExpandedComponent = ({ data }) => {
    const selectedActivityData = data.actividades[activityId];

    return (
        <div>
            {selectedActivityData ? (
                <div className="mb-4">
                    <h4 className="mt-3 font-semibold">Detalle</h4>
                    
                    {selectedActivityData.Respuestas.map((respuesta, idx) => (
                        <div key={idx} className={`p-2 text-center ${respuesta.Correcto ? 'bg-green-100' : 'bg-red-100'}`}>
                            <strong>Pregunta {idx + 1}:</strong> {respuesta.Oracion || respuesta.TextoAlternativa} ({respuesta.Correcto ? 'Correcta' : 'Incorrecta'})
                        </div>
                    ))}
                </div>
            ) : (
                <p>El alumno no ha realizado esta actividad.</p>
            )}
        </div>
    );
};



    // Define columns for DataTable
    const columns = [
        { name: 'Nombre', selector: row => row.nombre || '-', sortable: true, cell: row => <div className="text-sm font-medium text-gray-900 text-center">{row.nombre || '-'}</div> },
        { name: 'Apellido', selector: row => row.apellido || '-', sortable: true, cell: row => <div className="text-sm font-medium text-gray-900 text-center">{row.apellido || '-'}</div> },
        {
            name: 'Puntaje (%)',
            selector: row => {
                // Obtener el puntaje, redondearlo a 2 decimales
                const puntaje = parseFloat(row.actividades[activityId]?.Puntaje) || 0;
                return Math.round(puntaje * 100) / 100;  // Redondear a 2 decimales sin usar toFixed
            },
            sortable: true,
            cell: row => {
                const puntaje = row.actividades[activityId]?.Puntaje;
                return (
                    <div className="text-sm font-medium text-gray-900 text-center">
                        {renderPuntaje(puntaje)}
                    </div>
                );
            }
        },
        {
            name: 'Tiempo (min)',
            selector: row => calcularTiempoTranscurrido(row.actividades[activityId]?.FechaInicio, row.actividades[activityId]?.FechaFin),
            sortable: true,
            cell: row => {
                const tiempo = calcularTiempoTranscurrido(row.actividades[activityId]?.FechaInicio, row.actividades[activityId]?.FechaFin);
                return (
                        <div style={{ borderRadius: '12px', padding: '4px 8px', display: 'inline-block', minWidth: '60px', textAlign: 'center' }}>
                              {tiempo}
                        </div>
                );
            }
        },
        {
            name: 'Fecha de Inicio',
            selector: row => obtenerFechaYHora(row.actividades[activityId]?.FechaInicio),
            sortable: true,
            cell: row => {
                const tiempo = obtenerFechaYHora(row.actividades[activityId]?.FechaInicio?.toDate());
                return (
                    <div style={{ borderRadius: '12px', padding: '4px 8px', display: 'inline-block', minWidth: '60px', textAlign: 'center' }}>
                        {tiempo}
                    </div>
                );
            }
        }
    ];

    return (
        <div>
        {/* <h2 className="text-2xl font-bold mb-4">Actividad de {name}</h2> */}

            <DataTable
                columns={columns}
                data={data}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                highlightOnHover
                pagination

            />
        </div>
    );
};

export default ActivityTable;

