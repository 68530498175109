import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faGraduationCap, faStar, faList, faChildren, faChevronLeft, faChevronRight, faCalendarDays, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../services/AuthProvider';
import { useClasses } from '../services/ClassesContext';
import SidebarIcon from '../styles/logo.png'; 

export default function Sidebar({ sidebarOpen, setSidebarOpen }) {
    const { teacherId } = useAuth();
    const { classes, updateSelectedClass, selectedClass } = useClasses();
    const [sidebarExpanded, setSidebarExpanded] = useState(true); // Set true initially to show the expanded view
    const sidebar = useRef(null);

    useEffect(() => {
        const sidebarElement = document.getElementById("sidebar");

        if (sidebarExpanded) {
            sidebarElement?.classList.add("sidebar-expanded");
            sidebarElement?.classList.remove("sidebar-collapsed");

        } else {
            sidebarElement?.classList.add("sidebar-collapsed");
            sidebarElement?.classList.remove("sidebar-expanded");

        }
    }, [sidebarExpanded]);
    

    const handleClassClick = (classItemId) => {
        updateSelectedClass(classItemId);
    };

    return (
        <div id="sidebar" ref={sidebar}
            className={`flex flex-col bg-custom-blue text-white shadow-md transition-all duration-300 ${
                sidebarExpanded ? "w-64" : "w-20"} 
                h-screen`}  
        >
            {/* Sidebar Logo */}
            <div className="py-4 px-6 border-b border-blue-500">
                <div className="flex items-center justify-center mb-4">
                    <img
                        src={SidebarIcon}
                        alt="Sidebar Icon"
                        className={`${sidebarExpanded ? "h-16 w-16" : "h-8 w-8"} block transition-all duration-300`} 
                    />
                </div>
            </div>

            <ul className="flex-1 list-none space-y-2">
                <li className="px-4 py-4">
                    <details className="group">
                        <summary className="flex items-center justify-between rounded-lg text-white cursor-pointer">
                            <div className="flex items-center px-3">
                                <FontAwesomeIcon icon={faGraduationCap} className="text-xl mr-2 h-6 w-6" />
                                <span className="sidebar-text">
                                    Cambiar Curso
                                </span>                         </div>
                            {sidebarExpanded && (
                                <span className="shrink-0 transition duration-300 group-open:-rotate-180">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="size-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </span>
                            )}
                        </summary>
                        {sidebarExpanded && (
                            <ul className="mt-2 space-y-1 px-3">
                                {classes.map((classItem) => (
                                    <li key={classItem.id} className="py-1">
                                        <NavLink 
                                            to={`/${teacherId}/cursos/${classItem.id}/`} 
                                            className={({ isActive }) => isActive ? 
                                                "bg-amber-300 block rounded-lg px-4 py-2 text-sm font-medium text-gray-700" : 
                                                "block rounded-lg px-4 py-2 text-sm font-medium text-white hover:bg-amber-300 hover:text-gray-700"}
                                            onClick={() => handleClassClick(classItem.id)}
                                        >
                                            {classItem.nombre}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </details>
                </li>

                <li>
                    <NavLink 
                        to={`/unidades`} end
                        className={({ isActive }) => isActive ? 
                            "flex items-center px-6 py-4 border-l-4 border-amber-300 text-amber-300 font-semibold tracking-wide bg-blue-900" : 
                            "flex items-center px-6 py-4 border-l-4 border-transparent hover:bg-blue-900 hover:border-amber-300 hover:text-amber-300"}
                            >
                        <FontAwesomeIcon icon={faList} className="text-xl h-6 w-6" />
                        <span className="ml-2 sidebar-text">Unidades</span>
                        
                    </NavLink>
                </li>

                <li>
                    <NavLink 
                        to={`/${teacherId}/cursos/${selectedClass}/`} end
                        className={({ isActive }) => isActive ? 
                            "flex items-center px-6 py-4 border-l-4 border-amber-300 text-amber-300 font-semibold tracking-wide bg-blue-900" : 
                            "flex items-center px-6 py-4 border-l-4 border-transparent hover:bg-blue-900 hover:border-amber-300 hover:text-amber-300"}
                            >
                        <FontAwesomeIcon icon={faChildren} className="text-xl h-6 w-6" />
                        <span className="ml-2 sidebar-text">Curso</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink 
                        to={`/${teacherId}/cursos/${selectedClass}/niveles`} end
                        className={({ isActive }) => isActive ? 
                            "flex items-center px-6 py-4 border-l-4 border-amber-300 text-amber-300 font-semibold tracking-wide bg-blue-900" : 
                            "flex items-center px-6 py-4 border-l-4 border-transparent hover:bg-blue-900 hover:border-amber-300 hover:text-amber-300"}
                            >
                        <FontAwesomeIcon icon={faCalendarDays} className="text-xl h-6 w-6" />
                        <span className="ml-2 sidebar-text">Calendario de unidades</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink 
                        to={`/${teacherId}/cursos/${selectedClass}/resultados`} end
                        className={({ isActive }) => isActive ? 
                        "flex items-center px-6 py-4 border-l-4 bg-blue-900 border-amber-300 text-amber-300 font-semibold tracking-wide bg-blue-900" : 
                        "flex items-center px-6 py-4 border-l-4 border-transparent hover:bg-blue-900 hover:border-amber-300 hover:text-amber-300"}
                    >
                        <FontAwesomeIcon icon={faStar} className="text-xl h-6 w-6" />
                        <span className="ml-2 sidebar-text">Resultados</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink 
                        to={`/${teacherId}/cursos/${selectedClass}/extras`} end
                        className={({ isActive }) => isActive ? 
                        "flex items-center px-6 py-4 border-l-4 bg-blue-900 border-amber-300 text-amber-300 font-semibold tracking-wide bg-blue-900" : 
                        "flex items-center px-6 py-4 border-l-4 border-transparent hover:bg-blue-900 hover:border-amber-300 hover:text-amber-300"}
                    >
                        <FontAwesomeIcon icon={faCirclePlus} className="text-xl h-6 w-6" />
                        <span className="ml-2 sidebar-text">Entregas Extras</span>
                    </NavLink>
                </li>


                <li>
                    <NavLink 
                        to={`/${teacherId}/cursos/`} end
                        className={({ isActive }) => isActive ? 
                        "flex items-center px-6 py-4 border-l-4 border-amber-300 text-amber-300 font-semibold tracking-wide bg-blue-900" : 
                        "flex items-center px-6 py-4 border-l-4 border-transparent hover:bg-blue-900 hover:border-amber-300 hover:text-amber-300"}
                    >
                        <FontAwesomeIcon icon={faGear} className="text-xl h-6 w-6" />
                        <span className="ml-2 sidebar-text">Gestionar Cursos</span>
                    </NavLink>
                </li>
            </ul>

            <div className="pt-3 lg:inline-flex mt-auto">
                <div className="flex-1" />
                <div className="px-3 py-2 justify-end">
                    <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
                        <span className="sr-only">Expand / collapse sidebar</span>
                        <FontAwesomeIcon 
                            icon={sidebarExpanded ? faChevronLeft : faChevronRight} 
                            className="w-6 h-6 fill-current transition-transform duration-300"
                        />
                    </button>
                </div>
            </div>
        </div>
    );
}