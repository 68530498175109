import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { collection, getDocs, getDoc, doc, query, where } from 'firebase/firestore';
import CompletionChart from '../components/CompletionChart';
import ActivityStatsDisplay from '../components/ActivityStatsDisplay';
import ProgressChart from '../components/ProgressChart';
import ProgresoActividades from '../components/ProgressLevel'; 
import LoadingScreen from '../components/LoadingScreen';
import TiempoUsoReporte from '../components/TiempoUsoReporte'; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsProgress, faChartPie } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { db } from '../services/db';
import { useClasses } from '../services/ClassesContext';

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para manejar la carga
  const { studentId } = useParams();
  const { selectedClass } = useClasses();
  const [releasedLevels, setReleasedLevels] = useState(new Set());
  const [name, setName] = useState('');
  const [classResults, setClassResults] = useState({ meanScores: null, averageTimes: null });
  const [timeStats, setTimeStats] = useState({});

  // Función para obtener las fechas de los últimos 7 días, y no acceder a todos los documentos
  const getLast7Days = () => {
    const days = [];
    const today = new Date();

    for (let i = 0; i < 8; i++) {
      const date = new Date();
      date.setDate(today.getDate() - i);
      // Formateamos la fecha en 'ddMMyy' para que coincida con el formato del ID
      const formattedDate = format(date, 'ddMMyy');
      days.push(formattedDate);
    }

    return days; // Devuelve un array con los últimos 7 días formateados
  };


  useEffect(() => {
    if (classResults.meanScores) {
      console.log("classResults actualizado:", classResults);
    }
  }, [classResults]);
  
  useEffect(() => {
    const fetchData = async () => {
    // Verificar que selectedClass tiene un valor válido
      if (!selectedClass) {
          console.log("Esperando a que selectedClass tenga un valor válido...");
          return;
      }
      
      try {

        const storedClassResults = localStorage.getItem(`promedios_${selectedClass}`);
        
        if (storedClassResults) {
          // Si hay resultados en localStorage, usarlos
          console.log("SI ESTAN LOS RESULTAODS");
        // Convertir la cadena JSON de localStorage a objeto
          const parsedResults = JSON.parse(storedClassResults);
          //console.log(parsedResults);
          setClassResults({
            meanScores: parsedResults.meanScores,
            averageTimes: parsedResults.averageTimes,
          });
        } else {
          // Si no hay resultados, obtenerlos de Firebase
          //AQUI FALTA HACER LA LOGICA PARA OBTENER TODO

          // Guardar resultados en localStorage
          //localStorage.setItem(`promedios_${selectedClass}`, JSON.stringify(results));
          //setClassResults(results);
          console.log("lol");
        }

        // Obtener el nombre y apellido del alumno
        const studentDocRef = doc(db, 'cursos', selectedClass, 'estudiantes', studentId);
        const studentDoc = await getDoc(studentDocRef);
                
        if (studentDoc.exists()) {
          const studentData = studentDoc.data();
          const fullName = `${studentData.nombre} ${studentData.apellido}`;
          setName(fullName);
        } else {
          console.log("Estudiante no encontrado");
        }
        
        const answersCollection = collection(db, 'cursos', selectedClass, 'estudiantes', studentId, 'respuestas_actividades');
        const answersQuery = await getDocs(answersCollection);
        const answers = answersQuery.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setData(answers);
        console.log(answers);

        // Obtener los últimos 7 días
        const last7Days = getLast7Days();
        const timeCollection = collection(db, 'cursos', selectedClass, 'estudiantes', studentId, 'tiempo_uso');
        const timeQuery = query(timeCollection, where('__name__', 'in', last7Days));
        const querySnapshot = await getDocs(timeQuery);

        // Obtener los tiempos de uso de los últimos 7 días
        const tiemposUso = querySnapshot.docs.map(doc => doc.data().tiempo || 0);
        console.log("tiempos", tiemposUso);
        // Sumar todos los campos de 'tiempo_uso'
        const totalTime = tiemposUso.reduce((acc, tiempo) => acc + tiempo, 0);

        // Obtener el máximo, mínimo y promedio
        const maxTime = Math.max(...tiemposUso); // Máximo tiempo de uso
        const minTime = Math.min(...tiemposUso); // Mínimo tiempo de uso
        const avgTime = totalTime / tiemposUso.length; // Promedio de tiempo de uso

        // Actualizar el estado con los valores calculados
        setTimeStats({ total: totalTime, max: maxTime, min: minTime, average: avgTime });

        const levelsCollection = collection(db, `cursos/${selectedClass}/liberar_niveles`);
        const levelsSnapshot = await getDocs(levelsCollection);
        const currentDate = new Date();
        const levels = new Set();

        const fetchLevelPromises = levelsSnapshot.docs.map(async (doc) => {
          const releaseDate = new Date(doc.data().fecha_liberacion.seconds * 1000 + doc.data().fecha_liberacion.nanoseconds / 1000000);
          if (releaseDate <= currentDate) {
            levels.add(doc.id); // Agregar nivel liberado
          }
        });

        await Promise.all(fetchLevelPromises);
        setReleasedLevels(levels);
        setLoading(false); 
      } catch (error) {
        console.error("Error al obtener datos:", error);
        setLoading(false); // Incluso si hay un error, detener el estado de carga
      }
    };

    fetchData();
  }, [studentId, selectedClass]);

  if (loading) {
    return <LoadingScreen />;
  }
  else{
      console.log("data", data);
  }

  return (
    <>
      {data ? (
        <div className="container mx-auto p-4">
        <h1 className="text-3xl font-semibold mb-8">Desempeño de {name}</h1>
      
        {/* Componente de Tiempo Promedio al inicio ocupando todo el ancho */}
        <div className="rounded-md mb-8">
        <ActivityStatsDisplay data={data} classData={classResults} />
        </div>

        {/* Usamos grid para organizar los gráficos */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
          
          {/* Gráfico de Actividades Completadas */}
          <div className="bg-gray-100 rounded-md">
            <div className="bg-blue-500 text-white p-2 rounded-t-md text-xl font-semibold mb-5">
              <FontAwesomeIcon icon={faChartPie} className="mr-3 text-yellow-500" />
              Actividades Completadas</div>
            <div className="w-full h-72 mb-8">
              <CompletionChart data={data} releasedLevels={releasedLevels} />
            </div>
          </div>
      
          {/* Gráfico de Progreso */}
          <div className="bg-gray-100 rounded-md">
            <div className="bg-blue-500 text-white p-2 rounded-t-md text-xl text-custom-blue font-semibold">
            <FontAwesomeIcon icon={faBarsProgress} className="mr-3 text-yellow-500" />
            Progreso</div>
            <div className="w-full h-72 mb-8">
              <ProgressChart data={data} />
            </div>
          </div>
      
          {/* Mejores Notas por Unidad, ocupando todo el ancho */}
          <div className="mt-5 col-span-1 md:col-span-2 bg-gray-100 rounded-md">
            <h3 className="p-2 rounded-t-md text-xl bg-blue-500 text-white font-semibold mb-5">
              <FontAwesomeIcon icon={faBarsProgress} className="mr-3 text-yellow-500" />
              Mejores notas por unidad
            </h3>
            <div className="w-full h-72">
              <ProgresoActividades data={data} releasedLevels={releasedLevels} />
            </div>
          </div>


        </div>
      </div>
      ) : (
        <p>Cargando datos...</p>
      )}
    </>
  );
};

export default Dashboard;
