import React from 'react';
import { Link } from 'react-router-dom';

const Menu = () => {
  return (
    <div className="container mx-auto p-4">
      <h2 className="text-3xl font-semibold mb-4">Seleccione una categoría de actividades</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {/* Tarjeta de Actividades de Escritura */}
        <div className="bg-white rounded shadow-lg p-4 flex items-center transition duration-300 hover:shadow-xl">
          {/* Imagen a la izquierda */}
          <img 
            src={`${process.env.PUBLIC_URL}/images/escritura.png`} 
            alt="Actividades de Escritura" 
            className="w-40 h-40 object-cover rounded mr-4"  // Imagen en miniatura
          />
          <div className="flex flex-col"> {/* Contenedor del texto y botón */}
            <h3 className="text-xl font-semibold mb-2">Actividades de Escritura</h3>
            <p className="text-gray-600 text-sm mb-4">Las actividades de escritura requieren revisión y calificación, ya que implican un esfuerzo creativo por parte del estudiante. A través de estas actividades, los estudiantes desarrollan habilidades de redacción y expresión.</p>
            <Link 
              to="escritura" 
              className="btn btn-primary font-normal text-white rounded"
            >
              Ver y calificar entregas 
            </Link>
          </div>
        </div>
        
        {/* Tarjeta de Actividades Extra */}
        <div className="bg-white rounded shadow-lg p-4 flex items-center transition duration-300 hover:shadow-xl">
          {/* Imagen a la izquierda */}
          <img 
            src={`${process.env.PUBLIC_URL}/images/clasificacion.png`} 
            alt="Actividades Extra" 
            className="w-40 h-40 object-cover rounded mr-4"  // Imagen en miniatura
          />
          <div className="flex flex-col"> {/* Contenedor del texto y botón */}
            <h3 className="text-xl font-semibold mb-2">Actividades Automatizadas</h3>
            <p className="text-gray-600 text-sm mb-4">Las actividades automatizadas son más interactivas y permiten a los estudiantes practicar sin necesidad de revisión manual.</p>
            <Link 
              to="otras" 
              className="btn btn-accent font-normal rounded"
            >
              Ver resultados
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;