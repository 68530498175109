import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { createContext, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { auth, db } from "./db";
import { collection, query, where, getDocs } from 'firebase/firestore';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [teacherId, setTeacherId] = useState(null);
  const [loading, setLoading] = useState(true);

  const createUser = (email, password) => {
    setLoading(true);
    return createUserWithEmailAndPassword(auth, email, password)
      .finally(() => setLoading(false));
  };

  const loginUser = async (email, password) => {
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      const q = query(collection(db, "profesores"), where("email", "==", user.email));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const teacherData = querySnapshot.docs[0];
        setTeacherId(teacherData.id);
        localStorage.setItem('teacherId', teacherData.id);
      } else {
        setTeacherId(null);
      }
  
      setUser(user);
    } catch (error) {
      console.error("Error logging in:", error);
      throw new Error("Correo electrónico o contraseña incorrectos. Inténtalo de nuevo."); // Lanza un error personalizado
    } finally {
      setLoading(false);
    }
  };
  

  const logOut = async () => {
    setLoading(true);
    try {
      await signOut(auth);
      setUser(null);
      setTeacherId(null);
      localStorage.removeItem('teacherId');
      localStorage.removeItem('selectedClass');
      localStorage.removeItem('levels');

    } catch (error) {
      console.error("Error logging out:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const storedTeacherId = localStorage.getItem('teacherId');
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        if (storedTeacherId) {
          setTeacherId(storedTeacherId);
        } else {
          const q = query(collection(db, "profesores"), where("email", "==", currentUser.email));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const teacherData = querySnapshot.docs[0];
            setTeacherId(teacherData.id);
            localStorage.setItem('teacherId', teacherData.id);
          } else {
            setTeacherId(null);
          }
        }
      } else {
        setTeacherId(null);
        // setClasses([]); // Eliminar si no estás utilizando este estado aquí
      }
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const authValue = {
    createUser,
    user,
    teacherId,
    loginUser,
    logOut,
    loading,
  };

  return (
    <AuthContext.Provider value={authValue}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => {
  return useContext(AuthContext);
};