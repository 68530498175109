import React from 'react';
import { useParams } from 'react-router-dom';
import { useClasses } from "../services/ClassesContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrog } from '@fortawesome/free-solid-svg-icons';

const ClasificacionDePalabras = () => {
    const { levels } = useClasses();
    const { unidadId } = useParams();
  
    const level = levels.find(l => l.id === unidadId);
    console.log(level);

    // Verificar si el level existe
    if (!level) {
        return <p>No se encontró el nivel con id {unidadId}.</p>; // Muestra un mensaje si no se encuentra el nivel
    }

    return (
      <div className="container mx-auto p-4">
        {/* Título de la actividad */}
        <div className="mb-6 flex items-center">
          <FontAwesomeIcon icon={faFrog} className="text-3xl text-green-500 mr-2" />
          <h1 className="text-3xl font-semibold text-gray-800">{level.nombre}</h1>
        </div>
        <p className="text-lg text-gray-600 mt-2">{level.texto}</p>
  
        {/* Grupos de palabras */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {level.grupos.map((grupo, index) => (
            <div key={index} className="bg-gray-100 p-4 rounded-lg shadow-lg">
              <h2 className="text-xl font-semibold text-gray-700">{grupo.titulo}</h2>
              <ul className="mt-4 space-y-2 list-disc pl-5">
                {grupo.palabras.map((palabra, idx) => (
                  <li key={idx} className="text-lg text-gray-600">{palabra}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    );
  };

  export default ClasificacionDePalabras;
