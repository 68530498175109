import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useClasses } from "../services/ClassesContext";
import LoadingScreen from '../components/LoadingScreen'; 

const LevelCard = ({ level }) => {
    const imageUrl = `${process.env.PUBLIC_URL}/images/${level.id}.png`; // Intenta construir la ruta de la imagen

    // Lógica para definir la ruta dinámica basada en el id del nivel
    const getDynamicLink = (levelId) => {
        if (levelId.startsWith('CL')) {
            return `/clasificacion/${levelId}`;
        } else if (levelId.includes('ESP')) {
            return `/esp/${levelId}`;
        } else if (levelId.startsWith('B')) {
            return `/unidad/${levelId}`;
        }
    };

    return (
        <div className="max-w-sm rounded overflow-hidden shadow-lg hover:scale-105 transition-transform duration-300">
            <Link to={getDynamicLink(level.id)}>
                <div
                    className="relative w-full h-64 bg-cover bg-center" // Cambiado a relative aquí
                    style={{
                        backgroundImage: `url(${imageUrl})`,
                        backgroundPosition: level.position || 'center center' // Usa 'center center' como predeterminado
                    }} 
                >
                    <span className="sr-only">{level.nombre}</span>

                    {/* Course Category Tag */}
                    <div className="absolute top-0 left-0 bg-amber-300 text-custom-blue text-sm font-semibold py-1 px-3 rounded-br-lg z-10">
                        {level.letra || 'Sin categoría'}
                    </div>
                </div>
            </Link>
  
            {/* Course Details */}
            <div className="px-6 py-4">
                <div className="font-semibold text-lg mb-2 text-custom-blue">{level.nombre}</div>
                <p className="text-gray-700 text-xs">
                    {level.descripcion || "No description available."}
                </p>
            </div>
        </div>
    );
};

const ListaNiveles = () => {
    const { levels } = useClasses();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (levels.length > 0) {
            setLoading(false);
        }
    }, [levels]);

    // Filtra los niveles por curso
    const firstGradeLevels = levels.filter(level => level.curso === 1);
    const secondGradeLevels = levels.filter(level => level.curso === 2);
    const [filter, setFilter] = useState('todos'); // Estado para el filtro

    // Maneja el cambio de filtro
    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
    };


    if (loading) {
        return <LoadingScreen />;
      }

    return (
        <div className="container mx-auto p-4">
            <div id="frontpage-course-list">
                <h2 className="text-3xl font-semibold mb-4">Lista de unidades</h2>
    
                {/* Botones de filtrado */}
                <div className="mb-4">
                    <button 
                        className={`btn btn-sm w-48 mr-4 ${filter === 'todos' ? 'btn-primary text-white' : 'font-normal'}`}
                        onClick={() => handleFilterChange('todos')}
                    >
                        Todos
                    </button>
                    <button 
                        className={`btn btn-sm mr-4 w-48 ${filter === 'primero' ? 'btn-primary text-white' : 'font-normal'}`}
                        onClick={() => handleFilterChange('primero')}
                    >
                        1° Básico
                    </button>
                    <button 
                        className={`btn btn-sm mr-4 w-48 ${filter === 'segundo' ? 'btn-primary text-white' : 'font-normal'}`}
                        onClick={() => handleFilterChange('segundo')}
                    >
                        2° Básico
                    </button>
                </div>
    
                {/* Renderizado condicional según el filtro */}
                {filter === 'todos' && (
                    <>
                        {/* Primer Básico */}
                        <h3 className="text-xl text-custom-blue font-semibold mb-2">1° Básico</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                        {firstGradeLevels.map((level, index) => (
                                <LevelCard key={index} level={level} />
                            ))}
                        </div>
    
                        {/* Segundo Básico */}
                        <h3 className="text-xl text-custom-blue font-semibold mb-2 mt-6">2° Básico</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                        {secondGradeLevels.map((level, index) => (
                                <LevelCard key={index} level={level} />
                            ))}
                        </div>
                    </>
                )}
    
                {filter === 'primero' && (
                    <>
                        <h3 className="text-xl text-custom-blue font-semibold mb-2">1° Básico</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                        {firstGradeLevels.map((level, index) => (
                                <LevelCard key={index} level={level} />
                            ))}
                        </div>
                    </>
                )}
    
                {filter === 'segundo' && (
                    <>
                        <h3 className="text-xl text-custom-blue font-semibold mb-2">2° Básico</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {secondGradeLevels.map((level, index) => (
                                <LevelCard key={index} level={level} />
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
export default ListaNiveles;


